import React, { useEffect, useRef, useState, useContext } from 'react'
import SCTop from "../Components/SCTop";
import './AutoCICD.css';
import SCInputText from "../Components/SCInputText";
import SCButton from "../Components/SCButton";
import SCDropDownList from '../Components/SCDropDownList';
import * as consts from '../consts';
import DataContext from "../Context/DataContext";
import _ from "lodash";
import { DateTime, DateTime as luxon } from "luxon";
import cicdAxios from '../cicdAxios';
import { toast } from 'react-toastify';
import AlertModal from '../Modals/AlertModal';
import { HubConnectionBuilder } from '@microsoft/signalr';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SCTable from '../Components/SCTable';
import { useHistory } from 'react-router-dom';
import useRefTeamLeaderCallback from '../Hooks/Ref/useRefTeamLeaderCallback'
import useRefTeamLeaderIdCallback from '../Hooks/Ref/useRefTeamLeaderIdCallback'
import useRefTeamNameCallback from '../Hooks/Ref/useRefTeamNameCallback'
import TextField from '@mui/material/TextField';
import SCTextArea from '../Components/SCTextArea';
import PipelineTreeModal from '../Modals/PipelineTreeModal';
import { Autocomplete, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import BackgroundDataContext from '../Context/BackgroundDataContext';
import useAxios from '../Hooks/useAxios';
import useLogin from '../Hooks/useLogin';
import AksOrEksModal from '../Modals/AksOrEksModal';
import SelfServiceWizard from '../Components/SelfService/SelfServiceWizard';
import { DataProviderWizard } from '../Context/WizardDataContext';
import SelfServiceKVModal from '../Modals/SelfServiceKVModal';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DataContextChat } from '../Context/DataContextChat';
import SelfServiceAIGenerationModal from '../Modals/SelfServiceAIGenerationModal';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import migrationInstructionImage from '../Images/migration-instructions-image.png'
import InfoIcon from '@mui/icons-material/Info';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SCToggle from '../Components/SCToggle';
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import DPOrangeButton from '../Controllers/DPOrangeButton';
import DPGrayButton from '../Controllers/DPGrayButton';

const AutoCICD = () => {


  //https://mui.com/material-ui/react-stepper/

  const appNameRef = useRef(null)
  const serviceNameRef = useRef(null)
  const instance = useAxios(process.env.REACT_APP_CICD_BASE_URL);
  const { userLogin } = useLogin()

  const { token, setToken, setCurrentPage, setModalAlertShow, modalAlertShow, sortingAtoZ, listOfDepartments, showLoader, hideLoader,
    setConnection, connection, requestQueue, tabsAutoCICDValue, setTabsAutoCICDValue, getApprovalManagerDropDown, listOfTeams, initTeams, getSelfServiceBranchesForExsitingRepo,
    requestCICDItems, getUserCICDRequests, initDeperatmentManager, initSubDeperatmentManager, initTiers, getSelfServiceRepositories, getSelfServiceEnvironments, githubRepository,
    getSelfServiceSubscriptions, getKVNames, getSecrets, getSolutions, getCSProjFiles, selfServiceCopilotJson, githubSolutionFile, githubProjectSettingsFile, githubBranches,
    listOfUsers, getTelAvivUsers, isAdminUser } = useContext(DataContext);
  const { selfServiceReceiveJsonMessage, setSelfServiceReceiveJsonMessage } = useContext(DataContextChat)
  const { ddlProjects, setDdlProjects, ddlDBGroup, ddlProjectsCache, setDdlDBGroup, isApiProjectDone, isApiDBDone, getCICDAutoProjects, setIsApiProjectDone } = useContext(BackgroundDataContext);


  const [selectedTier, setSelectedTier] = useState('')

  //const [ddlProjects, setDdlProjects] = useState([])
  const [ddlFeeds, setDdlFeeds] = useState([])
  const [selectedProject, setSelectedProject] = useState('')
  const [selectedFeed, setSelectedFeed] = useState('')
  const [folderName, setFolderName] = useState('')
  const [keyVaultPayloadData, setKeyVaultPayloadData] = useState([])
  const [automationTest, setAutomationTest] = useState(false)
  const [ddlAcrDev, setDdlAcrDev] = useState([])//-prod-
  const [ddlAcrProd, setDdlAcrProd] = useState([])//-prod-

  const [repositories, setRepositories] = useState([])//-prod-
  const [typeDecision, setTypeDecision] = React.useState('new')
  const [options, setOptions] = useState([])
  const [migrationType, setMigrationType] = useState('aks')
  //const [ddlDBGroup, setDdlDBGroup] = useState([])
  const [copilotButtonDisable, setCopilotButtonDisable] = useState(false)
  const [selectedACRDev, setSelectedACRDev] = useState([])
  const [selectedACRProd, setSelectedACRProd] = useState([])

  const [selectedDBGroup, setSelectedDBGroup] = useState('')

  const [modalAKSEKSShow, setModalAKSEKSShow] = useState(false)
  const [decision, setDecision] = useState('aks')
  const [cloudDecision, setCloudDecision] = useState('azure')

  const [dockerfilePath, setDockerfilePath] = useState(null)
  const [repoDecision, setRepoDecision] = useState('new')

  const [ddlDeploy, setDdlDeploy] = useState([{ name: 'Yes', value: true }, { name: 'No', value: false }])

  const [ddlTeams, setDdlTeams] = useState([]);

  const [serviceDescription, setServiceDescription] = useState('')
  const [ddlTier, setDdlTier] = useState([]);

  const [ddlBranchesDev, setDdlBranchesDev] = useState([])
  const [ddlSolutions, setDdlSolutions] = useState([])
  const [selectedSolution, setSelectedSolution] = useState('')


  const [selectedSolutionFile, setSelectedSolutionFile] = useState('')

  const [copilotObject, setCopilotObject] = useState(null)
  const [ddlBranchesProd, setDdlBranchesProd] = useState([])

  const [ddlBranchesDevCached, setDdlBranchesDevCached] = useState([])
  const [ddlBranchesProdCached, setDdlBranchesProdCached] = useState([])

  const [ddlManager, setDdlManager] = useState([])
  const [selectedBranchesProd, setSelectedBranchesProd] = useState('')
  const [selectedBranchesDev, setSelectedBranchesDev] = useState([])

  const [selectedSolutionCsproj, setSelectedSolutionCsproj] = useState([])
  const [ddlSolutionCsproj, setDdlSolutionCsproj] = useState([])

  const [deployToDevCluster, setDeployToDevCluster] = useState(false)


  const [selectedManager, setSelectedManager] = useState('');

  const [ddlSolutionType, setDdlSolutionType] = useState([])
  const [ddlFramework, setDdlFramework] = useState([])


  const [selectedSolutionType, setSelectedSolutionType] = useState('');
  const [selectedFramework, setSelectedFramework] = useState('');


  const [selectedTeam, setSelectedTeam] = useState('')

  const [teamLeaderRef] = useRefTeamLeaderCallback();
  const [teamLeaderIdRef] = useRefTeamLeaderIdCallback();
  const [teamNameRef] = useRefTeamNameCallback();

  const [appNameValue, setAppNameValue] = useState('');
  const [serviceNameValue, setServiceNameValue] = useState('');

  const [repoNameValue, setRepoNameValue] = useState('');
  const [repoNameFromAutoComplete, setRepoNameFromAutoComplete] = useState('');

  const [dbNameValue, setDBNameValue] = useState('');

  const [type, setType] = useState(0)


  const [modalKVShow, setModalKVShow] = useState(false)

  const [error, setError] = useState(false);
  const [errorClusterProd, setErrorClusterProd] = useState(false)
  const [shake, setShake] = useState(false);
  const pattern = /^[A-Z][A-Za-z]*(?:\.[A-Z][A-Za-z]*)*$/;
  const patternServiceName = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
  const patternDBName = /^[a-zA-Z0-9]*$/;


  const [regexError, setRegexError] = useState(false);
  const [regexServiceError, setRegexServiceError] = useState(false);
  const [regexDBNameError, setRegexDBNameError] = useState(false);


  const [regexShake, setRegexShake] = useState(false);

  const [text, setText] = useState()

  const [showBuildPipelineTree, setShowBuildPipelineTree] = useState(false);
  const [showReleasePipelineTree, setShowReleasePipelineTree] = useState(false);

  const [modalAIMessageShow, setModalAIMessageShow] = useState(false)


  const [selectedBuildFolder, setSelectedBuildFolder] = useState("");
  const [selectedReleaseFolder, setSelectedReleaseFolder] = useState("");

  //***EKS */
  const [subscriptions, setSubscriptions] = useState([])
  const [ddlYesNo, setDdlYesNo] = useState([])
  const [selectedEKSAndKV, setSelectedEKSAndKV] = useState("");
  const [ddlSubscriptions, setDdlSubscriptions] = useState([])
  const [selectedSubscriptions, setSelectedSubscriptions] = useState("");


  const [ddlKVList, setDdlKVList] = useState([])
  const [selectedKVObject, setSelectedKVObject] = useState("");


  const [ddlSecrets, setDdlSecrets] = useState([])
  const [selectedSecrets, setSelectedSecrets] = useState([]);


  const [prefixValue, setPrefixValue] = useState("");

  //***EKS */


  //.Net 8 Custom
  const [ddlAppConfig, setDdlAppConfig] = useState(false)
  const [selectedAppConfig, setSelectedAppConfig] = useState(false)
  const [selectedAppConfigUrl, setSelectedAppConfigUrl] = useState('')

  const [selectedEFAndMongo, setSelectedEFAndMongo] = useState('None')

  const [errorAppConfig, setErrorAppConfig] = useState(false)


  const [errorList, setErrorList] = useState([])


  const tabListRef = useRef();

  const [ddlDeperatmentManager, setDdlDeperatmentManager] = useState([]);
  const [ddlSubDeperatmentManager, setDdlSubDeperatmentManager] = useState([]);
  const [selectedDeperatmentManager, setSelectedDeperatmentManager] = useState('');
  const [selectedSubDeperatmentManager, setSelectedSubDeperatmentManager] = useState('')

  const [openByEdit, setOpenByEdit] = useState(false)

  const [toggleExcludeBuildValue, setToggleExcludeBuildValue] = useState(false)
  const [toggleExcludeReleaseValue, setToggleExcludeReleaseValue] = useState(false)


  const waitingValidMessage = "We are handling your request, it will take a while, you will get a notification as soon as the process is ready or if there is some conflict";
  const waitingInValidMessage = "Request with the same parameters is in process";



  const [existingRepoNameInValid, setExistingRepoNameInValid] = useState(false)

  const location = useLocation();



  const columnsItems = [

    { display: true, name: "App Name", key: "appName", width: "7%" },
    { display: true, name: "Repo Name", key: "repoName", width: "7%" },
    { display: true, name: "Build Pipeline Url", key: "buildPipelineUrl", width: "7%" },
    { display: true, name: "Release Pipeline Url", key: "releasePipelineUrl", width: "7%" },
    { display: true, name: "Repo Url", key: "repoUrl", width: "7%" },
    { display: true, name: "DevOps Project", key: "azureDevOpsProject", width: "7%" },
    { display: true, name: "DB Creation Status", key: "dataBaseCreationStatus", width: "7%" },
    { display: true, name: "DB KeyVault Key", key: "dataBaseKeyVaultKey", width: "7%" },
    { display: true, name: "DB KeyVault Name", key: "dataBaseKeyVaultName", width: "7%" },
    { display: true, name: "Manager", key: "approvalManagerEmail", width: "7%" },
    { display: true, name: "Approval Status", key: "approvalStatus", width: "7%" },
    { display: true, name: "Ticket Number", key: "approvalTicketNumber", width: "7%" },
    { display: true, name: "Created On", key: "createdOn", width: "7%" },
    { display: true, name: "Process Status", key: "processStatus", width: "7%" },
    { display: false, name: "partitionKey", key: "partitionKey", width: "0%" },
    { display: false, name: "readByServiceCatalog", key: "readByServiceCatalog", width: "0%" },
    { display: false, name: "failureReason", key: "failureReason", width: "0%" },

  ]

  const [processEnded, setProcessEnded] = useState(false)


  useEffect(async () => {

    if (cloudDecision === 'github') {
      setDdlProjects([])
      var resource = [];

      resource.push({
        name: "platform-infra-il",
        value: "platform-infra-il"

      })
      setDdlProjects(resource)
      setIsApiProjectDone('success')
    }
    else {

      setDdlProjects([])
      setDdlProjects(ddlProjectsCache)
      setIsApiProjectDone('success')



    }
  }, [cloudDecision])


  useEffect(() => {
    setFolderName(serviceNameValue)
  }, [serviceNameValue])

  /*
    useEffect(() => {
      //REMARK WHEN MOVING TO STAGE AND PROD
      setModalAKSEKSShow(true)
      //REMARK WHEN MOVING TO STAGE AND PROD
      setDdlYesNo([{ name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }])
      setSelectedEKSAndKV('No')
      setDdlAppConfig([{ name: 'Yes', value: true }, { name: 'No', value: false }])
  
    }, [])
  
    useEffect(() => {
  
      setDdlAppConfig([{ name: 'Yes', value: true }, { name: 'No', value: false }])
  
    }, [])*/


  useEffect(() => {


    setCurrentPage('auto-cicd')
    var solutionTypes = [];

    solutionTypes.push({ name: 'WebApi', value: 'WebApi' }, { name: 'QueueProcessor', value: 'QueueProcessor' }, { name: 'CronWorker', value: 'CronWorker' })
    setDdlSolutionType(solutionTypes)
    setDdlTier(initTiers())


    var frameworkTypes = [];

    frameworkTypes.push({ name: 'Dot.Net 6', value: '.Net6' }, { name: 'Dot.Net 8', value: '.Net8' })
    setDdlFramework(frameworkTypes)


    let managersDdl = getApprovalManagerDropDown();
    setDdlManager(sortingAtoZ(managersDdl));

    return () => {
      setTabsAutoCICDValue({ TabValue: '0' })

    };


  }, [])



  useEffect(async () => {

    const { search } = location;
    const urlParams = new URLSearchParams(search);
    const value = urlParams.get('chat');
    if (value !== "selfService") {
      setModalAKSEKSShow(true)
    }
    else {
      setDecision('aks')
      setRepoDecision('new')
      setCloudDecision('azure')
      setMigrationType('aks')
    }

    setDdlYesNo([{ name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' }])
    setSelectedEKSAndKV('No')
    setDdlAppConfig([{ name: 'Yes', value: true }, { name: 'No', value: false }])




    //fill user details
    let name = localStorage.getItem("InfraConsoleName");
    let userName = localStorage.getItem("InfraConsoleUserName");
    let oid = localStorage.getItem("InfraConsoleUserId");

    let activeDirectoryUsers = await getTelAvivUsers(name);
    if (activeDirectoryUsers && activeDirectoryUsers?.data?.length > 0) {

      let user = activeDirectoryUsers.data.find(x => x.email === userName);

    }




    if (value === "selfService") {
      const checkState = () => {
        if (selfServiceReceiveJsonMessage !== null) {
          // Clear the interval when the state is not null
          clearInterval(intervalId);
          bindJsonObject(selfServiceReceiveJsonMessage)
          setSelfServiceReceiveJsonMessage(null)
        }
      };

      // Set an interval to check the state every 100 milliseconds
      const intervalId = setInterval(checkState, 100);

      // Clean up the interval when the component unmounts or when the effect re-runs
      return () => clearInterval(intervalId);
    }




  }, [])


  useEffect(() => {
    if (!modalAlertShow && !modalAKSEKSShow && tabsAutoCICDValue.TabValue === '0' && processEnded === true) {
      // setModalAKSEKSShow(true)    
    }


  }, [modalAlertShow])

  useEffect(() => {
    if (keyVaultPayloadData.length === 0) {
      setSelectedEKSAndKV('No')
    }
  }, [keyVaultPayloadData])

  useEffect(() => {
    if ((selectedEKSAndKV === '' || selectedEKSAndKV === "No") && !openByEdit) {

      setKeyVaultPayloadData([])
    }
  }, [selectedEKSAndKV])

  const displayErrorToast = (message) => {
    const ErrorMsg = () => (
      <div>{message}</div>
    )
    toast.error(<ErrorMsg />)
  }

  useEffect(() => {

    switch (decision) {
      case 'aks':
        {
          var solutionTypes = [];
          solutionTypes.push({ name: 'WebApi', value: 'WebApi' }, { name: 'QueueProcessor', value: 'QueueProcessor' }, { name: 'CronWorker', value: 'CronWorker' })
          setDdlSolutionType(solutionTypes)
          break;
        }
      case 'eks':
      case 'both':
        {
          var solutionTypes = [];
          solutionTypes.push({ name: 'WebApi', value: 'WebApi' })
          setDdlSolutionType(solutionTypes)
          break;
        }


      default:
        break;
    }


  }, [decision])



  useEffect(async () => {

    if (selectedKVObject !== '') {

      try {
        let response = await getSecrets(selectedKVObject)

        if (response.status === 200) {

          if (response.data.content.length > 0) {
            let secrets = response.data.content.map((s) => {
              return { name: s.secretName, value: s.secretName }
            })
            setDdlSecrets(secrets)
          }
          else {
            displayErrorToast('We could not fetch the secrets')
          }

        }
      } catch (error) {


      }

    }

  }, [selectedKVObject])

  useEffect(async () => {
    if (selectedSubscriptions !== '') {

      try {
        let response = await getKVNames(selectedSubscriptions)

        if (response.status === 200) {

          if (response.data.content.length > 0) {
            let kv = response.data.content.map((k) => {
              return { name: k.keyVaultName, value: k.keyVaultUrl }
            })
            setDdlKVList(kv)
          }
          else {
            displayErrorToast('We could not fetch the key vaults')
          }

        }
      } catch (error) {
        //show alert
      }

    }
  }, [selectedSubscriptions])

  useEffect(async () => {
    if (selectedEKSAndKV === 'Yes') {
      //invoke subscription api
      //if yes when create a request do validation
      if (subscriptions && subscriptions.length === 0) {

        try {
          let response = await getSelfServiceSubscriptions()

          if (response.status === 200) {
            setSubscriptions(response.data.content)
            if (response.data.content.length > 0) {
              let subs = response.data.content.map((s) => {
                return { name: s.displayName, value: s.subscriptionId }
              })
              setDdlSubscriptions(subs)
            }
            else {
              displayErrorToast('We could not fetch the subsciptions')

            }

          }
        } catch (error) {
          //show alert
        }

      }

    }
  }, [selectedEKSAndKV])


  useEffect(() => {

    switch (decision) {
      case 'aks':
        {
          var solutionTypes = [];
          solutionTypes.push({ name: 'WebApi', value: 'WebApi' }, { name: 'QueueProcessor', value: 'QueueProcessor' }, { name: 'CronWorker', value: 'CronWorker' })
          setDdlSolutionType(solutionTypes)
          break;
        }
      case 'eks':
      case 'both':
        {
          var solutionTypes = [];
          solutionTypes.push({ name: 'WebApi', value: 'WebApi' })
          setDdlSolutionType(solutionTypes)
          break;
        }


      default:
        break;
    }


  }, [decision])



  useEffect(async () => {

    if (selectedKVObject !== '') {

      try {
        let response = await getSecrets(selectedKVObject)

        if (response.status === 200) {

          if (response.data.content.length > 0) {
            let secrets = response.data.content.map((s) => {
              return { name: s.secretName, value: s.secretName }
            })
            setDdlSecrets(secrets)
          }
          else {
            displayErrorToast('We could not fetch the secrets')

          }

        }
      } catch (error) {
        //show alert
      }

    }

  }, [selectedKVObject])

  useEffect(async () => {
    if (selectedSubscriptions !== '') {

      try {
        let response = await getKVNames(selectedSubscriptions)

        if (response.status === 200) {

          if (response.data.content.length > 0) {
            let kv = response.data.content.map((k) => {
              return { name: k.keyVaultName, value: k.keyVaultUrl }
            })
            setDdlKVList(kv)
          }
          else {
            displayErrorToast('We could not fetch the key vaults')

          }

        }
      } catch (error) {
        //show alert
      }

    }
  }, [selectedSubscriptions])

  useEffect(async () => {
    if (selectedEKSAndKV === 'Yes') {
      //invoke subscription api
      //if yes when create a request do validation
      if (subscriptions && subscriptions.length === 0) {

        try {
          let response = await getSelfServiceSubscriptions()

          if (response.status === 200) {
            setSubscriptions(response.data.content)
            if (response.data.content.length > 0) {
              let subs = response.data.content.map((s) => {
                return { name: s.displayName, value: s.subscriptionId }
              })
              setDdlSubscriptions(subs)
            }
            else {
              displayErrorToast('We could not fetch the subsciptions')

            }

          }
        } catch (error) {
          //show alert
        }

      }

    }
  }, [selectedEKSAndKV])


  useEffect(() => {

    if (isApiProjectDone === 'failed' && ddlProjects.length === 0) {

      displayErrorToast('Your user account is not related to any project, please contact to infra team')


    }
    if (isApiDBDone === 'failed' && ddlDBGroup.length === 0) {
      displayErrorToast('Your user account is not related to any DB group, please contact to infra team')

    }




  }, [ddlProjects, ddlDBGroup, isApiProjectDone, isApiDBDone])





  useEffect(() => {

    if (listOfDepartments && listOfDepartments.length > 0) {

      setDdlDeperatmentManager(initDeperatmentManager())
      setDdlSubDeperatmentManager(initSubDeperatmentManager())

    }


  }, [listOfDepartments])

  useEffect(() => {
    setDdlTeams(initTeams())
  }, [listOfTeams])


  useEffect(() => {

    if (!connection) {
      return;
    }
    else {
      if (selectedProject) {
        if (typeDecision === 'migrate') {
          invokeNewRequestForMigration();
        }
        else {
          invokeNewRequest();
        }
      }

    }

  }, [connection]);

  //init contollers
  /*useEffect(async () => {
    if (token) {
   
      //managers list
      let managersDdl = getApprovalManagerDropDown();
      setDdlManager(sortingAtoZ(managersDdl));
    }
  }, [token, instance])*/

  /*useEffect(async () => {
    if (selectedProject) {
      await fillACRDropDown(selectedProject);
    }


  }, [selectedProject])*/


  const fillACRDropDown = async (selectedProject) => {
    if (cloudDecision === 'azure') {
      let response = await Promise.allSettled([getSelfServiceRepositories(selectedProject), getSelfServiceEnvironments(selectedProject)])

      if (response[0].status === "fulfilled") {
        setRepositories([])
        var repositories = [];
        response[0].value.data.content.map((a) => {

          repositories.push({
            id: a.id,
            name: a.name
          })
        })
        setRepositories(repositories)
      }

      if (response[1].status === "fulfilled") {
        var resourceDev = [];
        var resourceProd = [];

        response[1].value.data.content.map((a) => {

          if (a.name.toLowerCase().includes("-prod-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
            resourceProd.push({
              name: a.displayName + " (" + a.managedKubernetesService + ")",
              value: a.id,
              managedKubernetesService: a.managedKubernetesService,
              branches: a.allowedBranches
            })
          }
          if (a.name.toLowerCase().includes("-dev-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
            resourceDev.push({
              name: a.displayName + " (" + a.managedKubernetesService + ")",
              value: a.id,
              managedKubernetesService: a.managedKubernetesService,
              branches: a.allowedBranches
            })
          }
        })


        setDdlAcrDev(resourceDev)
        setDdlAcrProd(resourceProd)


      }


    }
    else {
      let response = await Promise.allSettled([githubRepository(selectedProject), getSelfServiceEnvironments("Infrastructure")])

      if (response[0].status === "fulfilled") {
        setRepositories([])
        var repositories = [];
        response[0].value.data.content.map((a) => {

          repositories.push({
            id: a.id,
            name: a.name
          })
        })
        setRepositories(repositories)
      }

      if (response[1].status === "fulfilled") {
        var resourceDev = [];
        var resourceProd = [];

        response[1].value.data.content.map((a) => {

          if (a.name.toLowerCase().includes("-prod-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
            resourceProd.push({
              name: a.displayName + " (" + a.managedKubernetesService + ")",
              value: a.id,
              managedKubernetesService: a.managedKubernetesService,
              branches: a.allowedBranches
            })
          }
          if (a.name.toLowerCase().includes("-dev-") && (a.managedKubernetesService.toLowerCase() === decision || decision === 'both')) {
            resourceDev.push({
              name: a.displayName + " (" + a.managedKubernetesService + ")",
              value: a.id,
              managedKubernetesService: a.managedKubernetesService,
              branches: a.allowedBranches
            })
          }
        })


        setDdlAcrDev(resourceDev)
        setDdlAcrProd(resourceProd)


      }


    }

  }

  useEffect(() => {
    if (selectedACRDev) {
      setDdlBranchesDev([])
      let result = ddlAcrDev.filter(a => selectedACRDev.find(x => x === a.value))
      if (result && result.length > 0) {
        var resource = [];

        result[0].branches.map((i) => (


          resource.push({
            name: i,
            value: i,
          })

        ))
        setDdlBranchesDev(resource)
        setDdlBranchesDevCached(resource)


      }
    }

  }, [selectedACRDev])

  useEffect(() => {
    if (selectedACRProd && repoDecision !== 'existing') {
      setDdlBranchesProd([])
      let result = ddlAcrProd.filter(a => selectedACRProd.find(x => x === a.value))


      if (result && result.length > 0) {
        var resource = [];

        result[0].branches.map((i) => (


          resource.push({
            name: i,
            value: i,
          })

        ))
        setDdlBranchesProd(resource)
        setDdlBranchesProdCached(resource)
      }
    }
    if (selectedACRProd.length === 0) {
      setSelectedBranchesProd([])
    }

  }, [selectedACRProd])

  useEffect(() => {

    if (token && tabsAutoCICDValue.TabValue === '1') {
      getUserCICDRequests();
    }
    if (appNameRef?.current) {
      appNameRef.current.value = appNameValue;
    }
    if (serviceNameRef?.current) {
      serviceNameRef.current.value = serviceNameValue;
    }


  }, [tabsAutoCICDValue])


  const handleChangeDeperatmentManager = (e) => {
    setSelectedDeperatmentManager(e.target.value);
  }
  const handleChangeSubDeperatmentManager = (e) => {
    setSelectedSubDeperatmentManager(e.target.value);
  }


  const buildCommand = () => {


    const dictionary = new Map();


    dictionary.set('--TeamId', selectedTeam);
    dictionary.set('--TeamName', teamNameRef.value);
    dictionary.set('--TeamLeaderId', teamLeaderIdRef.value);
    dictionary.set('--TeamLeaderName', teamLeaderRef.value);
    dictionary.set('--Description', serviceDescription);
    dictionary.set('--Tier', selectedTier);
    dictionary.set('--SubDepartmentManager', selectedSubDeperatmentManager);
    dictionary.set('--DepartmentManager', selectedDeperatmentManager);


    if (selectedEFAndMongo !== 'None' && selectedFramework === '.Net8') {
      switch (selectedEFAndMongo) {
        case 'EF':
          {
            dictionary.set('--UseEf', true);
            dictionary.set('--UseMongodb', false);
            break;
          }
        case 'Mongo':
          {
            dictionary.set('--UseEf', false);
            dictionary.set('--UseMongodb', true);
            break;
          }
        case 'None':
          {
            dictionary.set('--UseEf', false);
            dictionary.set('--UseMongodb', false);
            break;
          }


        default:
          break;
      }
    }

    if (selectedFramework === '.Net8') {
      if (selectedAppConfig === true && selectedAppConfigUrl !== "") {
        dictionary.set('--UseAzureAppConfiguration', true);
        dictionary.set('--AppConfigurationName', selectedAppConfigUrl);

      }
      if (selectedAppConfig === false) {
        dictionary.set('--UseAzureAppConfiguration', false);

      }
    }



    return dictionary;
  }

  const invokeNewRequestForMigration = () => {


    let payload = {};
    const dictionary = new Map();

    if (selectedACRDev && selectedACRDev.length > 0) {

      if (selectedACRDev.length > 1) {
        selectedACRDev.forEach((element, index) => {

          dictionary.set(element, selectedBranchesDev);
        });
      }
      else {
        dictionary.set(selectedACRDev, selectedBranchesDev);
      }
    }
    let commandAcrAndBranches = Object.fromEntries([...dictionary]);


    if (cloudDecision === 'azure') {
      const selectedOptions = ddlBranchesDev.filter(option => option.value === selectedBranchesDev[0]);
      payload = {
        projectName: selectedProject,
        appName: serviceNameValue,
        feedName: selectedFeed,
        buildPipelinePath: selectedBuildFolder,
        releasePipelinePath: selectedReleaseFolder,
        createBuildSubFolder: serviceNameValue === '' ? false : toggleExcludeBuildValue,
        createReleaseSubFolder: serviceNameValue === '' ? false : toggleExcludeReleaseValue,
        solutionName: selectedSolutionFile.split('.')[0], // without the sln
        approvalManagerEmail: selectedManager,
        repositoryName: repoNameFromAutoComplete,
        repositoryExists: true,
        migrationFlow: true,
        solutionType: "WebApi",
        projectSettingsFile: selectedSolutionCsproj,
        solutionFile: selectedSolutionFile,
        objectId: selectedOptions[0].objectId,
        deployToDevCluster: deployToDevCluster,
        automationTest: automationTest,
        scmType: cloudDecision === 'azure' ? 'AzureDevOps' : 'GitHub'
      }
      addImagePayloadToEKSAndAKS(payload, commandAcrAndBranches)

    }
    else {
      const selectedOptions = ddlBranchesDev.filter(option => option.value === selectedBranchesDev[0]);
      payload = {
        projectName: selectedProject,
        appName: serviceNameValue,
        feedName: "infrastructure-justeattakeaway-il",
        createBuildSubFolder: serviceNameValue === '' ? false : toggleExcludeBuildValue,
        createReleaseSubFolder: serviceNameValue === '' ? false : toggleExcludeReleaseValue,
        solutionName: selectedSolutionFile.split('.')[0], // without the sln
        approvalManagerEmail: selectedManager,
        repositoryName: repoNameFromAutoComplete,
        repositoryExists: true,
        migrationFlow: true,
        solutionType: "WebApi",
        projectSettingsFile: selectedSolutionCsproj,
        solutionFile: selectedSolutionFile,
        objectId: selectedOptions[0].objectId,
        deployToDevCluster: deployToDevCluster,
        automationTest: automationTest,
        scmType: cloudDecision === 'azure' ? 'AzureDevOps' : 'GitHub'
      }
      addImagePayloadToEKSAndAKS(payload, commandAcrAndBranches)
    }

    instance.post(consts.requests.createCICDAuto, payload).then((response) => {

      if (response) {

        setText(waitingValidMessage)
        setType(1)
        setModalAlertShow(true);
        resetFormFields()

      }

    }).catch((error) => {

      const ErrorMsg = () => (
        <div>Error during request process</div>
      )
      toast.error(<ErrorMsg />)




    }).finally(() => { })



  }

  const invokeNewRequest = () => {

    if (repoDecision !== 'new') {
      requestQueue.enqueue({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
    }
    else {
      requestQueue.enqueue({ repositoryName: repoNameValue, applicationName: appNameValue });
    }


    let map = buildCommand();
    let commandArguments = Object.fromEntries([...map]);


    const dictionary = new Map();

    if (selectedACRDev && selectedACRDev.length > 0) {

      if (selectedACRDev.length > 1) {
        selectedACRDev.forEach((element, index) => {

          dictionary.set(element, selectedBranchesDev);
        });
      }
      else {
        dictionary.set(selectedACRDev, selectedBranchesDev);
      }


    }
    if (selectedACRProd && selectedACRProd.length > 0) {

      if (selectedACRProd.length > 1) {
        selectedACRProd.forEach((element, index) => {
          let arr = [selectedBranchesProd];
          dictionary.set(element, arr);
        });
      }
      else {
        let arr = [selectedBranchesProd];
        dictionary.set(selectedACRProd, arr);
      }


    }
    let payload = {};
    let commandAcrAndBranches = Object.fromEntries([...dictionary]);
    if (repoDecision === 'new') {


      //remove prefix convention
      const prefix = "prefix:";

      const filteredPrefixPayload = keyVaultPayloadData.map(item => ({
        ...item,
        secret: item.secret.map(value =>
          value.startsWith(prefix) ? value.replace(prefix, '') : value
        )
      }));


      //end remove prefix convention



      payload = {
        projectName: ddlProjects.filter(d => d.value === selectedProject)[0].name,
        solutionName: appNameValue,
        appName: serviceNameValue,
        repositoryName: repoNameValue,
        dataBaseTeamName: dbNameValue === "" ? null : selectedDBGroup,
        dataBaseName: selectedDBGroup === "" || dbNameValue === "" ? null : dbNameValue,
        approvalManagerEmail: selectedManager,
        solutionType: selectedSolutionType,
        framework: selectedFramework,
        solutionArguments: commandArguments,
        buildPipelinePath: selectedBuildFolder,
        releasePipelinePath: selectedReleaseFolder,
        repositoryExists: repoNameFromAutoComplete !== "" ? true : false,
        feedName: selectedFeed,
        eksSecretsInput: filteredPrefixPayload,
        aksSecretsInput: [],
        createBuildSubFolder: serviceNameValue === '' ? false : toggleExcludeBuildValue,
        createReleaseSubFolder: serviceNameValue === '' ? false : toggleExcludeReleaseValue,
        automationTest: automationTest,
        scmType: cloudDecision === 'azure' ? 'AzureDevOps' : 'GitHub'
      }



    }
    else {
      payload = {
        projectName: ddlProjects.filter(d => d.value === selectedProject)[0].name,
        appName: serviceNameValue,
        repositoryName: repoNameFromAutoComplete,
        dataBaseTeamName: dbNameValue === "" ? null : selectedDBGroup,
        dataBaseName: selectedDBGroup === "" || dbNameValue === "" ? null : dbNameValue,
        approvalManagerEmail: selectedManager,
        solutionType: selectedSolutionType,
        buildPipelinePath: selectedBuildFolder,
        releasePipelinePath: selectedReleaseFolder,
        repositoryExists: true,
        feedName: selectedFeed,
        createBuildSubFolder: serviceNameValue === '' ? false : toggleExcludeBuildValue,
        createReleaseSubFolder: serviceNameValue === '' ? false : toggleExcludeReleaseValue,
        automationTest: automationTest,
        scmType: cloudDecision === 'azure' ? 'AzureDevOps' : 'GitHub',
        dockerfilePath: dockerfilePath

      }
    }
    addImagePayloadToEKSAndAKS(payload, commandAcrAndBranches)

    instance.post(consts.requests.createCICDAuto, payload).then((response) => {

      if (response) {

        setText(waitingValidMessage)
        setType(1)
        setModalAlertShow(true);
        resetFormFields()

      }

    }).catch((error) => {

      const ErrorMsg = () => (
        <div>Error during request process</div>
      )
      toast.error(<ErrorMsg />)

      let isExist;
      if (repoDecision !== 'new') {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
      }
      else {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameValue, applicationName: appNameValue });
      }

      if (isExist) {
        if (repoDecision !== 'new') {
          requestQueue.remove({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
        }
        else {
          requestQueue.remove({ repositoryName: repoNameValue, applicationName: appNameValue });
        }
      }


    }).finally(() => { })


  }


  const getImageType = (key) => {
    let managedKubernetesServiceType = ''
    let typeDev = ddlAcrDev.find(d => d.value === key);
    if (typeDev) {

      managedKubernetesServiceType = typeDev.managedKubernetesService
    }

    let typeProd = ddlAcrProd.find(d => d.value === key);
    if (typeProd) {

      managedKubernetesServiceType = typeProd.managedKubernetesService
    }
    return managedKubernetesServiceType;
  }

  const addImagePayloadToEKSAndAKS = (payload, commandAcrAndBranches) => {


    let imageRegisteriesArray = []
    Object.entries(commandAcrAndBranches).forEach(([key, value]) => {

      value.forEach((element, index) => {

        let imageObject = imageRegisteriesArray.find(img => img.environment === element);
        if (imageObject !== undefined) {

          imageObject.managedKubernetesService.push({
            type: getImageType(key),
            guid: key
          })

        }
        else {
          var image = {};
          image.environment = element

          if (image.managedKubernetesService === undefined) {
            image.managedKubernetesService = []
          }
          image.managedKubernetesService.push({
            type: getImageType(key),
            guid: key
          })
          imageRegisteriesArray.push(image)
        }


      })


    });
    payload.imageRegisteries = imageRegisteriesArray


  }

  const resetFormFields = (preventSettings = false) => {

    setServiceNameValue('')
    setAppNameValue('')
    if (appNameRef?.current?.value) {
      appNameRef.current.value = ''
    }
    if (serviceNameRef?.current?.value) {
      serviceNameRef.current.value = ''
    }
    setCopilotObject(null)
    setCopilotButtonDisable(false)
    setDdlSolutions([])
    setDdlSolutionCsproj([])
    setSelectedSolution('')
    setDeployToDevCluster(false)
    setSelectedSolutionFile('')
    setSelectedSolutionCsproj([])
    setDBNameValue('')
    setRepoNameValue('')
    setSelectedBranchesProd('')
    setSelectedBranchesDev([])
    setSelectedDBGroup('')
    setDdlBranchesDev([])
    setDdlBranchesProd([])
    setSelectedManager('')
    setSelectedACRDev([])
    setSelectedACRProd([])
    setSelectedProject('')
    setSelectedFeed('')
    setDdlAcrDev([])
    setDdlAcrProd([])
    setRepoNameFromAutoComplete('')
    setSelectedSolutionType('')
    setSelectedFramework('')
    setServiceDescription('')
    setSelectedTeam('')
    setSelectedDeperatmentManager('')
    setSelectedSubDeperatmentManager('')
    setSelectedTier('')

    setSelectedBuildFolder("")
    setSelectedReleaseFolder("")

    setSelectedEKSAndKV('No')
    setSelectedSubscriptions('')
    setSelectedKVObject('')
    setSelectedSecrets([])
    setPrefixValue('')

    setSelectedAppConfig('')
    setSelectedAppConfigUrl('')
    setExistingRepoNameInValid(false)
    if (!preventSettings) {
      /*etDecision('')
      setRepoDecision('')*/
      setProcessEnded(true)

    }



    if (teamLeaderRef?.current?.value) {
      teamLeaderRef.current.value = ''
    }

    if (teamLeaderIdRef?.current?.value) {
      teamLeaderIdRef.current.value = ''
    }
    if (teamNameRef?.current?.value) {
      teamNameRef.current.value = ''
    }

    setSelectedAppConfig(false)
    setSelectedAppConfigUrl('')

    setSelectedEFAndMongo('None')

    setToggleExcludeBuildValue(false)
    setToggleExcludeReleaseValue(false)

    setErrorList([])



  }



  const startSignalRConnection = async () => {

    if (connection && connection?._connectionState === 'Connected') {
      setConnection(connection)
      if (typeDecision === 'migrate') {
        invokeNewRequestForMigration();
      }
      else {
        invokeNewRequest();
      }

    }
    else {
      let expiresOnUnixString = localStorage.getItem("InfraConsoleExpired");
      const expiresOnUnix = parseInt(expiresOnUnixString, 10);

      const now = DateTime.now();
      const expiresOn = DateTime.fromSeconds(expiresOnUnix);



      if (now < expiresOn) {
        generateConnection(token)
      } else {
        console.error('Token expired');
        let newAccessToken = await userLogin();
        setToken(newAccessToken?.accessToken)
        generateConnection(newAccessToken?.accessToken)


        // Handle token expiration (e.g., redirect to login page)
      }






    }

  }

  const generateConnection = (token) => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_HUB_URL, {
        accessTokenFactory: () => token
      })
      .build();

    setConnection(newConnection);
  }

  const handleChangeSubscriptions = (e) => {

    setSelectedSubscriptions(e.target.value)
  }

  const handleChangeKVName = (e) => {
    setSelectedKVObject(e.target.value)
  }


  /*
    const getCICDAutoProjects = async () => {
  
      try {
        return await cicdAxios.get(consts.requests.getCICDAutoProjects, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
  
      }
      catch (error) {
  
      }
  
    }
  
    const getCICDDatabaseNames = async () => {
  
      try {
        return await cicdAxios.get(consts.requests.getCICDDatabaseNames, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      }
      catch (error) {
  
      }
  
    }*/



  const handleChangeProject = async (e) => {


    setSelectedProject(e.target.value)
    await fillACRDropDown(e.target.value);
    fillFeedDropdown(e.target.value);
    setSelectedACRDev([])
    setSelectedACRProd([])
    setSelectedBranchesProd('')
    setSelectedBranchesDev([])

  }
  const handleChangeFeed = (e) => {
    setSelectedFeed(e.target.value)

  }

  const handleChangeACRDev = (e) => {

    if (copilotObject !== null) {
      setTimeout(() => {
        setSelectedACRDev(e.target.value)

      }, [100])
    }
    else {
      setSelectedACRDev(e.target.value)

    }
    setSelectedBranchesDev([])



  }

  const handleChangeSecrets = (e) => {
    setSelectedSecrets(e.target.value)
  }

  const handleChangeACRProd = (e) => {
    setSelectedACRProd(e.target.value)
    if (repoDecision !== 'existing') {
      setSelectedBranchesProd('')
    }
    else {
      setSelectedBranchesProd(selectedBranchesDev);

    }



  }



  const fillFeedDropdown = (selectedProject) => {


    if (cloudDecision === 'azure') {
      let url = consts.requests.getCICDAutoFeed.replace('{projectName}', selectedProject);
      instance.get(url).then((response) => {

        var resource = [];
        if (response?.data?.content) {
          response.data.content?.map((feed) => {
            resource.push({
              name: feed.name,
              value: feed.name
            })
          })
        }
        else {
          setDdlFeeds([])
        }

        setDdlFeeds(resource)


      }).catch((error) => {
        setDdlFeeds([])
      })
    }
    else {
      let url = consts.requests.getCICDAutoFeed.replace('{projectName}', 'infrastructure');
      instance.get(url).then((response) => {

        var resource = [];
        if (response?.data?.content) {
          response.data.content?.map((feed) => {
            resource.push({
              name: feed.name,
              value: feed.name
            })
          })
        }
        else {
          setDdlFeeds([])
        }

        setDdlFeeds(resource)


      }).catch((error) => {
        setDdlFeeds([])
      })
    }







  }

  const handleChangeSolutionCsproj = async (e) => {
    if (typeDecision === 'migrate') {
      setSelectedSolutionCsproj(e.target.value)
    }
  }

  const handleChangeSolution = async (e) => {

    if (typeDecision === 'migrate') {

      if (cloudDecision === 'azure') {
        const values = e.target.value.split('|');
        setSelectedSolution(e.target.value);
        setSelectedSolutionFile(values[1])


        if (!selectedBranchesDev || selectedBranchesDev.length === 0) {
          displayErrorToast('Please select branch')
          return;
        }
        let response = await getCSProjFiles(selectedProject, repoNameFromAutoComplete, selectedBranchesDev, values[0])
        if (response.status === 200) {
          let resource = []
          response.data.content.map((i) => (
            resource.push({
              name: i.name,
              value: i.name,
            })

          ))
          setDdlSolutionCsproj(resource)
        }
        else {
          displayErrorToast('We could not fetch the project files')
        }
      }
      else {
        const values = e.target.value.split('|');
        setSelectedSolution(e.target.value);
        setSelectedSolutionFile(values[1])


        if (!selectedBranchesDev || selectedBranchesDev.length === 0) {
          displayErrorToast('Please select branch')
          return;
        }

        const selectedOptions = ddlBranchesDev.filter(option => option.value === selectedBranchesDev[0]);
        let response = await githubProjectSettingsFile(selectedProject, repoNameFromAutoComplete, selectedOptions[0].objectId, values[1])
        if (response.status === 200) {
          let resource = []
          response.data.content.map((i) => (
            resource.push({
              name: i.name,
              value: i.name,
            })

          ))
          setDdlSolutionCsproj(resource)
        }
        else {
          displayErrorToast('We could not fetch the project files')
        }
      }

    }
    else if (repoDecision === 'existing') {
      //taltal

      let res = ddlSolutions.filter(x => x.name === e.target.value.split('|')[1])
      if (res.length > 0) {
        setDockerfilePath(res[0].dockerfilePath)
      }
      setSelectedSolution(e.target.value);
    }
  }

  const handleChangeBranchesDev = async (e) => {



    if (typeDecision === 'migrate') {

      if (cloudDecision === 'azure') {
        setSelectedBranchesDev([e.target.value]);
        let response = await getSolutions(selectedProject, repoNameFromAutoComplete, e.target.value)
        if (response.status === 200) {
          let resource = []
          response.data.content.map((i) => (
            resource.push({
              name: i.name,
              value: i.parentDir + "|" + i.name
            })

          ))
          setDdlSolutions(resource)
        }
        else {
          displayErrorToast('We could not fetch the solution files')
        }
      }
      else {

        const selectedOptions = ddlBranchesDev.filter(option => option.value === e.target.value);
        setSelectedBranchesDev([e.target.value]);
        let response = await githubSolutionFile(selectedProject, repoNameFromAutoComplete, selectedOptions[0].objectId)
        if (response.status === 200) {
          let resource = []
          response.data.content.map((i) => (
            resource.push({
              name: i.name,
              value: i.parentDir + "|" + i.name
            })

          ))
          setDdlSolutions(resource)
        }
        else {
          displayErrorToast('We could not fetch the solution files')
        }
      }


    }
    else {


      if (repoDecision === 'existing') {
        setSelectedBranchesDev([e.target.value]);

      }
      else {
        setSelectedBranchesDev(e.target.value);
      }

    }
  }
  const handleChangeBranchesProd = (e) => {
    setSelectedBranchesProd(e.target.value);
  }

  const handleBlur = (e) => {


    if (pattern.test(e.target.value)) {
      setRegexError(false);
      setRegexShake(false);

    }

  };

  const handleDBNameBlur = (e) => {
    if (patternDBName.test(e.target.value)) {
      setRegexDBNameError(false);
      setRegexShake(false);

    }

  }

  const handleKVModalError = (msg) => {

    const ErrorMsg = () => (
      <div>{msg}</div>
    )
    toast.error(<ErrorMsg />)
  }

  const handleChangeAzureKV = (e, isFromEdit) => {

    if (isFromEdit) {
      setOpenByEdit(isFromEdit)


    }

    let option = e
    if (e.target !== undefined) {
      option = e.target.value;
    }

    if (option === 'No') {
      setSelectedEKSAndKV(option)
      setSubscriptions([])
      setDdlSubscriptions([])
      setSelectedSubscriptions('')
      setDdlKVList([])
      setSelectedKVObject('')
      setDdlSecrets([])
      setSelectedSecrets([])
      setKeyVaultPayloadData([])


    }

    if (option === 'Yes') {
      //check if the user select dev cluster without branches ||| select prod cluster without branches

      //open modal

      if (selectedACRDev.length === 0 && selectedACRProd.length === 0 && selectedBranchesProd.length === 0 && selectedBranchesDev.length === 0) {

        handleKVModalError("Please select cluster dev/prod and at least 1 branch for each cluster")
        return;
      }

      if (selectedACRDev.length > 0 && selectedBranchesDev.length === 0) {
        handleKVModalError("Please select dev, staging or both for dev cluster")
        return;
      }

      if (selectedACRDev.length === 0 && selectedBranchesDev.length > 0) {
        handleKVModalError("Dev cluster wasn't selected")
        return;
      }


      if (selectedBranchesProd.length > 0 && selectedACRProd.length === 0) {
        handleKVModalError("Please select main or msater branch for prod cluster")
        return;
      }

      if (selectedBranchesProd.length === 0 && selectedACRProd.length > 0) {
        handleKVModalError("Prod cluster wasn't selected")
        return;
      }

      if (!isFromEdit) {
        setSelectedEKSAndKV(option)
      }

      setModalKVShow(true)
    }

  }
  const handleDBNameChange = (e) => {


    if (patternDBName.test(e.target.value)) {
      setDBNameValue(e.target.value)
      setRegexDBNameError(false)

    }
    else {
      setRegexDBNameError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }
  }

  const handleServiceNameBlur = (e) => {
    if (patternServiceName.test(e.target.value)) {
      setRegexServiceError(false);
      setRegexShake(false);

    }


  }



  const handleServiceNameChange = (e) => {
    if (patternServiceName.test(e.target.value)) {
      setServiceNameValue(e.target.value)
      setRegexServiceError(false)
      setCopilotButtonDisable(true)


    }
    else {
      setCopilotButtonDisable(false)
      setRegexServiceError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }



  }

  const handleSecretPrefixChange = (e) => {
    setPrefixValue(e.target.value);
  }

  const handleAppNameChange = (e) => {
    if (pattern.test(e.target.value)) {
      setAppNameValue(e.target.value)
      setRegexError(false)

    }
    else {
      setRegexError(true);
      setRegexShake(true)
      setTimeout(() => {
        setRegexShake(false);
      }, 500);
      return;
    }
  }
  const handleRepoNameChange = (e) => {
    setRepoNameValue(e.target.value)

  }




  const handleCreateRequest = (e) => {



    e.preventDefault();

    let validation = true;
    let errorListArr = [];
    if (regexError || regexServiceError) {
      validation = false
      setRegexShake(true);
      setShake(true)
      setTimeout(() => {
        setRegexShake(false);
        setShake(false)
      }, 500);
      return;
    }


    if (repoDecision === 'existing' && selectedSolution === '') {
      errorListArr.push('Solution is required')
    }


    if (selectedAppConfig === true && selectedAppConfigUrl === "" && repoDecision === 'new') {

      errorListArr.push('App config url is required')
    }



    if (selectedAppConfig === false && selectedAppConfigUrl !== "" && repoDecision === 'new') {

      errorListArr.push('Selected App config is required')
    }



    if (appNameValue === "" && repoDecision === 'new') {

      errorListArr.push('App name is required')
    }


    if (serviceNameValue === "") {

      errorListArr.push('Service name is required')
    }



    if (repoNameValue === "" && repoDecision === 'new') {

      errorListArr.push('Repository name is required')
    }

    if (repoNameFromAutoComplete === "" && repoDecision !== 'new') {

      errorListArr.push('Existing repository is required')
    }

    if (selectedBuildFolder === "" && !toggleExcludeBuildValue && cloudDecision === 'azure') {

      errorListArr.push('Build folder is required')
    }


    if (selectedReleaseFolder === "" && !toggleExcludeReleaseValue && cloudDecision === 'azure') {

      errorListArr.push('Release folder is required')
    }

    if (selectedACRDev.length > 0 && selectedBranchesDev.length === 0) {

      errorListArr.push('Dev branches are required')
    }

    if (selectedACRDev.length === 0) {

      errorListArr.push('Dev cluster is required')
    }


    if (selectedACRProd.length > 0 && selectedBranchesProd.length === 0) {

      errorListArr.push('Prod branches are required')
    }


    if (selectedACRProd.length === 0 && selectedBranchesProd.length > 0) {

      errorListArr.push('Prod cluster is required')
    }


    if (selectedProject === "") {

      errorListArr.push('Project is required')
    }

    if (selectedManager === "") {

      errorListArr.push('Approval manager is required')
    }

    if (selectedSolutionType === "") {

      errorListArr.push('Solution type is required')
    }

    if (selectedTier === "" && repoDecision === 'new') {

      errorListArr.push('Tier is required')
    }

    if (selectedFeed === "" && cloudDecision === 'azure') {

      errorListArr.push('Feed is required')
    }

    if (serviceDescription === "" && repoDecision === 'new') {

      errorListArr.push('Description is required')
    }

    if (selectedTeam === "" && repoDecision === 'new') {

      errorListArr.push('Team is required')
    }

    if (selectedSubDeperatmentManager === "" && repoDecision === 'new') {

      errorListArr.push('Sub department manager is required')
    }

    if (selectedDeperatmentManager === "" && repoDecision === 'new') {

      errorListArr.push('Department manager is required')
    }

    if (teamLeaderRef.value === "" && repoDecision === 'new') {

      errorListArr.push('Team leader is required')
    }


    if (selectedFramework === "" && repoDecision === 'new') {

      errorListArr.push('Framework is required')
    }

    setErrorList(errorListArr)




    if (token && errorListArr.length === 0) {
      let isExist;

      if (repoDecision !== 'new') {
        if (existingRepoNameInValid) {
          errorListArr.push('Repository name doesn\'t exist')
          return;
        }
        else {
          isExist = requestQueue.isItemExist({ repositoryName: repoNameFromAutoComplete, applicationName: appNameValue });
        }

      }
      else {
        isExist = requestQueue.isItemExist({ repositoryName: repoNameValue, applicationName: appNameValue });
      }

      if (isExist) {

        setText(waitingInValidMessage)
        setType(3) // duplicate
        setModalAlertShow(true)

      }
      else {

        startSignalRConnection();
      }

    }
    else {

      const ErrorMsg = () => (
        <div>Please populate all the required fields below</div>
      )
      toast.error(<ErrorMsg />)
    }

  }

  const handleOpenDev = (e) => {
    setDdlBranchesDev([])

    if (repoNameFromAutoComplete === "" && (repoDecision !== 'new' || typeDecision === 'migrate')) {

      const ErrorMsg = () => (
        <div>Please enter repository name in the 'Existing Repositories' field</div>
      )
      toast.error(<ErrorMsg />)
      return;
    }
    if (repoDecision !== 'new') {
      buildClusterComponents('both', e);

    }
    else if (repoDecision !== 'new' || typeDecision === 'migrate') {
      buildClusterComponents('dev', e);
    }
    else {

      setDdlBranchesDev([...ddlBranchesDevCached])

    }
  }
  const handleOpenProd = (e) => {


    setDdlBranchesProd([])
    if (repoNameFromAutoComplete === "" && repoDecision !== 'new') {

      const ErrorMsg = () => (
        <div>Please enter repository name in the 'Existing Repositories' field</div>
      )
      toast.error(<ErrorMsg />)
      return;
    }
    if (repoDecision !== 'new') {
      buildClusterComponents('prod', e);
    }
    else {

      setDdlBranchesProd([...ddlBranchesProdCached])

      /*if (type === 'prod') {
        setDdlBranchesProd([...ddlBranchesProdCached])
      }*/
    }

  }


  const buildClusterComponents = async (type, e) => {

    if (selectedProject === "") {
      setDdlBranchesDev([])
      const ErrorMsg = () => (
        <div>Please select project</div>
      )
      toast.error(<ErrorMsg />)
      return;
    }

    else {
      if (existingRepoNameInValid) {


        const ErrorMsg = () => (
          <div>A repository doesn't exist. Please choose from the list</div>
        )
        toast.error(<ErrorMsg />)
        return;


      }
      if (cloudDecision === 'azure') {
        let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);
        var resource = [];
        results.data.content.map((r) => {

          resource.push({
            name: r.name,
            value: r.name,
            objectId: r.objectId
          })
        })
        if (type === 'dev') {
          setDdlBranchesDev(resource)
        }
        if (type === 'prod') {
          setDdlBranchesProd(resource)
        }
        if (type === 'both') {

          setDdlBranchesDev(resource)
          setDdlBranchesProd(resource)
        }
      }
      else {
        let results = await githubBranches(selectedProject, repoNameFromAutoComplete);
        var resource = [];
        results.data.content.map((r) => {

          resource.push({
            name: r.name,
            value: r.name,
            objectId: r.objectId
          })
        })
        if (type === 'dev') {
          setDdlBranchesDev(resource)
        }
        if (type === 'prod') {
          setDdlBranchesProd(resource)
        }
      }











      /*if (type === 'dev') {
 
        var cloneDdlBrancedDev = [...ddlBranchesDev]
        setDdlBranchesDev([])
        let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);
 
        results.data.content.map((r) => {
 
          if (r.name.toLowerCase() === 'dev') {
 
            let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'dev');
            if (newDdl.length > 0) {
 
              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
 
            }
          }
 
          if (r.name.toLowerCase() === 'staging') {
 
            let newDdl = cloneDdlBrancedDev.filter((d) => d.name.toLowerCase() === 'staging');
            if (newDdl.length > 0) {
 
              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
 
            }
          }
          setDdlBranchesDev([...cloneDdlBrancedDev]);
        })
      }
      if (type === 'prod') {
 
        var cloneDdlBrancedProd = [...ddlBranchesProd]
        setDdlBranchesProd([])
        let results = await getSelfServiceBranchesForExsitingRepo(selectedProject, repoNameFromAutoComplete);
 
 
        results.data.content.map((r) => {
 
          if (r.name.toLowerCase() === 'main') {
 
            let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'main');
            if (newDdl.length > 0) {
 
              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
            }
          }
          if (r.name.toLowerCase() === 'master') {
 
            let newDdl = cloneDdlBrancedProd.filter((d) => d.name.toLowerCase() === 'master');
            if (newDdl.length > 0) {
 
              newDdl[0].name = newDdl[0].name + " (Already Exist)";
              newDdl[0].disable = true;
            }
          }
          setDdlBranchesProd([...cloneDdlBrancedProd]);
        })
      }*/
    }


  }




  const handleTabChange = async (event, newValue) => {

    setTabsAutoCICDValue((prevState) => ({ TabValue: newValue }));
    resetFormFields(true)

    if (decision === '') {
      setTabsAutoCICDValue({ TabValue: '0' })
    }


  }

  const handleChangeManager = (e) => {
    setSelectedManager(e.target.value);


  }

  const handleChangeFramework = (e) => {
    setSelectedFramework(e.target.value)
    if (e.target.value === '.Net6') {
      setSelectedAppConfig(false)
      setSelectedAppConfigUrl('')

      setSelectedEFAndMongo('None')

    }
  }

  const handleChangeSolutionType = (e) => {
    setSelectedSolutionType(e.target.value)
  }

  const handleSelectedTeam = (e) => {

    setSelectedTeam(e.target.value);

    let manager = listOfTeams.filter(l => l.id === e.target.value)
    if (manager.length > 0) {
      teamLeaderRef.value = manager[0].managerName;
      teamLeaderIdRef.value = manager[0].managerUserId;
      teamNameRef.value = manager[0].name;

      setSelectedSubDeperatmentManager('')
      setSelectedDeperatmentManager('')
      let teamLeaderSubDepartmentManager = listOfDepartments.filter(l => l.id === manager[0].departmentId);
      if (teamLeaderSubDepartmentManager && teamLeaderSubDepartmentManager.length > 0) {
        if (teamLeaderSubDepartmentManager[0].parentDepartmentId !== "") {
          setSelectedSubDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
        }
        if (teamLeaderSubDepartmentManager[0].parentDepartmentId === "") {
          setSelectedDeperatmentManager(teamLeaderSubDepartmentManager[0].id)
        }

        let teamLeaderDepartmentManager = listOfDepartments.filter(l => l.id === teamLeaderSubDepartmentManager[0].parentDepartmentId);

        if (teamLeaderDepartmentManager && teamLeaderDepartmentManager.length) {
          setSelectedDeperatmentManager(teamLeaderDepartmentManager[0].id)
        }

      }


    }



  }

  const handleServiceDescription = (e) => {
    setServiceDescription(e.target.value)

  }
  const handleChangeTier = (e) => {
    setSelectedTier(e.target.value);
  }

  const handleChangeDBGroup = (e) => {
    setSelectedDBGroup(e.target.value)
  }

  const openPipelineTreeModal = (type) => {
    if (selectedProject === "") {
      const WarnMsg = () => (
        <div>Please select the project you're working in</div>
      )
      toast.warn(<WarnMsg />)
      return;
    }
    if (type === 'build') {
      setShowBuildPipelineTree(true)
    }
    else {
      setShowReleasePipelineTree(true)
    }

  }

  const handleAutoChange = (value) => {

    const autoCompleteArray = _.filter(repositories, element => _.includes(element.name.toLowerCase(), value.toLowerCase()));
    if (autoCompleteArray.length > 0) {
      setExistingRepoNameInValid(false)
      setOptions(autoCompleteArray)
    }
    else {
      setExistingRepoNameInValid(true)
    }


  }

  const getOptionLabel = (option) => {
    return `${option.name}`;
  }

  const handleDelete = (value, newInput) => {

    setExistingRepoNameInValid(false)
    setSelectedACRDev([])
    setSelectedACRProd([])
    if (newInput === "") {

      setRepoNameFromAutoComplete("")

    }
    else {
      setRepoNameFromAutoComplete(newInput)
    }
  }

  const handleResetPlatform = () => {

    setDecision('aks')
    setCloudDecision('azure')
    setTypeDecision('new')
    setRepoDecision('new')
    setMigrationType('aks')
    setModalAKSEKSShow(true)
    setDdlAcrDev([])
    setDdlAcrProd([])
    setSelectedProject('')
    resetFormFields()

  }

  const handleChangeAppDdlConfig = (e) => {
    setSelectedAppConfig(e.target.value)
    if (e.target.value === false) {
      setSelectedAppConfigUrl('')
    }
  }

  const handleAppConfigChange = (e) => {
    setSelectedAppConfigUrl(e.target.value)
  }


  const handleRadioChange = (e) => {


    setSelectedEFAndMongo(e.target.value)
  }



  const handleUserClick = () => { }




  const bindJsonObject = (args = null) => {
    if (args !== null) {
      setCopilotObject(args);

    }
  };




  const bindFormDetails = async () => {



    if (copilotObject?.appName) {
      setToggleExcludeBuildValue(true)
      setToggleExcludeReleaseValue(true)
      setFolderName(copilotObject.appName)
    }



    if (copilotObject?.appName) {
      handleServiceNameChange(createTargetEventModel(copilotObject.appName))

      if (serviceNameRef?.current) {
        serviceNameRef.current.value = copilotObject.appName;
      }
    }

    if (copilotObject?.framework) {
      handleChangeFramework(createTargetEventModel(copilotObject.framework))
    }

    if (copilotObject?.solutionType) {
      handleChangeSolutionType(createTargetEventModel(copilotObject.solutionType))
    }

    if (copilotObject['solutionArguments']['--Description'] && copilotObject['solutionArguments']['--Description'] !== '') {
      handleServiceDescription(createTargetEventModel(copilotObject['solutionArguments']['--Description']))
    }
    if (copilotObject?.projectName) {
      await handleChangeProject(createTargetEventModel(copilotObject.projectName))

    }

    if (copilotObject?.repositoryName) {
      handleRepoNameChange(createTargetEventModel(copilotObject.repositoryName))
    }

    if (copilotObject?.feedName) {
      handleChangeFeed(createTargetEventModel(copilotObject.feedName))
    }

    if (copilotObject?.solutionName) {

      if (copilotObject.solutionName.length === 0) return;
      let value = copilotObject.solutionName.charAt(0).toUpperCase() + copilotObject.solutionName.slice(1);

      handleAppNameChange(createTargetEventModel(value))
      if (appNameRef?.current) {
        appNameRef.current.value = value;
      }

    }
    if (copilotObject['solutionArguments']['--TeamId'] && copilotObject['solutionArguments']['--TeamId'] !== '') {

      handleSelectedTeam(createTargetEventModel(copilotObject['solutionArguments']['--TeamId']))
    }

    if (copilotObject['solutionArguments']['--Tier'] && copilotObject['solutionArguments']['--Tier'] !== '') {
      handleChangeTier(createTargetEventModel(copilotObject['solutionArguments']['--Tier']))
    }


    if (copilotObject?.approvalManagerEmail) {
      handleChangeManager(createTargetEventModel(copilotObject.approvalManagerEmail))
    }


  }

  useEffect(() => {
    if (ddlBranchesDev.length > 0 && copilotObject !== null && selectedACRDev.length > 0) {


      let env = []
      for (let i = 0; i < copilotObject.imageRegisteries.length; i++) {
        if (copilotObject.imageRegisteries[i]?.environment && copilotObject.imageRegisteries[i]?.environment !== '') {
          if (!env.includes(copilotObject.imageRegisteries[i].environment)) {
            env.push(copilotObject.imageRegisteries[i].environment)
          }

          setSelectedBranchesDev(env);


        }
      }
      setCopilotObject(null)
    }
  }, [ddlBranchesDev, selectedACRDev])


  useEffect(() => {

    if (ddlAcrDev.length > 0 && copilotObject !== null) {


      if (copilotObject.imageRegisteries && copilotObject.imageRegisteries.length > 0) {

        for (let i = 0; i < copilotObject.imageRegisteries.length; i++) {
          let acr = []

          if (copilotObject.imageRegisteries[i]?.managedKubernetesService && copilotObject.imageRegisteries[i]?.managedKubernetesService.length > 0) {

            for (let y = 0; y < copilotObject.imageRegisteries[i].managedKubernetesService.length; y++) {
              if (!acr.includes(copilotObject.imageRegisteries[i].managedKubernetesService[y].guid)) {
                acr.push(copilotObject.imageRegisteries[i].managedKubernetesService[y].guid)

              }

            }

            handleChangeACRDev(createTargetEventModel(acr))
          }

        }




      }
    }

  }, [ddlAcrDev])

  const createTargetEventModel = (value) => {
    return {
      target: {
        value: value
      }
    };
  }

  const handleClickCopilot = async () => {

    try {
      if (!serviceNameValue || serviceNameValue === "") {
        displayErrorToast('Service name required for this action')
        return;;
      }

      setModalAIMessageShow(true)
      let prompt = `service name = "${serviceNameValue}" and platform = "${decision}"`;

      let res = await selfServiceCopilotJson(prompt)
      setModalAIMessageShow(false)

      let obj = JSON.parse(res.data);

      setCopilotObject(obj)





    } catch (error) {
      setModalAIMessageShow(false)
    }



  }

  useEffect(() => {
    if (copilotObject !== null) {
      bindFormDetails()
    }
  }, [copilotObject])

  const handleAutomationTestChange = () => {
    setAutomationTest(!automationTest)
  }

  const handleChangeDeploy = (e) => {
    setDeployToDevCluster(e.target.value)
  }


  const handleCreateMigrationRequest = () => {
    let errorListArr = []
    if (selectedProject === '') {
      errorListArr.push('Project is required')
    }
    if (serviceNameValue === "") {
      errorListArr.push('Service name is required')
    }
    if (selectedBranchesDev.length === 0) {
      errorListArr.push('Branch is required')

    }
    if (selectedACRDev.length > 0 && selectedBranchesDev.length === 0) {

      errorListArr.push('Dev branches are required')
    }

    if (selectedACRDev.length === 0) {

      errorListArr.push('Dev cluster is required')
    }
    if (selectedSolution === '') {
      errorListArr.push('Solution is required')
    }
    if (selectedSolutionCsproj.length === 0) {
      errorListArr.push('csproj file is required (at least 1)')
    }
    if (repoNameFromAutoComplete === '') {
      errorListArr.push('Repository is required')
    }
    if (selectedBuildFolder === "" && !toggleExcludeBuildValue && cloudDecision === 'azure') {

      errorListArr.push('Build folder is required')
    }
    if (selectedReleaseFolder === "" && !toggleExcludeReleaseValue && cloudDecision === 'azure') {

      errorListArr.push('Release folder is required')
    }

    if (selectedFeed === "" && cloudDecision === 'azure') {

      errorListArr.push('Feed is required')
    }
    if (selectedManager === "") {

      errorListArr.push('Approval manager is required')
    }

    setErrorList(errorListArr)




    //if (token && errorListArr.length === 0) {

    startSignalRConnection();

    //}

  }

  const handleOpenSolutions = async () => {
    //taltal
    if (selectedProject !== '' && repoNameFromAutoComplete !== '' && selectedBranchesDev.length > 0) {
      let response = await getSolutions(selectedProject, repoNameFromAutoComplete, selectedBranchesDev)
      if (response.status === 200) {
        let resource = []

        response.data.content.map((i) => (
          resource.push({
            name: i.name,
            value: i.parentDir + "|" + i.name,
            dockerfilePath: i.dockerfilePath
          })

        ))
        setDdlSolutions(resource)

      }
      else {
        displayErrorToast('We could not fetch the solution files')
      }
    }
    else {
      displayErrorToast('Project, Repository and Branch are required fields')
    }

  }

  const RenderJiraLink = ({ ticket }) => {

    const number = ticket.match(/\d+/)[0];
    let url = process.env.REACT_APP_JIRA_URL + ticket

    return (
      <a className='self-service-jira-ticket' href={url} target='_blank'>JIRA Ticket {number}</a>
    )

  }

  return (
    <div>
      <div className="sc-header self-service-header">
        <SCTop displaySecondLine={false} displayFilter={false} />
      </div>
      <div className="sc-main auto-cicd-container">
        {
          process.env.REACT_APP_DISPLAY_COPILOT_INCIDENT === "true" &&
          (

            !copilotButtonDisable || regexServiceError ?
              (
                <div className='cicd-how-to-title cicd-copilot-btn'>

                  <DPGrayButton text="Copilot - Enter Service Name" disable={true} />



                </div>
              ) :
              (
                <div className='cicd-how-to-title cicd-copilot-btn'>
                  <DPOrangeButton text="Copilot" handleButtonClick={() => handleClickCopilot()} />

                </div>
              )


          )
        }

        {
          isAdminUser &&
          (
            <div className='self-service-toggle-container'>
              <SCToggle handleToggleChange={() => handleAutomationTestChange()} title="Create service in a test mode:" isChecked={automationTest} />
            </div>
          )
        }

        <div className='cicd-how-to-title'><span className='cicd-how-to-title-text'>How to populate the self-service form?</span>
          <a target='_blank' href='https://docs.google.com/document/d/1nI4MJ_GcTMt8YbIzES2ElkmSRe3tJ9M2UXXOCJB-T50/edit#heading=h.z6ne0og04bp5'>
            <span  className='cicd-how-to-link dp-hyperLink'>Click Here</span>
          </a>
        </div>

        <div className='cicd-how-to-title'>
          {
            decision !== "" && tabsAutoCICDValue.TabValue === "0" ?
              (
                <React.Fragment>
                  <span>Settings: </span>
                  {
                    typeDecision === 'new' ?
                      (
                        <span>Create or update service <b>|</b> </span>
                      ) :
                      (
                        <span>Service Migration  <b>|</b> </span>
                      )
                  }
                  {
                    cloudDecision === 'azure' ?
                      (
                        <span>Azure Devops <b>|</b> </span>
                      ) :
                      (
                        <span>GitHub  <b>|</b> </span>
                      )
                  }
                  {
                    typeDecision !== 'new' && migrationType === 'aks' ?
                      (
                        <span>Service To AKS</span>
                      ) : typeDecision !== 'new' && migrationType !== 'aks' &&
                      (
                        <span>Service To EKS</span>
                      )
                  }
                  {
                    decision === 'aks' && typeDecision === 'new' ?
                      (
                        <span>Platform AKS</span>
                      ) : decision === 'eks' && typeDecision === 'new' ?
                        (
                          <span>Platform EKS</span>
                        ) : decision === 'both' && typeDecision === 'new' ?
                          (
                            <span>Platform AKS & EKS</span>
                          ) :
                          (
                            <span></span>
                          )}

                  {repoDecision === 'new' && typeDecision === 'new' ?
                    (
                      <span> <b>|</b> Repository: New</span>
                    ) :
                    (
                      <span> <b>|</b> Repository: Existing</span>
                    )}
                  <span className='auto-cicd-platform-reset dp-hyperLink' onClick={() => handleResetPlatform()}>Change</span>
                </React.Fragment>
              ) :
              (
                <span></span>
              )
          }

        </div>


        <Box sx={{ width: '100%', typography: 'body1' }} style={{ paddingLeft: '10px' }}>
          <TabContext value={tabsAutoCICDValue.TabValue} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <TabList ref={tabListRef} onChange={handleTabChange} aria-label="lab API tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: '#F36805 !important', // Change this to your desired color
                  },
                  '& .MuiTab-root': {
                    color: 'black !important', // Color for unselected tabs
                  },
                  '& .Mui-disabled': {
                    color: 'lightgray !important'
                  }
                }}>
                <Tab className="sc-tab" label="Create Request" value="0" />
                <Tab className="sc-tab" label="View Requests" value="1" />
              </TabList>
            </Box>
            {
              typeDecision === 'new' ?
                (
                  <TabPanel value="0" className='auto-cicd-flex-container'>

                    <div className="auto-cicd-tab-one-container">
                      <div className="auto-cicd-tab-one-first">

                        {
                          errorList.length > 0 &&
                          (


                            <div className="cicd-error-container">
                              {errorList.map((error, index) => (
                                <React.Fragment key={index}>
                                  <div className="cicd-error-cell">{error}</div>

                                </React.Fragment>
                              ))}
                            </div>

                          )
                        }
                      </div>

                      <div className="auto-cicd-tab-one-line2first">
                        <div className='cicd-title'>Service Name</div>
                        <div className='cicd-input'>
                          <SCInputText id="cicd-service-name" refName={serviceNameRef} handleInputValueBlur={handleServiceNameBlur} required={true} size="small" className="md-input" label="Service Name" value={serviceNameValue}
                            handleInputValueChange={(e) => handleServiceNameChange(e)} />

                          {
                            regexServiceError &&
                            (
                              <FormHelperText className='cicd-field-error'>Service name could be with upper/lower case letters and numbers. Words may be separated with dashes (-)</FormHelperText>
                            )

                          }

                        </div>

                        {
                          repoDecision === 'new' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Framework</div>
                              <div className='cicd-input'>
                                <SCDropDownList id="cicd-framework-type" className="md-ddl-edit" label="Select Framework *" componentState={selectedFramework} setComponentState={setSelectedFramework}
                                  size="1000" items={ddlFramework} handleChange={(e) => handleChangeFramework(e)} value={selectedFramework} />
                              </div>
                            </React.Fragment>
                          )
                        }


                        <div className='cicd-title'>Service</div>

                        <SCDropDownList id="cicd-solution-type" className="md-ddl-edit" label="Select Solution Type *" componentState={selectedSolutionType} setComponentState={setSelectedSolutionType}
                          size="1000" items={ddlSolutionType} handleChange={(e) => handleChangeSolutionType(e)} value={selectedSolutionType} />

                        {

                          repoDecision === 'new' &&
                          (
                            <React.Fragment>

                              <div className='auto-cicd-input-container'>

                                <SCTextArea rows={1.9} size="small" name='description' className="md-input" label="Service Description *"
                                  handleInputValueChange={handleServiceDescription} value={serviceDescription} />
                              </div>
                              <div className='auto-cicd-flex-item'>
                                <SCDropDownList id="cicd-selected-team" className="md-ddl-edit" label="Team Name *" componentState={selectedTeam} setComponentState={setSelectedTeam}
                                  size="1000" items={ddlTeams} handleChange={(e) => handleSelectedTeam(e)} value={selectedTeam} />

                                <SCDropDownList id="cicd-department-manager" className="md-ddl-edit" label="Department Manager *" componentState={selectedDeperatmentManager} setComponentState={setSelectedDeperatmentManager}
                                  size="1000" items={ddlDeperatmentManager} handleChange={(e) => handleChangeDeperatmentManager(e)} value={selectedDeperatmentManager} />

                                <SCDropDownList id="cicd-sub-department-manager" className="md-ddl-edit" label="Sub Department Manager *" componentState={selectedSubDeperatmentManager} setComponentState={setSelectedSubDeperatmentManager}
                                  size="1000" items={ddlSubDeperatmentManager} handleChange={(e) => handleChangeSubDeperatmentManager(e)} value={selectedSubDeperatmentManager} />



                                <div className='auto-cicd-input-container'>


                                  <TextField

                                    disabled={true}
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        width: 400
                                      }
                                    }}
                                    inputProps={
                                      { readOnly: true }
                                    } value={teamLeaderRef.value} label="Team Leader *" InputLabelProps={{ shrink: true }} />


                                </div>

                                <SCDropDownList id="cicd-tier" className="md-ddl-edit" label="Tier *" componentState={selectedTier} setComponentState={setSelectedTier}
                                  size="1000" items={ddlTier} handleChange={(e) => handleChangeTier(e)} value={selectedTier} />
                              </div>

                            </React.Fragment>
                          )

                        }

                        <div className='cicd-title'>Repository</div>
                        <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Projects *" componentState={selectedProject} setComponentState={setSelectedProject}
                          size="1000" items={ddlProjects} handleChange={(e) => handleChangeProject(e)} value={selectedProject} />



                        {
                          repoDecision !== 'new' &&
                          (
                            <div>
                              <Autocomplete
                                freeSolo
                                id="search-repos"
                                disableClearable
                                options={options}
                                onChange={(e, value) => handleUserClick(e, value)}

                                getOptionLabel={getOptionLabel}
                                onInputChange={(event, newInputValue) => {
                                  handleDelete(event, newInputValue);
                                }}
                                filterOptions={(options) => options}
                                renderInput={(params) => (
                                  <TextField
                                    className='cicd-repos'
                                    {...params}
                                    label="Existing Repositories"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",

                                    }}

                                    InputLabelProps={{ shrink: true }}
                                    onChange={(event) =>
                                      handleAutoChange(event.target.value)
                                    }



                                  />
                                )}


                              />
                              {
                                existingRepoNameInValid &&
                                (
                                  <FormHelperText className='cicd-field-error'>A repository doesn't exist. Please choose from the list</FormHelperText>
                                )
                              }

                            </div>

                          )
                        }



                        {
                          repoDecision === 'new' &&
                          (
                            <div className='cicd-input'>
                              <SCInputText id="cicd-repository-name" required={true} size="small" className="md-input" label="New Repository" value={repoNameValue}
                                handleInputValueChange={(e) => handleRepoNameChange(e)} />
                            </div>
                          )
                        }

                        {
                          cloudDecision === 'azure' &&
                          (
                            <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Feed Name *" componentState={selectedFeed} setComponentState={setSelectedFeed}
                              size="1000" items={ddlFeeds} handleChange={(e) => handleChangeFeed(e)} value={selectedFeed} />
                          )
                        }

                        {
                          repoDecision === 'existing' &&
                          (
                            <React.Fragment>

                              <div className='cicd-title'>Cluster</div>
                              <div className='cicd-selected-acr-container'>
                                <SCDropDownList id="cicd-selected-acr-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit"
                                  multiple={true} label="Dev cluster*" componentState={selectedACRDev}
                                  setComponentState={setSelectedACRDev}
                                  size="1000" items={ddlAcrDev} handleChange={(e) => handleChangeACRDev(e)} value={selectedACRDev} />

                                <SCDropDownList id="cicd-branches-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" label="Branches *" componentState={selectedBranchesDev}
                                  setComponentState={setSelectedBranchesDev}
                                  size="1000" items={ddlBranchesDev}
                                  handleOpen={(e) => handleOpenDev(e)}
                                  handleChange={(e) => handleChangeBranchesDev(e)} value={selectedBranchesDev} />


                                <SCDropDownList id="cicd-selected-acr-prod" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Prod Cluster" componentState={selectedACRProd}
                                  setComponentState={setSelectedACRProd}
                                  size="1000" items={ddlAcrProd} handleChange={(e) => handleChangeACRProd(e)} value={selectedACRProd} />

                                <SCDropDownList disabled={repoDecision === 'existing' ? true : false} id="cicd-branches-prod" className="md-ddl-edit" label="Branches" componentState={selectedBranchesProd}
                                  helperText={repoDecision === 'existing' && 'The branch you selected in DEV will define the branch of the PROD'}
                                  setComponentState={setSelectedBranchesProd} handleOpen={(e) => handleOpenProd(e)}
                                  size="1000" items={ddlBranchesProd} handleChange={(e) => handleChangeBranchesProd(e)} value={selectedBranchesProd} />
                              </div>
                            </React.Fragment>
                          )
                        }
                        {
                          repoDecision === 'new' &&
                          (
                            <div className='cicd-input'>
                              <SCInputText id="cicd-solution-name" refName={appNameRef} handleInputValueBlur={handleBlur} required={true} size="small" className="md-input" label="Solution Name" value={appNameValue}
                                handleInputValueChange={(e) => handleAppNameChange(e)}

                              />
                              {
                                regexError && repoDecision === 'new' &&
                                (
                                  <FormHelperText className='cicd-field-error'>Solution name must consist of Capitalized Case characters and dot only</FormHelperText>
                                )

                              }



                            </div>
                          )
                        }


                      </div>
                      <div className="auto-cicd-tab-one-line2second">
                        {
                          repoDecision !== 'new' &&
                          (//taltal
                            <React.Fragment>
                              <div className='cicd-title'>Solution</div>
                              <SCDropDownList id="cicd-solution" className="md-ddl-edit" label="Solution *" componentState={selectedSolution}
                                helperText="Select a solution to connect your new project to a new ci/cd process based on the solution dockerfile"
                                setComponentState={setSelectedSolution}
                                size="1000" items={ddlSolutions}
                                handleChange={(e) => handleChangeSolution(e)} value={selectedSolution}
                                handleOpen={(e) => handleOpenSolutions(e)}
                              />
                            </React.Fragment>
                          )
                        }
                        {
                          cloudDecision === 'azure' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Pipelines Paths</div>
                              <div className='cicd-build-release'>

                                <span onClick={() => openPipelineTreeModal('build')} className='cicd-pipeline-tree-title' id='cicd-build-folders'>Build</span>
                                {
                                  selectedBuildFolder === "" && !toggleExcludeBuildValue ?
                                    (
                                      <span className='cicd-pipeline-tree-value-not-selected'>Pipeline build folder was not selected</span>
                                    ) :
                                    (
                                      selectedBuildFolder !== "" && !toggleExcludeBuildValue ?
                                        (
                                          <span className='cicd-pipeline-tree-value'>{selectedBuildFolder}</span>
                                        ) :
                                        selectedBuildFolder !== "" && toggleExcludeBuildValue && serviceNameValue !== '' ?
                                          (
                                            <span className='cicd-pipeline-tree-value'>{selectedBuildFolder} - New folder: {serviceNameValue}</span>
                                          ) :
                                          (
                                            toggleExcludeBuildValue && serviceNameValue === '' && selectedBuildFolder === "" ?
                                              (
                                                <span className='cicd-pipeline-tree-value-empty'>Enter Service Name</span>) :
                                              (
                                                <span className='cicd-pipeline-tree-value'>New folder: {serviceNameValue}</span>
                                              )
                                          )




                                    )

                                }
                              </div>
                              <div className='cicd-build-release'>
                                <span onClick={() => openPipelineTreeModal('release')} className='cicd-pipeline-tree-title' id='cicd-release-folders'>Release</span>
                                {
                                  selectedReleaseFolder === "" && !toggleExcludeReleaseValue ?
                                    (
                                      <span className='cicd-pipeline-tree-value-not-selected'>Pipeline release folder was not selected</span>
                                    ) :
                                    (

                                      selectedReleaseFolder !== "" && !toggleExcludeReleaseValue ?
                                        (
                                          <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder}</span>
                                        ) :
                                        selectedReleaseFolder !== "" && toggleExcludeReleaseValue && serviceNameValue !== '' ?
                                          (
                                            <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder} - New folder: {serviceNameValue}</span>
                                          ) :
                                          (
                                            toggleExcludeReleaseValue && serviceNameValue === '' && selectedReleaseFolder === "" ?
                                              (
                                                <span className='cicd-pipeline-tree-value-empty'>Enter Service Name</span>) :
                                              (
                                                <span className='cicd-pipeline-tree-value'>New folder: {serviceNameValue}</span>
                                              )
                                          )

                                    )

                                }
                              </div>
                            </React.Fragment>
                          )
                        }

                        {
                          repoDecision === 'new' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Cluster</div>
                              <div className='cicd-selected-acr-container'>
                                <SCDropDownList id="cicd-selected-acr-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit"
                                  multiple={true} label="Dev cluster*" componentState={selectedACRDev}
                                  setComponentState={setSelectedACRDev}
                                  size="1000" items={ddlAcrDev} handleChange={(e) => handleChangeACRDev(e)} value={selectedACRDev} />

                                <SCDropDownList id="cicd-branches-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Branches *" componentState={selectedBranchesDev}
                                  setComponentState={setSelectedBranchesDev}
                                  size="1000" items={ddlBranchesDev}
                                  handleOpen={(e) => handleOpenDev(e)}
                                  handleChange={(e) => handleChangeBranchesDev(e)} value={selectedBranchesDev} />


                                <SCDropDownList id="cicd-selected-acr-prod" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit" multiple={true} label="Prod Cluster" componentState={selectedACRProd}
                                  setComponentState={setSelectedACRProd}
                                  size="1000" items={ddlAcrProd} handleChange={(e) => handleChangeACRProd(e)} value={selectedACRProd} />

                                <SCDropDownList id="cicd-branches-prod" className="md-ddl-edit" label="Branches" componentState={selectedBranchesProd}
                                  setComponentState={setSelectedBranchesProd} handleOpen={(e) => handleOpenProd(e)}
                                  size="1000" items={ddlBranchesProd} handleChange={(e) => handleChangeBranchesProd(e)} value={selectedBranchesProd} />
                              </div>
                            </React.Fragment>
                          )
                        }




                        {
                          cloudDecision === 'azure' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Create Database</div>
                              <SCDropDownList id="cicd-selected-db-name" className="md-ddl-edit" label="Database Group" componentState={selectedDBGroup}
                                setComponentState={setSelectedDBGroup}
                                size="1000" items={ddlDBGroup} handleChange={(e) => handleChangeDBGroup(e)} value={selectedDBGroup} />
                              <div className='cicd-input'>
                                <SCInputText id="cicd-db-name" text="DB Name shouldn't contain the environment suffix e.g. DBName not DBName_Dev" size="small" handleInputValueBlur={handleDBNameBlur} className="md-input" label="Database Name" value={dbNameValue}
                                  handleInputValueChange={(e) => handleDBNameChange(e)} />

                                {
                                  regexDBNameError &&
                                  (
                                    <FormHelperText className='cicd-field-error'>DB name could be with upper/lower case letters and numbers</FormHelperText>
                                  )

                                }

                              </div>
                            </React.Fragment>
                          )
                        }

                        {
                          selectedFramework === '.Net8' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>App Configuration</div>
                              <SCDropDownList id="cicd-selected-app-configuration" className="md-ddl-edit" label="App Configuration" componentState={selectedAppConfig}
                                setComponentState={setSelectedAppConfig}
                                size="1000" items={ddlAppConfig} handleChange={(e) => handleChangeAppDdlConfig(e)} value={selectedAppConfig} />
                              <div className='cicd-input'>
                                <SCInputText disabled={!selectedAppConfig ? true : false} id="cicd-selected-app-configuration-url" text="Example: https://app-config-prod-infra.azconfig.io" size="small"
                                  className="md-input" label="App Config Url" value={selectedAppConfigUrl}
                                  handleInputValueChange={(e) => handleAppConfigChange(e)} />

                              </div>
                              <div className='cicd-title'>Add Mongo Or Entity Framework</div>
                              <FormControl>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={selectedEFAndMongo}
                                  onChange={(e) => handleRadioChange(e)}
                                  id="self-service-radio-group"
                                >
                                  <FormControlLabel id="self-service-radio-label-none" value="None" control={<Radio />} label="None" />
                                  <FormControlLabel id="self-service-radio-label-mongo" value="Mongo" control={<Radio />} label="Add Mongo" />
                                  <FormControlLabel id="self-service-radio-label-ef" value="EF" control={<Radio />} label="Add Entity Framework" />


                                </RadioGroup>
                              </FormControl>

                            </React.Fragment>
                          )
                        }
                        {
                          (decision === 'eks' || decision === 'both') && repoDecision === 'new' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Should EKS use Azure KeyVault?
                                {
                                  keyVaultPayloadData && keyVaultPayloadData.length > 0 &&
                                  (
                                    <span className='cicd-kv-valid'><CheckCircleOutlineIcon /></span>
                                  )
                                }  </div>
                              <SCDropDownList id="cicd-selected-azure-kv-name" className="md-ddl-edit" label="Using Azure KeyVault" componentState={selectedEKSAndKV}
                                setComponentState={setSelectedEKSAndKV}
                                size="1000" items={ddlYesNo} handleChange={(e) => handleChangeAzureKV(e, false)} value={selectedEKSAndKV} />
                              {
                                keyVaultPayloadData && keyVaultPayloadData.length > 0 &&
                                (
                                  <span className='cicd-kv-valid-edit' onClick={() => handleChangeAzureKV(selectedEKSAndKV, true)} >Edit KeyVault Values</span>
                                )
                              }


                            </React.Fragment>
                          )
                        }

                        <div className='cicd-title'>Approval Manager</div>
                        <SCDropDownList id="cicd-approval-manager" className="md-ddl-edit" label="Select Approval Manager" componentState={selectedManager} setComponentState={setSelectedManager}
                          size="1000" items={ddlManager} handleChange={(e) => handleChangeManager(e)} value={selectedManager} />


                        <div className='cicd-button'>

                          <DPOrangeButton id="selfServiceCreateButton" size="medium" text="Create Request" handleButtonClick={handleCreateRequest} />

                        </div></div>
                    </div>




                  </TabPanel>
                ) :
                (
                  <TabPanel value="0" className='auto-cicd-flex-container'>
                    <div className="auto-cicd-tab-one-container">
                      <div className="auto-cicd-tab-one-first">

                        {
                          errorList.length > 0 &&
                          (


                            <div className="cicd-error-container">
                              {errorList.map((error, index) => (
                                <React.Fragment key={index}>
                                  <div className="cicd-error-cell">{error}</div>
                                </React.Fragment>
                              ))}
                            </div>

                          )
                        }
                      </div>
                      <div className="auto-cicd-tab-one-line2first">
                        <div className='cicd-title'>Service Name</div>
                        <div className='cicd-input'>
                          <SCInputText id="cicd-service-name" refName={serviceNameRef} handleInputValueBlur={handleServiceNameBlur} required={true} size="small" className="md-input" label="Service Name" value={serviceNameValue}
                            handleInputValueChange={(e) => handleServiceNameChange(e)} />

                          {
                            regexServiceError &&
                            (
                              <FormHelperText className='cicd-field-error'>Service name could be with upper/lower case letters and numbers. Words may be separated with dashes (-)</FormHelperText>
                            )

                          }

                        </div>

                        <div className='cicd-title'>Migration Service Data</div>
                        <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Projects *" componentState={selectedProject} setComponentState={setSelectedProject}
                          size="1000" items={ddlProjects} handleChange={(e) => handleChangeProject(e)} value={selectedProject} />


                        <div>
                          <Autocomplete
                            freeSolo
                            id="search-repos"
                            disableClearable
                            options={options}
                            onChange={(e, value) => handleUserClick(e, value)}

                            getOptionLabel={getOptionLabel}
                            onInputChange={(event, newInputValue) => {
                              handleDelete(event, newInputValue);
                            }}
                            filterOptions={(options) => options}
                            renderInput={(params) => (
                              <TextField
                                className='cicd-repos'
                                {...params}
                                label="Existing Repositories"
                                InputProps={{
                                  ...params.InputProps,
                                  type: "search",

                                }}

                                InputLabelProps={{ shrink: true }}
                                onChange={(event) =>
                                  handleAutoChange(event.target.value)
                                }



                              />
                            )}


                          />
                          {
                            existingRepoNameInValid &&
                            (
                              <FormHelperText className='cicd-field-error'>A repository doesn't exist. Please choose from the list</FormHelperText>
                            )
                          }
                        </div>
                        {
                          cloudDecision === 'azure' &&
                          (
                            <SCDropDownList id="cicd-selected-project" className="md-ddl-edit" label="Feed Name *" componentState={selectedFeed} setComponentState={setSelectedFeed}
                              size="1000" items={ddlFeeds} handleChange={(e) => handleChangeFeed(e)} value={selectedFeed} />
                          )
                        }

                        <SCDropDownList id="cicd-selected-acr-dev" helperText="*Multichoice field (use Esc to keep the changes)" className="md-ddl-edit"
                          multiple={true} label="Dev cluster*" componentState={selectedACRDev}
                          setComponentState={setSelectedACRDev}
                          size="1000" items={ddlAcrDev} handleChange={(e) => handleChangeACRDev(e)} value={selectedACRDev} />

                        <SCDropDownList id="cicd-branches-dev" className="md-ddl-edit" label="Branch *" componentState={selectedBranchesDev}
                          setComponentState={setSelectedBranchesDev}
                          size="1000" items={ddlBranchesDev}
                          handleOpen={(e) => handleOpenDev(e)}
                          handleChange={(e) => handleChangeBranchesDev(e)} value={selectedBranchesDev} />

                        <Tooltip
                          title={
                            <img
                              src={migrationInstructionImage} // Replace with your image URL
                              alt="Tooltip Image"
                              style={{ width: '700px', height: '500px', backgroundColor: 'white', border: '3px solid gray' }} // Adjust size as needed

                            />
                          }
                          placement="top"
                          PopperProps={{
                            sx: {
                              '.MuiTooltip-tooltip': {
                                bgcolor: 'transparent', // Remove background color
                                border: 'none', // Optional: remove border
                              },
                              '.MuiTooltip-arrow': {
                                bgcolor: 'transparent', // Remove arrow background if needed
                              },
                            },
                          }}
                        >
                          <FormHelperText>
                            <InfoIcon style={{ fontSize: '20px', marginRight: '3px' }} />
                            Depending on this branch, we'll create a new branch automatically, the new CI will be triggered by the new branch, once you complete the pull-request in the dev, you should change the default branch in your CI</FormHelperText>

                        </Tooltip>

                        <SCDropDownList id="cicd-solution" className="md-ddl-edit" label="Solution *" componentState={selectedSolution}
                          setComponentState={setSelectedSolution}
                          size="1000" items={ddlSolutions}
                          handleChange={(e) => handleChangeSolution(e)} value={selectedSolution} />
                        <SCDropDownList id="cicd-branches-dev" className="md-ddl-edit" label="Solution csproj files" componentState={selectedSolutionCsproj}
                          setComponentState={setSelectedSolutionCsproj}
                          size="1000" items={ddlSolutionCsproj} helperText="Only project in type WebApi could be migrated (currently)"
                          handleChange={(e) => handleChangeSolutionCsproj(e)} value={selectedSolutionCsproj} />



                      </div>
                      <div className="auto-cicd-tab-one-line2second">
                        {
                          repoDecision !== 'new' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Solution (for monorepo scenario only)</div>
                              <SCDropDownList id="cicd-branches-dev" className="md-ddl-edit" label="Solution" componentState={selectedSolution}
                                helperText="Select a solution to connect your new project to a new ci/cd process based on the solution dockerfile"
                                setComponentState={setSelectedSolution}
                                size="1000" items={ddlSolutions}
                                handleChange={(e) => handleChangeSolution(e)} value={selectedSolution}
                                handleOpen={(e) => handleOpenSolutions(e)}
                              />
                            </React.Fragment>
                          )
                        }
                        {
                          cloudDecision === 'azure' &&
                          (
                            <React.Fragment>
                              <div className='cicd-title'>Pipelines Paths</div>
                              <div className='cicd-build-release'>

                                <span onClick={() => openPipelineTreeModal('build')} className='cicd-pipeline-tree-title' id='cicd-build-folders'>Build</span>
                                {
                                  selectedBuildFolder === "" && !toggleExcludeBuildValue ?
                                    (
                                      <span className='cicd-pipeline-tree-value-not-selected'>Pipeline build folder was not selected</span>
                                    ) :
                                    (
                                      selectedBuildFolder !== "" && !toggleExcludeBuildValue ?
                                        (
                                          <span className='cicd-pipeline-tree-value'>{selectedBuildFolder}</span>
                                        ) :
                                        selectedBuildFolder !== "" && toggleExcludeBuildValue && serviceNameValue !== '' ?
                                          (
                                            <span className='cicd-pipeline-tree-value'>{selectedBuildFolder} - New folder: {serviceNameValue}</span>
                                          ) :
                                          (
                                            toggleExcludeBuildValue && serviceNameValue === '' && selectedBuildFolder === "" ?
                                              (
                                                <span className='cicd-pipeline-tree-value-empty'>Enter Service Name</span>) :
                                              (
                                                <span className='cicd-pipeline-tree-value'>New folder: {serviceNameValue}</span>
                                              )
                                          )




                                    )

                                }
                              </div>
                              <div className='cicd-build-release'>
                                <span onClick={() => openPipelineTreeModal('release')} className='cicd-pipeline-tree-title' id='cicd-release-folders'>Release</span>
                                {
                                  selectedReleaseFolder === "" && !toggleExcludeReleaseValue ?
                                    (
                                      <span className='cicd-pipeline-tree-value-not-selected'>Pipeline release folder was not selected</span>
                                    ) :
                                    (

                                      selectedReleaseFolder !== "" && !toggleExcludeReleaseValue ?
                                        (
                                          <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder}</span>
                                        ) :
                                        selectedReleaseFolder !== "" && toggleExcludeReleaseValue && serviceNameValue !== '' ?
                                          (
                                            <span className='cicd-pipeline-tree-value'>{selectedReleaseFolder} - New folder: {serviceNameValue}</span>
                                          ) :
                                          (
                                            toggleExcludeReleaseValue && serviceNameValue === '' && selectedReleaseFolder === "" ?
                                              (
                                                <span className='cicd-pipeline-tree-value-empty'>Enter Service Name</span>) :
                                              (
                                                <span className='cicd-pipeline-tree-value'>New folder: {serviceNameValue}</span>
                                              )
                                          )

                                    )

                                }
                              </div>
                            </React.Fragment>
                          )
                        }

                        <div className='cicd-title'> Deploy To Dev Cluster</div>
                        <SCDropDownList id="cicd-approval-manager" className="md-ddl-edit" label="Deploy" componentState={deployToDevCluster} setComponentState={setDeployToDevCluster}
                          size="1000" items={ddlDeploy} handleChange={(e) => handleChangeDeploy(e)} value={deployToDevCluster} />

                        <div className='cicd-title'>Approval Manager</div>
                        <SCDropDownList id="cicd-approval-manager" className="md-ddl-edit" label="Select Approval Manager" componentState={selectedManager} setComponentState={setSelectedManager}
                          size="1000" items={ddlManager} handleChange={(e) => handleChangeManager(e)} value={selectedManager} />
                        <div className='cicd-button'>
                          <SCButton buttonId="selfServiceCreateMigrationButton" size="medium" text="Create Request" handleButtonClick={handleCreateMigrationRequest} />
                        </div>

                        <div className='auto-cicd-comments-container'>

                          <div className='auto-cicd-comments'>
                            * If dockerignore file is existing in your project, please remove it before the migration
                          </div>
                          <div className='auto-cicd-comments'>
                            * Only project in type WebApi could be migrated (currently)
                          </div>
                          <div className='auto-cicd-comments'>
                            * CI/CD process is not running automatically, you need to do it first manually
                          </div>


                        </div>
                      </div>
                    </div>
                  </TabPanel>
                )
            }


            <TabPanel value="1">

              <div>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="self-service-view-req-th">App Name</TableCell>
                        <TableCell className="self-service-view-req-th">Repo Name</TableCell>
                        <TableCell className="self-service-view-req-th">Build Pipeline Url</TableCell>
                        <TableCell className="self-service-view-req-th">Release Pipeline Url</TableCell>
                        <TableCell className="self-service-view-req-th">DevOps Project	</TableCell>
                        <TableCell className="self-service-view-req-th">Manager</TableCell>
                        <TableCell className="self-service-view-req-th">Approval Status	</TableCell>
                        <TableCell className="self-service-view-req-th">Ticket Number	</TableCell>
                        <TableCell className="self-service-view-req-th">Created On	</TableCell>
                        <TableCell className="self-service-view-req-th">Process Status</TableCell>
                        <TableCell className="self-service-view-req-th">DB Creation Status	</TableCell>
                        <TableCell className="self-service-view-req-th">DB KeyVault Key	</TableCell>
                        <TableCell className="self-service-view-req-th">DB KeyVault Name	</TableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        requestCICDItems?.data?.map((item, index) => (
                          <TableRow key={`self-service-vr-row--${index}`} id={`self-service-vr-row--${index}`}>
                            <TableCell key={`self-service-vr-cell--${index}`} id="self-service-vr-appName">{item.appName}</TableCell>
                            {
                              item.repoUrl && item.repoUrl !== undefined ?
                                (
                                  <TableCell id="self-service-vr-repoName">
                                    {item.repoUrl}
                                  </TableCell>
                                ) :
                                (
                                  <TableCell id="self-service-vr-repoName">{item.repoName}</TableCell>
                                )
                            }

                            {
                              item.buildPipelineUrl && item.buildPipelineUrl !== undefined ?
                                (
                                  <TableCell id="self-service-vr-build">
                                    {item.buildPipelineUrl}
                                  </TableCell>
                                ) :
                                (
                                  <TableCell id="self-service-vr-build">-</TableCell>
                                )
                            }

                            {
                              item.releasePipelineUrl && item.releasePipelineUrl !== undefined ?
                                (
                                  <TableCell id="self-service-vr-release">
                                    {item.releasePipelineUrl}
                                  </TableCell>
                                ) :
                                (
                                  <TableCell id="self-service-vr-release">-</TableCell>
                                )
                            }

                            <TableCell id="self-service-vr-azureDevOpsProject">{item.azureDevOpsProject}</TableCell>
                            <TableCell id="self-service-vr-approvalManagerEmail">{item.approvalManagerEmail}</TableCell>
                            <TableCell id="self-service-vr-approvalStatus">{item.approvalStatus}</TableCell>
                            <TableCell id="self-service-vr-approvalTicketNumber">
                              <RenderJiraLink ticket={item.approvalTicketNumber} />
                            </TableCell>
                            <TableCell id="self-service-vr-createdOn">{item.createdOn}</TableCell>
                            {
                              item.processStatus === 'failed' ?
                                (
                                  <Tooltip title={item.failureReason} arrow>
                                    <TableCell id="self-service-vr-processStatus">   <InfoIcon style={{ fontSize: '19px', marginRight: '3px', color: 'red' }} />
                                      <span className='self-service-view-req-failed'>{item.processStatus}</span></TableCell>
                                  </Tooltip>

                                ) :
                                (
                                  <TableCell id="self-service-vr-processStatus">{item.processStatus}</TableCell>
                                )
                            }
                            <TableCell id="self-service-vr-dataBaseCreationStatus">{item.dataBaseCreationStatus}</TableCell>
                            <TableCell id="self-service-vr-dataBaseKeyVaultKey">{item.dataBaseKeyVaultKey}</TableCell>
                            <TableCell id="self-service-vr-dataBaseKeyVaultName">{item.dataBaseKeyVaultName}</TableCell>


                          </TableRow>
                        ))
                      }

                    </TableBody>
                  </Table>
                </TableContainer>

                {/*
                  <SCTable customColumnWidth="true" title="Self-Service Requests" columnsItems={columnsItems}
                  rowItems={requestCICDItems.data}
                  toolTip={{
                    column: 'processStatus', text: '',
                    filter: { key: 'processStatus', operator: 'eq', value: 'failed', toolTipTextFromColumn: 'failureReason' }
                  }}
                />
                */}

              </div>

            </TabPanel>

          </TabContext>
        </Box>


      </div>




      <AlertModal text={text} type={type} />

      <PipelineTreeModal show={showBuildPipelineTree} title="Build Pipeline Folder Tree"
        setSelectedValue={setSelectedBuildFolder} selectedValue={selectedBuildFolder}
        appName={serviceNameValue}
        folderName={folderName}
        toggleExcludeValue={toggleExcludeBuildValue} setToggleExcludeValue={setToggleExcludeBuildValue}
        setShowPipelineTree={setShowBuildPipelineTree} projectName={selectedProject} />

      <PipelineTreeModal show={showReleasePipelineTree} title="Release Pipeline Folder Tree"
        setSelectedValue={setSelectedReleaseFolder} selectedValue={selectedReleaseFolder}
        appName={serviceNameValue}
        folderName={folderName}
        toggleExcludeValue={toggleExcludeReleaseValue} setToggleExcludeValue={setToggleExcludeReleaseValue}
        setShowPipelineTree={setShowReleasePipelineTree} projectName={selectedProject} />


      <AksOrEksModal setModalShow={setModalAKSEKSShow}
        setRepoDecision={setRepoDecision} repoDecision={repoDecision}
        setMigrationType={setMigrationType} migrationType={migrationType}
        modalShow={modalAKSEKSShow} decision={decision} setDecision={setDecision}
        setCloudDecision={setCloudDecision} cloudDecision={cloudDecision}
        typeDecision={typeDecision} setTypeDecision={setTypeDecision} />

      <SelfServiceKVModal setModalShow={setModalKVShow} setKeyVaultPayloadData={setKeyVaultPayloadData} keyVaultPayloadData={keyVaultPayloadData}
        modalShow={modalKVShow} acrDev={selectedACRDev} branchesDev={selectedBranchesDev} acrProd={selectedACRProd}
        openByEdit={openByEdit}
        setSelectedEKSAndKV={setSelectedEKSAndKV}
        branchesProd={selectedBranchesProd} />

      <SelfServiceAIGenerationModal setModalShow={setModalAIMessageShow} modalShow={modalAIMessageShow} />

    </div>
  )
}

export default AutoCICD