import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import './fonts/Urbanist-Regular.ttf';
import { DataProviderChat } from './Context/DataContextChat';
import './variables.css'
import './mui-styles.css'



ReactDOM.render(
  <DataProviderChat>
  <App />
  </DataProviderChat>,
  document.getElementById('root')
);


