import Avatar from '../../Images/newIcons/chatButton.svg'
import React, { useContext, useEffect } from 'react';
import './ChatBotButton.css';
import { DataContextChat } from '../../Context/DataContextChat';

const ChatBotButton = ({ onClick }) => {


    const { toggleChat } = useContext(DataContextChat);

 

    return (

        <React.Fragment>
            {
                process.env.REACT_APP_DISPLAY_COPILOT === "true" &&
                (
                    <div id="chat-float-button"  title="Copilot">
                        <img className='infra-copilot-avatar-btn' src={Avatar} alt="avatar" onClick={toggleChat} />
                    </div>
                )
            }
        </React.Fragment>



    );
};

export default React.memo(ChatBotButton);