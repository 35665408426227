
import React, { useEffect, useState, useContext } from "react";
import './DPCheckbox.css';
import Radio_Checked from '../Images/newIcons/Radio_Checked.svg';
import Radio_UnChecked from '../Images/newIcons/Radio_Unchecked.svg';

const DPCheckbox = ({ text, onClick, customClass = "", id = "", disable = false, checked = false }) => {


    return (
        <React.Fragment>
            {
                checked ?
                    (
                        <div id={id} className={customClass + " dp-check-radio"} onClick={onClick}>
                            <span><img src={Radio_Checked} alt='icon' className='dp-radio-icon' /></span>
                            <span className='dp-check-radio-text' >{text}</span>
                        </div>
                    ) :
                    (
                        <div id={id} className={customClass + " dp-check-radio"} onClick={onClick}>
                              <img src={Radio_UnChecked} alt='icon'  className='dp-radio-icon' />
                            <span className='dp-check-radio-text'  >{text}</span>
                        </div>
                    )
            }
        </React.Fragment>


    )
}

export default DPCheckbox
