
import React, { useEffect, useState, useContext, useRef } from 'react'
import './SAAnalyzerReportModal.css';
import _ from "lodash";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import * as consts from '../../../consts';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import SAAnalyzerSummary from './SAAnalyzerSummary';
import SAAnalyzerRootCause from './SAAnalyzerRootCause';
import SAMeasurements from './SAMeasurements';
import SADump from './SADump';
import SAAnomalyChart from './anomaly/SAAnomalyChart';
import SAAnalyzerIncidentSummary from './SAAnalyzerIncidentSummary';
import SCFloatingButton from '../../SCFloatingButton';
import SAAnalyzerComments from './SAAnalyzerComments';
import SARenderReportDataByCode from './SARenderReportDataByCode';
import SARenderLogs from './SARenderLogs';
import DataContext from '../../../Context/DataContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
const { DateTime } = require('luxon');



const SAAnalyzerReportModal = ({ services, show, setShowAnalyzerReport, service, progressBar, requestId, valueFrom, valueTo,
    selectedServiceId, summary, fromHistory = false, withPredict = false, manageStatus = false }) => {

    const { doForceInit, servicesCached } = useContext(DataContext);

    const { LuxonDateFormatString, SubstringStringForToolTip, predict, handleCopyToClipboard, anomalyPredict,
        setAnomalyPredict, modules, setModules, getMetrics } = useContext(DataContextAnalyzer);

    const [value, setValue] = React.useState("0");

    const targetRef = useRef(null);

    const [ready, setReady] = useState(false)

    const [reportAnomaly, setReportAnomaly] = useState([])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const location = useLocation();


    useEffect(async () => {



        if (!service || !show) {
            return;
        }

        if (show) {
            setReady(false)
            setValue("0")
        }

        console.log("service",service)
  
        let cloneService = { ...service }

        const data = _.groupBy(cloneService.report, item => item.module);

        let types = ['Summary', 'RootCause', 'Configuration', 'Database', 'Deployments', 'Diagnostics', 'Logs', 'Metrics', 'ResourceChanges', 'Measurements', 'Dump']
        let dataDictionary = {};

        types.map((t) => {
            if ((t in data) && t !== 'RootCause') {

                //filter out
                switch (t.toLowerCase()) {
                    case "logs":
                        {
                            let logsElements = data[t].filter(e =>  e.code === 'error_count');
                            dataDictionary[t] = logsElements;
                            break;
                        }


                    default:
                        {
                            dataDictionary[t] = data[t];
                            break;
                        }

                }


            }
            else if (t === 'RootCause') {


                if (cloneService && Object.entries(cloneService).length > 0 && cloneService.incident !== null && (cloneService.incident?.risk?.toLowerCase() === 'high' || cloneService.incident?.risk?.toLowerCase() === 'Medium') && service.rootCause !== null) {
                    dataDictionary[t] = { rootCause: cloneService.rootCause, insights: cloneService.report }
                }
                else {
                    dataDictionary[t] = [];
                }
            }
            else {
                dataDictionary[t] = [];
            }


        })



        if (dataDictionary['ResourceChanges'] && dataDictionary['ResourceChanges'].length > 0) {
            dataDictionary['ResourceChanges'] = dataDictionary['ResourceChanges'].filter(x => x.code.toLowerCase() !== consts.INFRA_CHANGES && x.code.toLowerCase() !== consts.HEALTH_CHANGES);
        }

        setModules(dataDictionary)



        if (withPredict) {

            if (service?.incident?.anomalies?.metricsToValues) {
                setReportAnomaly(service?.incident?.anomalies?.metricsToValues)


                var fromDateCPU = null;
                var toDateCPU = null;

                var fromDateMemory = null;
                var toDateMemory = null;

                var fromDateResponseTime = null;
                var toDateResponseTime = null;

                var fromDateLogsCount = null;
                var toDateLogsCount = null;
                _.forOwn(service?.incident?.anomalies?.metricsToValues, (value, key) => {

                    var dt = DateTime.fromISO(value[0].timestamp);


                    switch (key) {
                        case "CPU":
                            {
                                fromDateCPU = dt.plus({ hours: -12 }).toUTC();
                                //toDateCPU = dt.plus({ hours: 12 }).toUTC();
                                toDateCPU = dt.plus({ hours: 1 }).toUTC();
                                //toDateCPU = dt.toUTC();
                            }
                            break;
                        case "Memory":
                            {

                                fromDateMemory = dt.plus({ hours: -12 }).toUTC();
                                //toDateMemory = dt.plus({ hours: 12 }).toUTC();
                                toDateMemory = dt.plus({ hours: 1 }).toUTC();
                                //toDateMemory = dt.toUTC();
                            }
                            break;
                        case "ResponseTime":
                            {

                                fromDateResponseTime = dt.plus({ hours: -12 }).toUTC();
                                //toDateResponseTime = dt.plus({ hours: 12 }).toUTC();
                                toDateResponseTime = dt.plus({ hours: 1 }).toUTC();
                                //toDateResponseTime = dt.toUTC();
                            }
                            break;
                        case "LogsCount":
                            {

                                fromDateLogsCount = dt.plus({ hours: -12 }).toUTC();
                                //toDateLogsCount = dt.plus({ hours: 12 }).toUTC();
                                toDateLogsCount = dt.plus({ hours: 1 }).toUTC();
                                //toDateLogsCount = dt.toUTC();
                            }
                            break;

                        default:
                            break;
                    }


                })

                var dt = DateTime.fromISO(Object.entries(service?.incident?.anomalies?.metricsToValues)[0][1][0].timestamp);
                if (fromDateCPU === null) {
                    fromDateCPU = dt.plus({ hours: -12 }).toUTC();
                    toDateCPU = dt.plus({ hours: 12 }).toUTC();

                }

                if (fromDateMemory === null) {
                    fromDateMemory = dt.plus({ hours: -12 }).toUTC();
                    toDateMemory = dt.plus({ hours: 12 }).toUTC();
                }

                if (fromDateResponseTime === null) {
                    fromDateResponseTime = dt.plus({ hours: -12 }).toUTC();
                    toDateResponseTime = dt.plus({ hours: 12 }).toUTC();
                }

                if (fromDateLogsCount === null) {
                    fromDateLogsCount = dt.plus({ hours: -12 }).toUTC();
                    toDateLogsCount = dt.plus({ hours: 12 }).toUTC();
                }



                let count = 0
                let response = await Promise.allSettled([
                    getMetrics(service.serviceId, fromDateCPU.toISO(), toDateCPU.toISO(), 60, "CPU"),
                    getMetrics(service.serviceId, fromDateMemory.toISO(), toDateMemory.toISO(), 60, "Memory"),
                    getMetrics(service.serviceId, fromDateLogsCount.toISO(), toDateLogsCount.toISO(), 60, "LogsCount"),
                    getMetrics(service.serviceId, fromDateResponseTime.toISO(), toDateResponseTime.toISO(), 60, "ResponseTime")

                ])
                if (response[0].status === "fulfilled") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        cpu: { metrics: response[0], anomaly: service?.incident?.anomalies?.metricsToValues['CPU'] }

                    }));
                    count++;
                }
                else if (response[0].status === "rejected") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        cpu: null

                    }));
                    count++;
                }

                if (response[1].status === "fulfilled") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        memory: { metrics: response[1], anomaly: service?.incident?.anomalies?.metricsToValues['Memory'] }

                    }));
                    count++;

                }
                else if (response[1].status === "rejected") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        memory: null

                    }));
                    count++;
                }

                if (response[2].status === "fulfilled") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        logsCount: { metrics: response[2], anomaly: service?.incident?.anomalies?.metricsToValues['LogsCount'] }
                    }));
                    count++;

                }
                else if (response[2].status === "rejected") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        logsCount: null

                    }));
                    count++;
                }

                if (response[3].status === "fulfilled") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        responseTime: { metrics: response[3], anomaly: service?.incident?.anomalies?.metricsToValues['ResponseTime'] }

                    }));
                    count++;
                }
                else if (response[3].status === "rejected") {
                    setAnomalyPredict(prevState => ({
                        ...prevState,
                        responseTime: null,
                        anomaly: null
                    }));
                    count++;
                }

                if (count === 4) {
                    setReady(true)
                }

            }
        }

    }, [service, show])






    const analyzerReportModalClose = () => {

        localStorage.removeItem('selectedCountersValue'); // related to counters state
        setShowAnalyzerReport(false)
        setRerender(0)


        const { search } = location;
        const urlParams = new URLSearchParams(search);
        const short = urlParams.get('short');

        if (servicesCached === '' && short === 'slack') {
            doForceInit()
        }



    }

    const RenderInsightsTable = ({ name, modules }) => {




        let elements = modules[name];

        if (name.toLowerCase() === "diagnostics") {
            return (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Code</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>OccurredAt</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {

                                elements.map((value, innerIndex) => (
                                    <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                        <TableCell >{value.code}</TableCell>
                                        <TableCell>
                                            <SARenderReportDataByCode code={value.code} value={value} />
                                        </TableCell>
                                        <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                    </TableRow>
                                ))

                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    

        if (name.toLowerCase() === 'metrics' || name.toLowerCase() === 'configuration' || name.toLowerCase() === 'database' || name.toLowerCase() === 'deployments') {

            const groupedData = _.groupBy(elements, 'code');
            return (
                Object.entries(groupedData).map(([key, value]) => (

                    <SARenderReportDataByCode code={key} value={null} elements={value} />
                ))

            )

        }


        if (name.toLowerCase() === 'logs') {

            return (

                <SARenderLogs elements={elements} />

            )

        }

        return (
            elements.map((value, innerIndex) => (
                <TableContainer key={innerIndex} component={Paper}>
                    <Table>
                        <SARenderReportDataByCode code={value.code} value={value} elements={elements} />
                    </Table>
                </TableContainer>
            ))

        )
    }



    const DisplayTabs = () => {
        if (modules) {
            return (
                <div>
                    <Box sx={{ width: '100%', typography: 'body1' }} ref={targetRef}>
                        <TabContext value={value.toString()}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example"
                                    sx={{
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: '#F36805 !important', // Change this to your desired color
                                        },
                                        '& .MuiTab-root': {
                                            color: 'black !important', // Color for unselected tabs
                                        },
                                        '& .Mui-disabled': {
                                            color:'lightgray !important'
                                        }

                                    }}>
                                    {Object.entries(modules).map(([key, values], index) => (

                                        values.length > 0 || key === "Summary" || Object.entries(values).length > 0 ||
                                            (key === "Measurements" && service.type === "AksApp") || (key === "Dump" && service.type === "AksApp") ?
                                            (
                                                <Tab data-id={`sa-summary-report-table-tab-active-` + key} label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            ) :
                                            (
                                                <Tab data-id={`sa-summary-report-table-tab-inactive-` + key} disabled label={key} value={index.toString()} key={`modal--key-name-element` + index} />
                                            )



                                    ))}


                                </TabList>
                            </Box>
                            {
                                Object.entries(modules).map(([key, values], innerIndex) => (
                                    <TabPanel value={innerIndex.toString()} key={`panel-element` + innerIndex}>
                                        {
                                            key === "Summary" ?
                                                (
                                                    <SAAnalyzerSummary targetRef={targetRef} fromHistory={fromHistory} services={services} progressBar={progressBar} data={modules} service={service} setValue={setValue} />
                                                ) : key === "RootCause" ?
                                                    (
                                                        <SAAnalyzerRootCause services={services} values={values} />
                                                    ) : key === "Measurements" ?
                                                        (
                                                            <SAMeasurements service={service} requestId={requestId} />
                                                        ) : key === "Dump" ?
                                                            (
                                                                <SADump service={service} requestId={requestId} />
                                                            ) :
                                                            (

                                                                <RenderInsightsTable name={key} modules={modules} />

                                                            )
                                        }
                                        {
                                            key === "Deployments" ?
                                                (
                                                    <span className='data-by-code-deployments-msg'>* Manual deployments are not fully supported due to some issues related to the pipeline data.</span>
                                                ) :
                                                (
                                                    <span></span>
                                                )
                                        }
                                    </TabPanel>
                                ))
                            }
                        </TabContext>
                    </Box>
                </div>
            )
        }
        return (
            <div>NO DATA</div>
        )
    }

    const [rerender, setRerender] = useState(0)
    const handleScroll = (event) => {
        if (event.currentTarget.scrollTop === 0 || (event.currentTarget.scrollTop > 0 && event.currentTarget.scrollTop < 10)) {
            setRerender(event.currentTarget.scrollTop)
        }
    };


    return (
        <React.Fragment>
            <Modal fullscreen={true}
                show={show}
                onHide={() => analyzerReportModalClose()}

                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Box
                    sx={{
                        overflow: 'scroll'
                    }}
                    onScroll={handleScroll}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Incident Report
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="notification-modal-container">


                        <div>
                            {
                                withPredict && service?.incident && Object.entries(service?.incident)?.length > 0 && (service?.incident?.risk === "High" || service?.incident?.risk === "Medium") ?
                                    (
                                        <div className="data-by-code-incident-summary">

                                            <SAAnalyzerIncidentSummary modules={modules} ready={ready} manageStatus={manageStatus} service={service} requestId={requestId} from={valueFrom} to={valueTo} />
                                        </div>
                                    ) :
                                    (
                                        <span></span>
                                    )
                            }

                            {
                                withPredict && service?.incident && Object.entries(service?.incident)?.length > 0 && (service?.incident?.risk === "High" || service?.incident?.risk === "Medium") ?
                                    (

                                        <div className='data-by-code-predict-container'>
                                            <div>
                                                <SCFloatingButton scroll={rerender} />
                                            </div>
                                            <div className="data-by-code-incident-container">



                                                <div className='data-by-code-incident-row'>

                                                    <div className="data-by-code-incident-square">
                                                        <SAAnomalyChart yLinkValue="ms" ready={ready} type="CPU" data={anomalyPredict.cpu} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                                                    </div>
                                                    <div className="data-by-code-incident-square">
                                                        <SAAnomalyChart yLinkValue="%" ready={ready} type="Memory" data={anomalyPredict.memory} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                                                    </div>

                                                </div>
                                                <div className='data-by-code-incident-row'>

                                                    <div className="data-by-code-incident-square">
                                                        <SAAnomalyChart yLinkValue="count" ready={ready} type="Logs Count" data={anomalyPredict.logsCount} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                                                    </div>
                                                    <div className="data-by-code-incident-square">
                                                        <SAAnomalyChart yLinkValue="ms" ready={ready} type="Response Time" data={anomalyPredict.responseTime} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    ) : (<span></span>)
                            }
                            <div className='data-by-code-incident-tabs'>
                                <DisplayTabs />
                            </div>
                            <div className='data-by-code-incident-comments'>
                                <SAAnalyzerComments service={service} requestId={requestId} />
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => analyzerReportModalClose()}>Close</Button>
                    </Modal.Footer>
                </Box>

            </Modal>


        </React.Fragment>
    )
}

export default SAAnalyzerReportModal









