import { createContext, useState, useContext } from 'react';
import DataContext from "./DataContext";
import _ from "lodash";



const DataContextHome = createContext({});

export const DataProviderHome = ({ children }) => {

  const [toggleExcludeValue, setToggleExcludeValue] = useState(false)
  const { services } = useContext(DataContext);

  const [chunkedServices, setChunkedServices] = useState([])
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false); // Loading indicator
  const [loadMore, setLoadMore] = useState(1)


  const calcServices = () => {

    let numberOfServices = 10 * loadMore;
    const smallerArrays = _.take(services, numberOfServices);
    setChunkedServices(smallerArrays)

    setIsLoadMoreLoading(false)
  }
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;




    if (isScrolledToBottom && !isLoadMoreLoading) {

      handleLoadMore()
    }
  }



  const handleLoadMore = () => {
    setIsLoadMoreLoading(true)

    if (chunkedServices.length < services.length) {
      setLoadMore((loadMore) => loadMore + 1);
    }
    else {
      setIsLoadMoreLoading(false)
    }

  }


  const generateUserText = (cronExpression) => {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');
  
    // Define mappings for each field
    const fieldMappings = {
      minute: {
        '*': 'every minute',
        '*/1': 'every 1 minutes',
        // Add more mappings as needed
      },
      hour: {
        '*': 'every hour',
        // Add more mappings as needed
      },
      dayOfMonth: {
        '*': 'every day',
        // Add more mappings as needed
      },
      month: {
        '*': 'every month',
        // Add more mappings as needed
      },
      dayOfWeek: {
        '*': 'every day of the week',
        // Add more mappings as needed
      },
    };
  
    // Generate user-friendly text for each field
    const userText = {
      minute: fieldMappings.minute[minute] || minute,
      hour: fieldMappings.hour[hour] || hour,
      dayOfMonth: fieldMappings.dayOfMonth[dayOfMonth] || dayOfMonth,
      month: fieldMappings.month[month] || month,
      dayOfWeek: fieldMappings.dayOfWeek[dayOfWeek] || dayOfWeek,
    };
  
    // Concatenate the field descriptions into a user-friendly text
    const text = `${userText.minute} ${userText.hour} ${userText.dayOfMonth} ${userText.month} ${userText.dayOfWeek}`;
    
    return text;
  };
  


  return (
    <DataContextHome.Provider value={{
      toggleExcludeValue, setToggleExcludeValue, loadMore, setLoadMore, chunkedServices, setChunkedServices,
      isLoadMoreLoading, setIsLoadMoreLoading, calcServices,handleScroll,generateUserText
    }}>



      {children}
    </DataContextHome.Provider>
  )

}

export default DataContextHome;
