// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
import Avatar from '../../Images/newIcons/chatButton.svg'
import './InfraAvatar.css';



const InfraAvatar = () => {

    return (
       
            <img className='infra-copilot-avatar' src={Avatar} alt="avatar" />
       
    );
};

export default InfraAvatar;