import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';

const SARenderReportDataByCode = ({ code, value, elements = null }) => {

    const { LuxonDateFormatString, SubstringStringForToolTip, handleCopyToClipboard } = useContext(DataContextAnalyzer);

    const RenderReportData = () => {
        console.log(code, elements)
        switch (code.toString().toLowerCase()) {

            case "cpu":
                {

                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Occurred At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((element, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{element.code}</TableCell>
                                                    <TableCell >{element.data.value}
                                                        {
                                                            element.data.unitOfMeasure === "Percentage" ?
                                                                (
                                                                    <span>%</span>
                                                                ) :
                                                                (
                                                                    <span>{element.data.unitOfMeasure}</span>
                                                                )

                                                        }
                                                    </TableCell>
                                                    <TableCell >{LuxonDateFormatString(element.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )
                }
            case "memory":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Occurred At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((element, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{element.code}</TableCell>
                                                    <TableCell >{element.data.value}
                                                        {
                                                            element.data.unitOfMeasure === "Percentage" ?
                                                                (
                                                                    <span>%</span>
                                                                ) :
                                                                (
                                                                    <span>{element.data.unitOfMeasure}</span>
                                                                )

                                                        }
                                                    </TableCell>
                                                    <TableCell >{LuxonDateFormatString(element.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "requests":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Occurred At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((element, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{element.code}</TableCell>
                                                    <TableCell >{element.data.value}
                                                        {
                                                            element.data.unitOfMeasure === "Percentage" ?
                                                                (
                                                                    <span>%</span>
                                                                ) :
                                                                (
                                                                    <span>{element.data.unitOfMeasure}</span>
                                                                )

                                                        }
                                                    </TableCell>
                                                    <TableCell >{LuxonDateFormatString(element.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "response_time":
                {

                    return (

                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell>Occurred At</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((element, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{element.code}</TableCell>
                                                    <TableCell >{element.data.value}
                                                        {
                                                            element.data.unitOfMeasure === "Percentage" ?
                                                                (
                                                                    <span>%</span>
                                                                ) :
                                                                (
                                                                    <span>{element.data.unitOfMeasure}</span>
                                                                )

                                                        }
                                                    </TableCell>
                                                    <TableCell >{LuxonDateFormatString(element.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>

                    )
                }
            case "alert": {
                return (
                    <React.Fragment>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: '12%' }}>Database</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Details</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Rule Name</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Rule Type</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Server</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Sync Type</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Table</TableCell>
                                        <TableCell sx={{ width: '12%' }}>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell sx={{ width: '12%' }}>{value.data.database}</TableCell>

                                                <Tooltip title={value.data.details}>
                                                    <TableCell sx={{ width: '12%' }}>
                                                        <div>{SubstringStringForToolTip(value.data.details)}</div>
                                                        <div className="report-infra-modal-copy" onClick={() => handleCopyToClipboard(value.data.details)}>Copy Text</div>
                                                    </TableCell>
                                                </Tooltip>
                                                <TableCell sx={{ width: '12%' }}>{value.data.ruleName}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.ruleType}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.server}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.syncType}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{value.data.table}</TableCell>
                                                <TableCell sx={{ width: '12%' }}>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )


            }
            case "aks_diagnostics": {
                if (value.data && value.data.message) {
                    return (
                        <div>
                            <div>Category: {value.data.category}</div>
                            <div>Detector Name: {value.data.detectorName}</div>
                            <div>Message: {value.data.message}</div>
                        </div>
                    )

                }
                return (
                    <span></span>
                )


            }
            case "diagnostics_cpu_drill_down": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )

            }
            case "diagnostics_memory_drill_down": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_key_vault_app_settings": {

                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_snat_port_exhaustion": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_swap_availability_and_performance": {
                if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_tcp_connections_diagnostic": {
                if (value.data && value.data.items) {
                    return (
                        <div>
                            <ul>
                                {
                                    value.data.items.map((item, index) => (
                                        <li className="data-by-code-li" key={index}>
                                            <div>Message: {item.message}</div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_ssl_and_domains": {
                if (value.data && value.data.messages) {
                    return (
                        <div>
                            <ul>
                                {
                                    value.data.messages.map((message, index) => (
                                        <li className="data-by-code-li" key={index}>{message}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_restart": {
                if (value.data && value.data.restartMessages) {
                    return (
                        <div>
                            <div>{value.data.message}</div>
                            <ul>
                                {
                                    value.data.restartMessages.map((item, index) => (
                                        <li className="data-by-code-li" key={index}>
                                            {item}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    )
                }
                else if (value.data && value.data.message) {
                    return (
                        <div>{value.data.message}</div>
                    )
                }
                return (
                    <span></span>
                )
            }
            case "diagnostics_scaling_operations":
                {
                    if (value.data && value.data.messages) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.messages.map((message, index) => (
                                            <li className="data-by-code-li" key={index}>{message}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }
            case "diagnostics_alert":
                {
                    if (value.data && value.data.items) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.items.map((item, index) => (
                                            <li className="data-by-code-li" key={index}>
                                                <div>Name: {item.name}</div>
                                                <div>Description: {item.description}</div>
                                                <div>Status: {item.status}</div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }
            case "diagnostics_event_logs":
                {
                    if (value.data && value.data.items) {
                        return (
                            <div>
                                <ul>
                                    {
                                        value.data.items.map((item, index) => (
                                            <li className="data-by-code-li" key={index}>
                                                <div>Computer: {item.computer}</div>
                                                <div>Date: {item.date}</div>
                                                <div>Description: {item.description}</div>
                                                <div>Level: {item.level}</div>
                                                <div>Event Id: {item.eventId}</div>
                                                <div>Source: {item.source}</div>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )
                    }
                    return (
                        <span></span>
                    )
                }
            case "release_definition_history": {

                return (
                    <React.Fragment>
                        <div className='data-by-code-table-title'>Release Definition History (Code: release_definition_history)</div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Change Type</TableCell>
                                        <TableCell>Changed By</TableCell>
                                        <TableCell>Comment</TableCell>
                                        <TableCell>Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell >{value.data.changeType}</TableCell>
                                                <TableCell >{value.data.changedBy}</TableCell>
                                                <TableCell >{value.data.comment}</TableCell>
                                                <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }
            case "release_history": {

                return (
                    <React.Fragment>
                        <div className='data-by-code-table-title'>Release History (Code: release_history)</div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Release Name</TableCell>
                                        <TableCell>Release Created By</TableCell>
                                        <TableCell>Changed By</TableCell>
                                        <TableCell>Change Type</TableCell>
                                        <TableCell>Change Date</TableCell>
                                        <TableCell>Change Details</TableCell>
                                        <TableCell>Comment</TableCell>
                                        <TableCell>Url</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell >{value.data.releaseName}</TableCell>
                                                <TableCell>{value.data.releaseCreatedBy}</TableCell>
                                                <TableCell >{value.data.changedBy === "Microsoft.VisualStudio.Services.ReleaseManagement" ? 'Azure DevOps' : value.data.changedBy}</TableCell>
                                                <TableCell >{value.data.changeType}</TableCell>
                                                <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                <TableCell >{value.data.changeDetails}</TableCell>
                                                <TableCell >{value.data.comment}</TableCell>
                                                <TableCell ><a className='dp-hyperLink' href={value.data.url} target="_blank" >Click here</a></TableCell>
                                            </TableRow>
                                        ))

                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )

                return (
                    <span></span>
                )

            }
            case "stage_last_modified_on": {


                return (
                    <React.Fragment>
                        <div className='data-by-code-table-title'>Last Modified (Code: stage_last_modified_on)</div>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Attempt (Redeploy)</TableCell>
                                        <TableCell>Created By</TableCell>
                                        <TableCell>Created On</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>URL</TableCell>
                                        <TableCell>Release Name</TableCell>
                                        <TableCell>Modified By</TableCell>
                                        <TableCell>Modified Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {

                                        elements.map((value, innerIndex) => (
                                            <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                <TableCell >{value.data.attempt}</TableCell>
                                                <TableCell >{value.data.createdBy}</TableCell>
                                                <TableCell >{LuxonDateFormatString(value.data.createdOn)}</TableCell>
                                                <TableCell >{value.data.name}</TableCell>
                                                <TableCell >{value.data.status}</TableCell>
                                                <TableCell ><a href={value.data.url} className='dp-hyperLink' target="_blank" >Click here</a></TableCell>
                                                <TableCell >{value.data.releaseName}</TableCell>
                                                <TableCell >{value.data.modifiedBy}</TableCell>
                                                <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                            </TableRow>
                                        ))

                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </React.Fragment>
                )
            }
            case "app_service":
                {


                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Change Category</TableCell>
                                            <TableCell>Description</TableCell>
                                            <TableCell>New Value</TableCell>
                                            <TableCell>Old Value</TableCell>
                                            <TableCell>Property</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.data.changeCategory}</TableCell>
                                                    <TableCell >{value.data.description}</TableCell>
                                                    <TableCell >{value.data.newValue}</TableCell>
                                                    <TableCell >{value.data.oldValue}</TableCell>
                                                    <TableCell >{value.data.property}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment>
                    )
                }
            case "resource_changes":
                {

                    return (
                        <React.Fragment>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Change Type</TableCell>
                                    <TableCell>Changes Count</TableCell>
                                    <TableCell>Resource Group</TableCell>
                                    <TableCell>Changes</TableCell>
                                    <TableCell>Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                    elements.map((value, innerIndex) => (
                                        <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            <TableCell >{value.data.changeType}</TableCell>
                                            <TableCell >{value.data.changesCount}</TableCell>
                                            <TableCell >{value.data.resourceGroup}</TableCell>
                                            <TableCell >{ParseDictionary(value.data.changes)}</TableCell>
                                            <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                        </TableRow>
                                    ))

                                }

                            </TableBody>
                        </React.Fragment>
                    )
                }
            case "app_configuration_or_key_vault":
                {


                    return (
                        <React.Fragment>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ETag</TableCell>
                                            <TableCell>Event Id</TableCell>
                                            <TableCell>Event Type</TableCell>
                                            <TableCell>Key</TableCell>
                                            <TableCell>Label</TableCell>
                                            <TableCell>Provider</TableCell>
                                            <TableCell>Sync Token</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            elements.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell >{value.data.etag}</TableCell>
                                                    <TableCell >{value.data.eventId}</TableCell>
                                                    <TableCell >{value.data.eventType}</TableCell>
                                                    <TableCell >{value.data.key}</TableCell>
                                                    <TableCell >{value.data.label}</TableCell>
                                                    <TableCell >{value.data.provider}</TableCell>
                                                    <TableCell >{value.data.syncToken}</TableCell>
                                                    <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </React.Fragment >
                    )

                }
            default:
                {

                    return (
                        <span></span>
                    )
                }


        }
    }



    const ParseDictionary = (dic) => {
        var htmlItem = [];
        for (const [key, value] of Object.entries(dic)) {
            if (Object.entries(value).length > 0) {

                Object.entries(value).forEach(([k, v]) => {


                    htmlItem.push(<div><span>{k}:</span>&nbsp;<span>{v}</span></div>)

                })
            }
        }
        return htmlItem;
    }


    return (
        <RenderReportData />
    )


}
export default SARenderReportDataByCode