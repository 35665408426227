import { useEffect, useState, useContext, useRef, useReducer } from "react";
import * as React from 'react';
import './HomePage.css';
import SCTop from "../Components/SCTop";
import DataContext from "../Context/DataContext";
import DataContextHome from "../Context/DataContextHome";
import { useHistory } from 'react-router-dom';
import CardsGridView from "../Components/CardsGridView";
import CardsListView from "../Components/CardsListView";
import CardListGroupView from "../Components/CardListGroupView";
import SCDropDownList from "../Components/SCDropDownList";
import SCButton from '../Components/SCButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { CSVLink, CSVDownload } from "react-csv";
import DPOrangeButton from "../Controllers/DPOrangeButton";
import DPGrayButton from "../Controllers/DPGrayButton";
import SCToggle from "../Components/SCToggle";


//https://stackoverflow.com/questions/69965614/how-can-i-prevent-re-render-after-state-changed-in-react-hooks
const HomePage = () => {
    const { services, groupServices, viewMode, setCurrentPage, setViewMode, setServices, servicesCached,
        setGroupServices, groupServicesCached, setSearchText, listOfFrameworks, servicesTagsCache,
        frameworksFilterObject, setFrameworksFilterObject, servicesTags, setServicesTags, listOfTeams
    } = useContext(DataContext);

    const { toggleExcludeValue, setToggleExcludeValue, loadMore, setLoadMore } = useContext(DataContextHome);


    const history = useHistory();

    const [tagFilter, setTagFilter] = useState('')
    const [tagFilterToSearch, setTagFilterToSearch] = useState('')
    const [csvData, setCsvData] = useState([])
    const [typeFilter, setTypeFilter] = useState('')
    const [teamFilter, setTeamFilter] = useState('')
    const [teamLeaderFilter, setTeamLeaderFilter] = useState('')
    const [tierFilter, setTierFilter] = useState('')
    const [frameworkFilter, setFrameworkFilter] = useState('')
    const [frameworkValidFilter, setFrameworkValidFilter] = useState(false)
    const [osProcessor, setOSProcessor] = useState('')

    const [ddlServiceType, setDdlServiceType] = useState([

        { name: 'AppService', value: 'AppService' },
        { name: 'FunctionApp', value: 'FunctionApp' },
        { name: 'Library', value: 'Library' },
        { name: 'JsLibrary', value: 'JsLibrary' },
        { name: 'AksApp', value: 'AksApp' },
        { name: 'EksWebApp', value: 'EksWebApp' },
        { name: 'AksCronJob', value: 'AksCronJob' },
        { name: 'Unknown', value: 'Unknown' }
    ])
    const [ddlFrameworks, setDdlFrameworks] = useState([]);

    const [ddlTags, setDdlTags] = useState([])
    const [ddlTeams, setDdlTeams] = useState([])
    const [ddlTeamLeader, setDdlTeamLeader] = useState([])
    const [ddlTier, setDdlTier] = useState([])
    const [ddlOSProcessor, setDdlOSProcessor] = useState([])
    const [toggleValue, setToggleValue] = useState(false)

    const [searchClicked, setSearchClicked] = useState(false)




    const refFilterFW = useRef(null);


    let keys = [{ key: '2', value: 'netstandard2.0', entities: [] },
    { key: '21', value: 'netstandard2.1', entities: [] },
    { key: '31', value: 'netcoreapp3.1', entities: [] },
    { key: '35', value: 'v3.5', entities: [] },
    { key: '4', value: 'v4.0 ', entities: [] },
    { key: '45', value: 'v4.5', entities: [] },
    { key: '451', value: 'v4.5.1', entities: [] },
    { key: '452', value: 'v4.5.2', entities: [] },
    { key: '46', value: 'v4.6', entities: [] },
    { key: '461', value: 'v4.6.1', entities: [] },
    { key: '462', value: 'v4.6.2', entities: [] },
    { key: '47', value: 'v4.7', entities: [] },
    { key: '471', value: 'v4.7.1', entities: [] },
    { key: '472', value: 'v4.7.2', entities: [] },
    { key: '48', value: 'v4.8', entities: [] },
    { key: '5', value: 'net5', entities: [] },
    { key: '6', value: 'net6', entities: [] },
    { key: '7', value: 'net7', entities: [] },
    { key: '8', value: 'net8.0', entities: [] }]

    useEffect(() => {

        setCurrentPage('services')
        setViewMode('grid')
        let osArray = [];
        osArray.push({ name: '32 bit', value: '32' }, { name: '64 bit', value: '64' });
        setDdlOSProcessor(osArray)

        /* window.addEventListener('unload', handleEndConcert)
         return () => {
 
             window.removeEventListener('unload', handleEndConcert)
             handleEndConcert()
         }*/


    }, [])



    useEffect(() => {

        let tagsArray = [];
        if (servicesTagsCache) {

            //for (const [key, value] of Object.entries(servicesTags)) {
            for (const tag of servicesTagsCache) {



                if (tagsArray.length === 0) {

                    tagsArray.push({ name: tag.value, value: tag.value + '|' + tag.key.toLowerCase() });
                }
                else {

                    if (tagsArray.filter(ta => ta.name.toLowerCase() === tag.value.toLowerCase()).length === 0) {


                        tagsArray.push({ name: tag.value, value: tag.value + '|' + tag.key.toLowerCase() });
                    }
                }
            }
            // }

            setDdlTags(tagsArray);
        }


    }, [servicesTagsCache])

    useEffect(() => {


        if (servicesCached && servicesCached.length > 0) {
            exportFileButtonClick();
        }



    }, [servicesCached])

    useEffect(() => {
        let teams = []
        let teamLeaders = []
        let tiers = []
        if (listOfTeams && listOfTeams.length > 0) {
            listOfTeams.map((teamName) => {
                teams.push({ name: teamName.name.toString().toLowerCase(), value: teamName.name.toString().toLowerCase() });
                teamLeaders.push({ name: teamName.managerName, value: teamName.managerUserId })
            })

        }
        setDdlTeams(teams)
        setDdlTeamLeader(teamLeaders)

        tiers.push({ name: 1, value: 1 });
        tiers.push({ name: 2, value: 2 });
        tiers.push({ name: 3, value: 3 });

        setDdlTier(tiers);

    }, [listOfTeams])


    useEffect(() => {

        let arr = []
        listOfFrameworks.forEach(element => {
            mapFrameworkObject(element)
        });

        setFrameworksFilterObject(keys)
        keys.map((k) => {
            arr.push({ name: k.value, value: k.key });

        })

        setDdlFrameworks(arr)

    }, [listOfFrameworks])


    const exportFileButtonClick = () => {


        const csvServicesData = [
            ["NAME", "DESCRIPTION", "SERVICE TYPE", "REPOSITORY", "MANAGER", "TEAM", "OS PROCESSOR"]
        ];
        services.map((service) => {
            let desc = service.serviceMetadata?.description?.replaceAll("\n", " ").replace("\r", " ");
            csvServicesData.push([
                service.name, desc, service.serviceType,
                service.repositoryName
                , service.serviceMetadata?.manager, service.serviceMetadata?.team,
                getOSProccess(service.use32BitWorkerProcess)
            ]
            )
        })

        setCsvData(csvServicesData)
    }

    const getOSProccess = (os) => {
        if (os === 'True') {
            return "32bit"
        }
        if (os === 'False') {
            return "64bit"
        }
        return "";
    }

    const mapFrameworkObject = (frameworks) => {


        mangeFrameworksVersions(frameworks, keys)


    }
    const mangeFrameworksVersions = (frameworks, keys) => {
        if (frameworks.includes(';')) {
            let splitedFrameworks = frameworks.split(';');
            splitedFrameworks.forEach(element => {
                buildArray(element, keys)
            });
        }
        else {
            buildArray(frameworks, keys)
        }
    }

    const buildArray = (value, keys) => {

        var numb = value.match(/\d/g);
        if (numb !== null) {
            numb = numb.join("");
            if (numb % 10 === 0) {
                numb = numb.split(0, numb.length)[0];
            }

            let selectedKey = keys.filter(k => k.key.toString() == numb.toString());




            if (!selectedKey[0].entities.filter(e => e === value).length > 0)
                selectedKey[0].entities.push(value);



        }

    }

    const handleEndConcert = () => {
        setViewMode('grid')

        let toggledServices = [...servicesCached]
        setServices(toggledServices.filter(s => s.excludedFromBackstage === toggleExcludeValue))

    }


    const handleTagsFilterChange = (e, index) => {

        setTagFilter(index.props.value)
        setTagFilterToSearch(index.props.name)


    }
    const handleTypeFilterChange = (e) => {
        setTypeFilter(e.target.value)

    }
    const handleTeamFilterChange = (e) => {
        setTeamFilter(e.target.value)
    }

    const handleTeamLeaderFilterChange = (e) => {
        setTeamLeaderFilter(e.target.value)
    }

    const handleTierFilterChange = (e) => {

        setTierFilter(e.target.value)
    }



    const handleFrameworkFilterChange = (e) => {

        setFrameworkFilter(e.target.value)



    }

    const getAllServicesByFramework = (frameworksToSearch) => {
        var filteredServices = []
        frameworksToSearch.forEach(fw => {


            filteredServices = servicesCached?.filter((service) => {
                if (service.frameworkStatus.filter(fs => fs.framework === fw).length > 0) {
                    return true;
                }
                return false;
            }).map((service) => { return service; });


        });
        return filteredServices;
    }

    const handleResetButtonClick = () => {
        setTagFilter('')
        setTypeFilter('')
        setTeamFilter('')
        setTeamLeaderFilter('')
        setTierFilter('')
        setFrameworkFilter('')
        setOSProcessor('')
        setToggleValue(false)
        setToggleExcludeValue(false)
        setSearchClicked(false)
        setLoadMore(1)

        let tempServices = [...servicesCached]

        setServices(tempServices.filter(s => s.excludedFromBackstage === false))

    }
    const handleButtonClick = () => {

        setLoadMore(1)
        setSearchClicked(true)
        let frameworksToSearch = frameworksFilterObject.filter(fw => fw.key === frameworkFilter);



        var servicesAfterfilter = servicesCached?.filter((service) => {
            if (frameworkFilter === '')
                return true
            else {

                if (service.frameworkStatus.filter(fs => fs.framework?.toLowerCase() === frameworksToSearch[0].entities[0]?.toLowerCase()).length > 0) {
                    return true;
                }
                return false;
            }

        }).map((service) => {

            if (typeFilter === '') {
                return service
            }
            else {
                if (service.serviceType === typeFilter) {
                    return service;
                }
                return false;
            }
            // }).map((service) => { return service });
        }).map((service) => {

            if (tagFilter === '') {
                return service
            }
            else {


                if (service?.serviceMetadata?.tags?.filter(t => t.value.toString().toLowerCase() === tagFilterToSearch.toString().toLowerCase()).length > 0) {


                    return service;
                }
                return false;
            }
        }).map((service) => {

            if (osProcessor === '') {
                return service
            }
            else {


                if (service?.use32BitWorkerProcess === "True" && osProcessor === "32") {
                    return service;
                }
                if (service?.use32BitWorkerProcess !== "True" && osProcessor === "64") {
                    return service;
                }
                return false;
            }
        }).map((service) => {

            if (teamFilter === '') {
                return service
            }
            else {

                if (service?.serviceMetadata?.team?.toLowerCase() === teamFilter.toLowerCase()) {
                    return service;
                }
                return false;
            }
        })
            .map((service) => {

                if (teamLeaderFilter === '') {
                    return service
                }
                else {

                    if (service?.serviceMetadata?.userManagerId?.toLowerCase() === teamLeaderFilter.toLowerCase()) {
                        return service;
                    }
                    return false;
                }
            })
            .map((service) => {

                if (tierFilter === '') {
                    return service
                }
                else {

                    if (service?.serviceMetadata?.tier?.toString() === tierFilter.toString()) {
                        return service;
                    }
                    return false;
                }
            })
            .map((service) => { return service });

        let servicesFiltered = [].concat(servicesAfterfilter.filter(s => s !== false));


        if (toggleValue) {

            servicesFiltered = servicesFiltered.map((func) => {
                let res = func.frameworkStatus.filter(f => f.status !== 'Valid')
                if (res != undefined && res.length > 0) {
                    return func;
                }
                return false;

            }).filter(s => s !== false);


        }

        if (toggleExcludeValue) {
            servicesFiltered = servicesFiltered.filter(s => s.excludedFromBackstage === true);
        }
        else {
            servicesFiltered = servicesFiltered.filter(s => s.excludedFromBackstage === false);
        }
        setServices(servicesFiltered)

    }

    const handleDisplayFrameworkChange = (e) => {
        setToggleValue(!toggleValue)
    }

    const handleDisplayExcludeChange = (e) => {


        setToggleExcludeValue(!toggleExcludeValue)


    }


    const handleOSProcessorFilterChange = (e) => {
        setOSProcessor(e.target.value);
    }



    const handleExportGenerateServices = () => {

        exportFileButtonClick();
    }



    const BuildCards = () => {
        if (viewMode === 'grid') {
            return (
                <div>
                    <CardsGridView />

                </div>
            )
        }
        else if (viewMode === 'list') {
            return (
                <div>
                    <CardsListView />
                </div>
            )
        }
        else {
            return (
                <div>
                    <CardListGroupView />
                </div>
            )
        }

    }


    const generateServicesView = React.useMemo(() => <BuildCards />, [viewMode]);

    return (
        <div>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} totalAfterFilter={searchClicked ? services.length : 0} />
            </div>
            <div className="sc-main">

                <div className="all-services-filter-container" >

                    <div className="all-services-row all-services-row-padding-large">
                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="OS Processor" componentState={osProcessor} setComponentState={setOSProcessor}
                                size="small" items={ddlOSProcessor} handleChange={(e, index) => handleOSProcessorFilterChange(e, index)} value={osProcessor} />
                        </div>

                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Tags" componentState={tagFilter} setComponentState={setTagFilter}
                                size="small" items={ddlTags} handleChange={(e, index) => handleTagsFilterChange(e, index)} value={tagFilter} />
                        </div>

                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Teams" componentState={teamFilter} setComponentState={setTeamFilter}
                                size="small" items={ddlTeams} handleChange={(e) => handleTeamFilterChange(e)} value={teamFilter} />
                        </div>


                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Team Leader" componentState={teamLeaderFilter} setComponentState={setTeamLeaderFilter}
                                size="small" items={ddlTeamLeader} handleChange={(e) => handleTeamLeaderFilterChange(e)} value={teamLeaderFilter} />
                        </div>

                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Tier" componentState={tierFilter} setComponentState={setTierFilter}
                                size="small" items={ddlTier} handleChange={(e) => handleTierFilterChange(e)} value={tierFilter} />
                        </div>

                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Type" componentState={typeFilter} setComponentState={setTypeFilter}
                                size="small" items={ddlServiceType} handleChange={(e) => handleTypeFilterChange(e)} value={typeFilter} />
                        </div>
                        <div className="span-filter">
                            <SCDropDownList className="md-ddl" label="Framework" componentState={frameworkFilter} setComponentState={setFrameworkFilter}
                                size="small" items={ddlFrameworks} handleChange={(e) => handleFrameworkFilterChange(e)} value={frameworkFilter} />
                        </div>
                        <div className="span-filter-button" >
                            <DPOrangeButton text="Search" handleButtonClick={handleButtonClick} />

                        </div>
                        <div className="span-filter-button" >
                            <DPGrayButton text="Reset" handleButtonClick={handleResetButtonClick} />
                        </div>
                        <div className="span-filter-button-export" >
                            <div className="services-export-button"><CSVLink className="service-export-link" filename="InfraConsoleServices.csv" onClick={handleExportGenerateServices} data={csvData}>Export</CSVLink></div>
                        </div>
                        <div className="all-services-toggles ">
                            <div>
                            <SCToggle width="550px" handleToggleChange={() => handleDisplayFrameworkChange()} title="Display only services that contain EOL or EOS frameworks:" isChecked={toggleValue} />
                            </div>
                            <div>
                            <SCToggle width="550px" handleToggleChange={() => handleDisplayExcludeChange()} title="Display only services that exclude is on:" isChecked={toggleExcludeValue} />
                            </div>
                        </div>
                    </div>
                   
                    <div className="all-services-row all-services-row-padding">
                        {generateServicesView}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage
