import './SCToggle.css';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';


const SCToggle = ({ handleToggleChange, isChecked, title, width = '350px' }) => {
  
    return (
        <div style={{ width: width }} >
            <span style={{ position:'relative' }} >{title}
            <span style={{ marginLeft: '5px', position:'absolute', top:'-10px'}}>
                <FormControlLabel className='valid-services'
                    control={
                        <Switch id="toggle-ss-qa"

                            sx={{
                                '& .MuiSwitch-root':{
                                    width: '58px',
                                    height: '40px'
                                },
                                '& .MuiSwitch-track': {
                                    backgroundColor: 'gray !important',
                                    height:'17px !important'
                                },
                                '& .MuiSwitch-colorPrimary':{
                                    marginLeft: '4px !important',
                                },
                                '& .MuiSwitch-thumb':{
                                    marginTop: '4px !important',
                                    
                                    width: '15px !important',
                                    height: '15px !important'
                                },
                                '& .Mui-checked .MuiSwitch-thumb': {
                                    color: '#F36805',
                                    paddingLeft: '5px',
                                    marginLeft: '-4px'
                                }


                            }}
                            checked={isChecked} onChange={(e) => handleToggleChange(e)} />
                    }
                />
            </span>
            </span>
        </div>
    )
}

export default SCToggle
