import { useEffect, useState } from 'react';
import './ChatHeader.css';
import { useContext } from 'react';
import { DataContextChat } from '../../Context/DataContextChat';
import closeButton from '../../Images/newIcons/closeChatButton.svg'

const ChatHeader = ({ text }) => {

    const { toggleChat} = useContext(DataContextChat);
    


 

    return (
        <div className='copilot-chat-header' >
            
            <span className='copilot-chat-header-text'>{text} <span class="mp0 nj0" aria-label="Emoji: Waving Hand" title="Waving Hand" alt="👋"></span></span>
            
            <img onClick={toggleChat} className='copilot-chat-header-close' src={closeButton} alt="close button" />
            
                  
        </div>
    );
};

export default ChatHeader;