import { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import DogPicture from './DogPicture';
import InfraAvatar from './InfraAvatar';
import React, { useContext } from 'react';
import DataContext from '../../Context/DataContext';
import HtmlContentWidget from './HtmlContentWidget';
import TypingMessage from './TypingMessage';
import ChatHeader from './ChatHeader';
import { v4 as uuid } from 'uuid';
import HtmlContentReportWidget from './HtmlContentReportWidget';
import IncidentReportButtonWidget from './IncidentReportButtonWidget';
import SelfServiceFillFormWidget from './SelfServiceFillFormWidget';


//https://fredrikoseberg.github.io/react-chatbot-kit-docs/docs/

const botName = 'Copilot';

const messageTyping = createChatBotMessage({ message: "", type: 'typing', id: '000' });

const config = {

  initialMessages: [createChatBotMessage({ message: `Hi ${localStorage.getItem('InfraConsoleName')} I'm your ${botName}. How can i help you?`, type: 'welcome', id: '000' })],
  customStyles: {
    // Overrides the chatbot message styles   
    // Overrides the chat button styles
    /* chatButton: {
       backgroundColor: "#5ccc9d",
       
     },*/
  },
  botName: botName,
  customComponents: {
    //botMessageBox: (props) => <HtmlContentWidget {...props} />,
    // Replaces the default header
    //header: () => <ChatHeader  text={`Infrastructure Copilot With ${botName}`} />,
    header: () => <ChatHeader text={`Hi there `} />,
    
    // Replaces the default bot avatar
    botAvatar: (props) => <InfraAvatar {...props} />,
    // Replaces the default bot chat message container

    // botChatMessage: (props) => <HtmlContentWidget {...props} />,

    botChatMessage: (props) => {

      //createChatBotMessage({message:`Hi ${localStorage.getItem('InfraConsoleName')} I'm ${botName}, How can i help you? ${config?.payload?.module}` , type:'html', id:'000'})
      if (props.message.type === 'html') {
        return <HtmlContentWidget {...props} />;
      } else if (props.message.type === 'welcome') {
        return <HtmlContentReportWidget {...props} />;
      } else if (props.message.type === 'report') {
        return <HtmlContentReportWidget {...props} />;
      } else if (props.message.type === 'typing' && props.message.message !== "done") {
        return <TypingMessage messages={props.message} />;}
      else if (props.message.type === 'report-widget') {
        return <IncidentReportButtonWidget {...props} />;
      } 
      else if (props.message.type === 'self-service-widget') {
        return <SelfServiceFillFormWidget {...props} />;
      }
    
      else {

        //return <HtmlContentWidget {...props} />;  // Fallback to HtmlMessage
        return <React.Fragment></React.Fragment>

      }
    },


    // Replaces the default user icon
    // userAvatar: (props) => <MyCustomAvatar {...props} />,
    // Replaces the default user chat message
    // userChatMessage: (props) => <MyCustomUserChatMessage {...props} />
  },

  widgets: [
    
    /*
    {
      widgetName: 'dogPicture',
      widgetFunc: (props) => <DogPicture {...props} />,
    }/*,
    {
      widgetName: "htmlContentWidget",
      widgetFunc: (props) => <HtmlContentWidget {...props} />,
      mapStateToProps: ["messages"],
   }*/
  ],
};

export default config;