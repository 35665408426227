import { createContext, useState, useEffect, useContext } from 'react';
//import axios from '../axios';
import cicdAxios from '../cicdAxios';
import * as consts from '../consts';
import _ from "lodash";
import { getAllByPlaceholderText } from '@testing-library/react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import * as msal from "@azure/msal-browser";
import { useHistory, useParams } from 'react-router-dom';
import useQuery from '../Hooks/useQuery';
import { toast } from 'react-toastify';
import { FaRegSquare } from 'react-icons/fa';
import jwt_decode from 'jwt-decode';
import { DateTime, DateTime as luxon } from "luxon";
import Queue from '../queue';
import useAxios from '../Hooks/useAxios';
import useLogin from '../Hooks/useLogin';
import { DataContextChat } from './DataContextChat';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';


//import useStateWithCallback from 'use-state-with-callback';

const DataContext = createContext({});
const requestQueue = new Queue();
export const DataProvider = ({ children }) => {

  const instance = useAxios(process.env.REACT_APP_BASE_URL);
  const instanceSelfService = useAxios(process.env.REACT_APP_CICD_BASE_URL);
  const instanceCopilot = useAxios(process.env.REACT_APP_COPILOT);

  const [progressBarValue, setProgressBarValue] = useState(0);
  const [progressBarText, setProgressBarText] = useState('');
  const [progressBarShow, setProgressBarShow] = useState(false);
  const [requestCICDItems, setRequestCICDItems] = useState({ status: null, data: [] });
  const [requestCICDUnreadItems, setRequestCICDUnreadItems] = useState([]);
  const [userPermissionRequests, setUserPermissionRequests] = useState({ status: null, data: [] })
  const [title, setTitle] = useState('');
  const [services, setServices] = useState('');
  const [servicesCached, setServicesCached] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [groupBy, setGroupBy] = useState('');
  const [groupedServices, setGroupedServices] = useState('');
  const [searchText, setSearchText] = useState('');
  const [modalDetailsShow, setModalDetailsShow] = useState(false);
  const [modalPermissionShow, setModalPermissionShow] = useState(false);
  const [pipelineState, setPipelineState] = useState(-1) //0 - not started  // 1 - in progress // 2 - done  // 3 - timeout
  const [modalNotificationShow, setModalNotificationShow] = useState(false);
  const [modalAlertShow, setModalAlertShow] = useState(false)
  const [tlvUsers, setTLVUsers] = useState([])
  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [modalServiceDetailsShow, setModalServiceDetailsShow] = useState(false);
  const [modalBuildShow, setModalBuildShow] = useState(false);
  const [modalEditServiceDetailsShow, setModalEditServiceDetailsShow] = useState(false);
  const [modalEditServiceMetaDataShow, setModalEditServiceMetaDataShow] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([{ name: 'Home', route: '' }]);
  const [selectedService, setSelectedService] = useState({});
  const [insights, setInsights] = useState([]);
  const [projectItem, setProjectItem] = useState({});
  const [flatPackagesRelation, setFlatPackagesRelation] = useState({});
  const [groupedPackages, setGroupedPackages] = useState([]);
  const [topScore, setTopScore] = useState([]);
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [branchWithoutMaster, setBranchWithoutMaster] = useState([]);
  const [servicesMetaData, setServicesMetaData] = useState([]);
  const [updateDetailsSuccess, setUpdateDetailsSuccess] = useState(false);
  const [branches, setBranches] = useState([]);
  const [branchDetails, setBranchDetails] = useState({});
  const [buildDefinitions, setBuildDefinitions] = useState([]);
  const [menuSelectedItem, setMenuSelectedItem] = useState('Services');
  const [mdEditRowTable, setMdEditRowTable] = useState([]);
  const [mdEditEnvTable, setMdEditEnvTable] = useState([]);
  const [mdEditTagsTable, setMdEditTagsTable] = useState([]);
  const [mdEditReleaseTable, setMdEditReleaseTable] = useState([]);
  const [viewMode, setViewMode] = useState('grid');
  const [infraNugetsServices, setInfraNugetsServices] = useState([]);
  const [packagesWithVersions, setPackagesWithVersions] = useState([]);
  const [servicesByPackageAndVersion, setServicesByPackageAndVersion] = useState([]);
  const [currentPage, setCurrentPage] = useState('');
  const [page, setPage] = useState({});
  const [isEditorUser, setIsEditorUser] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [groupServices, setGroupServices] = useState([]);
  const [groupServicesCached, setGroupServicesCached] = useState('');
  const [resultsFound, setResultsFound] = useState('');
  const [scoreRules, setScoreRules] = useState([]);
  const [listOfFrameworks, setListOfFrameworks] = useState([]);
  const [servicesTags, setServicesTags] = useState([])
  const [servicesTagsCache, setServicesTagsCache] = useState([])

  const [frameworksFilterObject, setFrameworksFilterObject] = useState([]);
  const [manageFilter, setManageFilter] = useState([{ name: 'AppService', type: 'unchecked', element: Object.create(<FaRegSquare />) },
  { name: 'FunctionApp', type: 'unchecked', element: Object.create(<FaRegSquare />) },
  { name: 'Library', type: 'unchecked', element: Object.create(<FaRegSquare />) }, { name: 'JsLibrary', type: 'unchecked', element: Object.create(<FaRegSquare />) },
  { name: 'Other', type: 'unchecked', element: Object.create(<FaRegSquare />) }])

  const [loginState, setLogin] = useState({
    error: null,
    isAuthenticated: false,
    user: {},
  });

  const [forceInit, setForceInit] = useState(false)

  const [isUserAuth, setIsUserAuth] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const [token, setToken] = useState('');
  const [aiMessages, setAIMessages] = useState([])
  const [htmlContent, setHtmlContent] = useState([])
  const [connection, setConnection] = useState(null);
  const [notification, setNotification] = useState([]);
  const [tabsAutoCICDValue, setTabsAutoCICDValue] = useState({ TabValue: '0' });
  const history = useHistory();
  const query = useQuery();
  const { id } = useParams();

  const { userLogin, userToken } = useLogin();


  const { toggleChatOnPageChange } = useContext(DataContextChat);
  const location = useLocation();
  const { search } = location;
  const urlParams = new URLSearchParams(search);

  

  useEffect(async () => {
    


    const short = urlParams.get('short');

    if ((userToken && short !== "slack" && short !== "incident") || (userToken && forceInit)) {
      setForceInit(false)
      setUserPermissionRequests({ status: 0, data: [] });
      Promise.all([getAllServices(), getGroups(), getInsights(), getTags(), getTeams(),
      getDepartment(), getUsers(), getUserCICDRequests(), getMyRequests()
      ])
        .then((results) => {

          const resServices = results[0];
          const resGroup = results[1];
          const resInsights = results[2];          
          const resTags = results[3];
          const resTeams = results[4];
          const resDepartemnts = results[5];
          const resUsers = results[6];
          const resPermission = results[8];

          if (results) {

            if (resDepartemnts?.status === 200) {
              setListOfDepartments(resDepartemnts.data)
            }
            if (resUsers?.status === 200) {
              setListOfUsers(resUsers.data)
            }

            if (resTeams?.status === 200) {

              setListOfTeams(resTeams.data)
            }
            if (resServices?.status === 200) {


              const groupedUsers = _.groupBy(resServices.data, 'repositoryName');
              const filteredGroups = _.pickBy(groupedUsers, (group) => group.length > 1);

              resServices?.data?.map((service) => {
                let updateService = service;
                let sharingRepoServices = filteredGroups[service.repositoryName];
                if (sharingRepoServices && sharingRepoServices.length > 0) {
                  updateService.shareTheSameRepoWith = sharingRepoServices
                }
              })



              setServices(resServices.data.filter(s => s.excludedFromBackstage === false))
              setServicesCached(resServices.data)

              getTopScore(resServices.data);
              getDistinctServicesFrameworks(resServices.data)
              getMasterAndMainBranches(resServices.data)
            }
            if (resGroup?.status === 200) {
              var groups = [];
              resGroup?.data?.forEach(g => {
                groups.push({ GroupName: g.groupName, GroupDesc: g.description, id: g.id, ServiceIds: g.serviceIds })
              });
              setGroupServices([...groups])
              setGroupServicesCached([...groups])
            }
           // if (resInsights?.status === 200) {
              setInsights(resInsights)
           // }
            if (resTags?.status === 200) {
              setServicesTags(resTags?.data)
              setServicesTagsCache(resTags?.data)
            }
            if (resPermission?.status === 200) {
              setUserPermissionRequests({ status: resPermission.status, data: resPermission?.data?.content })
            }

          }
        });
    }
    else {
   
   
      let loginResponse = await userLogin()
      setToken(loginResponse?.accessToken)
      if (loginResponse?.accessToken) {
        const decoded = jwt_decode(loginResponse?.accessToken);
        setUserAccount({
          user: decoded.name,
          userName: decoded.upn
        });
      }

      if (loginResponse?.idTokenClaims?.roles && loginResponse?.idTokenClaims?.roles.length > 0) {

        setIsEditorUser(true)
        setIsAdminUser(true)
      }
    
    }

  }, [userToken, forceInit]);


  

  useEffect(() => {
    if (services) {
      checkForServiceNameQuery();
    }
  }, [services]);


  const deptManagerEmailList = ['tomer fefer', 'sander kole', 'elizabeth eastaugh']
  const initDeperatmentManager = () => {
    if (listOfDepartments && listOfDepartments.length > 0) {
      var deptManagerList = []
      listOfDepartments.map((dept) => {
        if (buildDepartmentManagers(dept)) {
          deptManagerList.push({
            name: dept.manager,
            value: dept.id
          })
        }
      })
      return deptManagerList;
    }

  }

  const doForceInit = () => {


    const short = urlParams.get('short');

    if (servicesCached === '' && short === 'slack') {
      setForceInit(true)
    }
  }

  const initSubDeperatmentManager = () => {
    let subDeptManagerList = [];
    listOfDepartments.map((dept) => {

      if (!_.some(subDeptManagerList, { name: dept.manager }) && dept.parentDepartmentId !== "") {
        subDeptManagerList.push({
          name: dept.manager,
          value: dept.id
        })
      }
    })
    return subDeptManagerList;

  }

  const initTiers = () => {
    let tiers = [{
      name: '',
      value: ''
    }];
    for (let i = 1; i < 4; i++) {
      tiers.push({
        name: i,
        value: i.toString()
      })
    }
    return tiers;
  }

  const initTeams = () => {
    var teams = [];
    if (listOfTeams.length > 0) {
      listOfTeams.map((item => {
        teams.push({
          name: item.name,
          value: item.id
        })
      }))
      return teams;

    }
    else {
      getTeams().then((response) => {

        if (response.data.length > 0) {
          response.data.map((item => {
            teams.push({
              name: item.name,
              value: item.id
            })
          }))
          return teams;
        }
      })
    }
  }



  const buildDepartmentManagers = (item) => {

    return deptManagerEmailList.filter(d => d === item.manager.toLowerCase()).length > 0 ? true : false
  }


  const getSelfServiceRepositories = async (projectName) => {

    if (userToken) {
      const url = consts.requests.getSelfServiceRepositories.replace('{projectName}', projectName);
      return instanceSelfService.get(url)
    }
  }

  const getSelfServiceEnvironments = async (projectName) => {
    if (userToken) {
      const url = consts.requests.getCICDAutoACR.replace('{projectName}', projectName);
      return instanceSelfService.get(url)
    }
  }

  const getSelfServiceSubscriptions = async () => {
    if (userToken) {
      const url = consts.requests.getSelfServiceSubscriptions;
      return instanceSelfService.get(url)
    }
  }

  const getSecrets = async (keyVaultUri) => {
    if (userToken) {
      const url = consts.requests.getSecrets.replace('{keyVaultUri}', keyVaultUri);
      return instanceSelfService.get(url)
    }
  }

  const getSolutions = async (projectName, repositoryName, branch) => {
    if (userToken) {
      const url = consts.requests.getSolutions.replace('{projectName}', projectName).replace('{repositoryName}', repositoryName).replace('{branch}', branch);
      return instanceSelfService.get(url)
    }
  }

  const getCSProjFiles = async (projectName, repositoryName, branch, solutionFileDir) => {
    if (userToken) {
      const url = consts.requests.getCSProjFiles.replace('{projectName}', projectName).replace('{repositoryName}', repositoryName)
        .replace('{branch}', branch).replace('{solutionFileDir}', solutionFileDir);;
      return instanceSelfService.get(url)
    }
  }

  const selfServiceCopilotJson = async (prompt, ignoreSpinner = true) => {
    if (userToken) {
      const url = consts.requests.selfServiceCopilotJson.replace('{prompt}', prompt);
      return instanceCopilot.get(url, { ignoreSpinner: ignoreSpinner })
    }
  } 

  const githubBranches = async (projectName, repositoryName) => {
    if (userToken) {
      const url = consts.requests.githubBranches.replace('{projectName}', projectName).replace('{repositoryName}', repositoryName);        
      return instanceSelfService.get(url)
    }
  }
  const githubRepository = async (projectName) => {
    if (userToken) {
      const url = consts.requests.githubRepository.replace('{projectName}', projectName);        
      return instanceSelfService.get(url)
    }
  }
  const githubSolutionFile = async (projectName,repositoryName, commitId) => {
    if (userToken) {
      const url = consts.requests.githubSolutionFile.replace('{projectName}', projectName).replace('{repositoryName}', repositoryName)
      .replace('{commitId}',commitId);    
      return instanceSelfService.get(url)
    }
  }
  const githubProjectSettingsFile = async (projectName,repositoryName,commitId,solutionFileDir) => {
    if (userToken) {
      const url = consts.requests.githubProjectSettingsFile.replace('{projectName}', projectName).replace('{repositoryName}', repositoryName)
      .replace('{commitId}', commitId).replace('{solutionFileDir}', solutionFileDir);    
      return instanceSelfService.get(url)
    }
  }
  

  const getKVNames = async (subscriptionId) => {
    if (userToken) {
      const url = consts.requests.getKVNames.replace('{subscriptionId}', subscriptionId);
      return instanceSelfService.get(url)
    }
  }

  const getSelfServiceBranchesForExsitingRepo = async (projectName, repoName) => {
    if (userToken) {
      const url = consts.requests.getSelfServiceBranchesForExsitingRepo.replace('{projectName}', projectName).replace('{repoName}', repoName);
      return instanceSelfService.get(url)
    }
  }

  const isNullOrEmpty = (str) => {
    return str === null || str === undefined || str === '';
  }

  const getUserCICDRequests = () => {
    var selfServiceItems = { status: 0, data: [] };
    setRequestCICDItems(selfServiceItems);
    instanceSelfService.get(consts.requests.getCICDAutoRequests).then((response) => {
      selfServiceItems = { status: response.status, data: [] };
      let items = [];
      let unreadItems = [];
      if (!response.data.content) {
        setRequestCICDItems(selfServiceItems);
        return;
      }
      response.data.content.map((item) => {
        let object = JSON.parse(item.response);
        let payload = JSON.parse(item.requestPayLoad)
        var userItem = {};


        if (item.response !== null) {
          userItem = {
            appName: object.appName,
            repoName: object.repoName,
            buildPipelineUrl: object.buildPipelineUrl ? renderPipelineUrlHtml(object.buildPipelineUrl) : '',
            releasePipelineUrl: object.releasePipelineUrl ? renderPipelineUrlHtml(object.releasePipelineUrl) : '',
            repoUrl: object.repositoryUrl ? renderRepoUrl(object.repositoryUrl, object.repoName) : '',
            readByServiceCatalog: item.readByServiceCatalog
          }
        }
        if (item.requestPayLoad) {
          userItem.appName = payload.appName;
          userItem.repoName = payload.repositoryName;
          userItem.buildPipelineUrl = userItem.buildPipelineUrl && userItem.buildPipelineUrl;
          userItem.releasePipelineUrl = userItem.releasePipelineUrl && userItem.releasePipelineUrl;
          userItem.repoUrl = userItem.repoUrl && userItem.repoUrl;
        }


        //DB section
        userItem.azureDevOpsProject = item.azureDevOpsProject;
        userItem.dataBaseCreationStatus = item.dataBaseCreationStatus;
        userItem.dataBaseKeyVaultKey = item.dataBaseKeyVaultKey;
        userItem.dataBaseKeyVaultName = item.dataBaseKeyVaultName;


        userItem.approvalManagerEmail = item.approvalManagerEmail && item.approvalManagerEmail;
        userItem.approvalStatus = item.approvalStatus && item.approvalStatus;
        userItem.approvalTicketNumber = item.approvalTicketNumber && item.approvalTicketNumber;
        userItem.createdOn = item.createdOn && formatDateTime(item.createdOn);
        userItem.processStatus = item.processStatus && item.processStatus;

        userItem.partitionKey = item.rowKey;
        userItem.readByServiceCatalog = item.readByServiceCatalog;
        userItem.failureReason = item.failureReason;




        items.push(userItem);

      })

      const filteredItems = _.filter(items, (item) => item.approvalTicketNumber !== null && item.approvalTicketNumber !== undefined && item.approvalTicketNumber !== '');
      const filteredItemsOrdered = _.orderBy(filteredItems, [(item) => item.approvalTicketNumber], ['desc']);

      selfServiceItems.data = filteredItemsOrdered;

      setRequestCICDItems(selfServiceItems);



    }).catch((error) => {
      setRequestCICDItems({ status: 500, data: [] });
    })
  }

  const renderRepoUrl = (item, name) => {
    return (
      <div>
        <a href={item} target="_blank" >{name}</a>
      </div>
    )

  }

  const renderPipelineUrlHtml = (items) => {
    return (
      <div>
        {

          Object.entries(items).map(([key, value]) => (
            <div key={key}>Branch:<a href={value} target="_blank" > {key}</a></div>
          ))

        }
      </div>
    )

  }
  const getDistinctServicesFrameworks = (services) => {
    //frameworkStatus
    let arr = [];
    services.forEach(service => {
      buildDistinctObject(service, arr)
    });

    setListOfFrameworks(arr)
  }
  const handleTeamLeaderNameById = (id) => {

    if (listOfTeams.length > 0) {
      let team = listOfTeams.filter(t => t.managerUserId === id)
      if (team.length > 0) {


        return team[0].managerName

      }
    }
    return ''
  }
  const handleTeamNameById = (id) => {


    if (listOfTeams.length > 0) {
      let name = listOfTeams.filter(t => t.id === id)
      if (name.length > 0) {

        return { team: name[0].name, manager: name[0].managerName }

      }
    }
    return ''
  }
  const buildDistinctObject = (service, arr) => {
    service.frameworkStatus?.forEach(fw => {

      if (fw.framework === null)
        return;

      if (arr.length > 0) {

        if (!arr.includes(fw.framework.toLowerCase())) {
          arr.push(fw.framework.toLowerCase())

        }
      }
      else {
        arr.push(fw.framework.toLowerCase())
      }



    });



  }

  const getApprovalManagerDropDown = () => {
    let managers = JSON.parse(process.env.REACT_APP_APPROVAL_MANAGER);
    let managersArray = [];
    managers.map((manager) => {

      managersArray.push({
        name: manager.name,
        value: manager.email
      })
    })
    return managersArray;
  }

  const getTopScore = (services) => {

    var filtered = services.filter((s) => {
      return s.serviceScore != null;
    });

    var grouped = _.groupBy(filtered, "serviceScore.totalScore");

    var scoringMedal = []

    for (const [key, value] of Object.entries(grouped)) {

      scoringMedal.push({ score: Number(key), count: value.length, items: value, medal: '' });
    }

    let topScoreItems = _.orderBy(scoringMedal, ['score'],
      ['desc']);


    if (topScoreItems.length > 0) {
      topScoreItems[0].medal = 'gold';
    }
    if (topScoreItems.length > 1) {
      topScoreItems[1].medal = 'silver';
    }
    if (topScoreItems.length > 2) {
      topScoreItems[2].medal = 'bronze';
    }






    setTopScore(topScoreItems);




  }

  const checkForServiceNameQuery = () => {

    if (query.get("name")) {

      var service = services.filter(s => s.name === query.get("name"));
      setSelectedService(service[0]);

    }
  }


  const handleBreadCrumbs = (name, route) => {


    breadcrumbs.length = 0;
    if (route === '/Service') {
      breadcrumbs.push({ name: 'Catalog', route: '/Catalog' });
    }

    breadcrumbs.push({ name: name, route: route });

    setBreadcrumbs([...breadcrumbs]);


  }

  const initPublicClientApplication = () => {

    const msalConfig = {
      auth: {
        clientId: process.env.REACT_APP_ID,
        authority: process.env.REACT_APP_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URL,
        scopes: getScopes()
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    };

    return new msal.PublicClientApplication(msalConfig);
  }
  const getScopes = () => {
    const scopesArray = {

      impersonation: process.env.REACT_APP_SCOPES_DEFAULT,
    };
    return Object.values(scopesArray);
  }


  /*const getToken = async (accountInfo, msalInstance, scopes) => {
    //let request: msal.SilentRequest = {
    let request = {
      scopes: scopes,
      account: accountInfo, // (or null?)
    };

    try {
      let tokenResponse = await msalInstance.acquireTokenSilent(request);
      //save token to local storage
      localStorage.setItem('InfraConsoleToken', tokenResponse.accessToken)
      const decoded = jwt_decode(tokenResponse.accessToken);

      localStorage.setItem('InfraConsoleName', decoded.name)
      localStorage.setItem('InfraConsoleUserName', decoded.upn)
      localStorage.setItem('InfraConsoleExpired', decoded.exp)

      handleLoginState(
        accountInfo.name,
        accountInfo.username,
        tokenResponse.accessToken,
        false
      );

      if (tokenResponse?.idTokenClaims?.roles && tokenResponse?.idTokenClaims?.roles.length > 0) {

        setIsEditorUser(true)
        setIsAdminUser(true)
      }
      return tokenResponse.accessToken;

    }
    catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        history.push('/NotAuthorized')
        return msalInstance.acquireTokenRedirect(request);
      } else {
        console.log(error);
      }
    }


  };*/
  const login = async () => {
    /*
        const msalInstance = initPublicClientApplication();
        console.log('LOGIN!!!')
      
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
        }
    
        msalInstance.addEventCallback(
          (event) => {
            // set active account after redirect
            if (
              event.eventType === EventType.LOGIN_SUCCESS &&
              event.payload.account
            ) {
              const account = event.payload.account;
              msalInstance.setActiveAccount(account);
            }
          },
          (error) => {
            console.log("error", error);
          }
        );
    
        try {
          let authResult = await msalInstance.handleRedirectPromise();
          const account = msalInstance.getActiveAccount();
          if (!account) {
            // redirect anonymous user to login page        
            msalInstance.loginRedirect();
          } else {
            return await getToken(account, msalInstance, getScopes());
          }
        }
        catch (error) {
          console.log(error);
        }   */
  };

  /*const handleLoginState = (name, username, accessToken, isLogout) => {

    setLogin(
      !isLogout ? { isAuthenticated: true } : { isAuthenticated: false }
    );
    setIsUserAuth(true);
    setUserAccount({
      user: !isLogout ? name : null,
      userName: !isLogout ? username : null,
    });
    setToken(!isLogout ? accessToken : null);

    console.log('TOKEN!!!')
  };
*/
  const handleLogout = () => {
    const msalInstance = initPublicClientApplication();
    msalInstance.logout();

    setUserAccount({
      user: null,
      userName: null,
    });

    localStorage.removeItem('InfraConsoleToken')
    localStorage.removeItem('InfraConsoleName')
    localStorage.removeItem('InfraConsoleExpired')
    localStorage.removeItem('InfraConsoleUserName')
  };

  //****************************************************** */

  const [urlWorkflowType, setUrlWorkflowType] = useState('')
  const [urlRequestId, setUrlRequestId] = useState('')

  useEffect(() => {
    //auto refresh
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const reqId = urlParams.get('reqId')
    const workflowType = urlParams.get('workflowType');

    if (workflowType !== null) {
      setUrlWorkflowType(workflowType)
    }

    if (reqId !== null) {
      setUrlRequestId(reqId)
    }

    if (performance.navigation.type === 1 && reqId === null) {

      history.push('/');
    }

  }, []);

  const generatePermissionReqObject = (obj) => {
    obj.createdOn = formatDateTime(obj.createdOn)
    obj.grantOn = formatDateTime(obj.grantOn)
    obj.revokeOn = formatDateTime(obj.revokeOn)
    return obj;
  }
  const formatDateTime = (date) => {
    return luxon.fromISO(date).toFormat('dd/MM/yyyy HH:mm')
  }

  const convertFromTick = (tick) => {


    const milliseconds = tick * 0.0001;
    const epoch = DateTime.fromObject({ year: 1, month: 1, day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
    return epoch.plus({ milliseconds });

  }

  const formatDateTimeFromTick = (tick) => {
    if (tick) {

      let date = convertFromTick(tick)
      return formatDateTime(date);
    }
    return "";

  }


  const getMyRequests = async () => {
    return instance.get(consts.requests.getMyRequests)
  }



  useEffect(() => {

    return history.listen(location => {
      var path = location.pathname.substring(1);
      if (history.action === 'PUSH') {

      }

      if (history.action === 'POP') {

      }
      if (path === '') {
        setPage({ name: 'home', route: path })
      }
      else {
        setPage({ name: path, route: path })
      }

    })
  }, [page])


  const handleNavigation = (name, route) => {

    toggleChatOnPageChange()
    setPage({ name: name, route: route })
    history.push('/' + route);
  }




  const computeServicesAndOpenModal = () => {
    setModalShow(true)

  }





  const refreshService = () => {
    var refresh = [...servicesCached]
    setServices(refresh);
  }

  const getInsights = async () => {

    let insightsArray  = []
    const now = DateTime.now();

    const startDateCurrentMonth = now.startOf('month').toISODate();
    const endDateCurrentMonth = now.toISODate(); // Today's date

    const startDate1 = now.minus({ months: 1 }).startOf('month').toISODate();
    const endDate1 = now.minus({ months: 1 }).endOf('month').toISODate();


    const startDate2 = now.minus({ months: 2 }).startOf('month').toISODate();
    const endDate2 = now.minus({ months: 2 }).endOf('month').toISODate();


    const startDate3 = now.minus({ months: 3 }).startOf('month').toISODate();
    const endDate3 = now.minus({ months: 3 }).endOf('month').toISODate();

    const startDate4 = now.minus({ months: 4 }).startOf('month').toISODate();
    const endDate4 = now.minus({ months: 4 }).endOf('month').toISODate();

    const startDate5 = now.minus({ months: 5 }).startOf('month').toISODate();
    const endDate5 = now.minus({ months: 5 }).endOf('month').toISODate();

 



    let response = await Promise.all([getInsightsWithRange(startDateCurrentMonth, endDateCurrentMonth),
      getInsightsWithRange(startDate1, endDate1),
    getInsightsWithRange(startDate2, endDate2),
    getInsightsWithRange(startDate3, endDate3),
    getInsightsWithRange(startDate4, endDate4),
    getInsightsWithRange(startDate5, endDate5)])

    const firstMonth = response[0];
    const secondMonth = response[1];
    const thirdMonth = response[2];
    const fourthMonth = response[3];
    const fifthMonth = response[4];
    const sixthMonth = response[5];


    if (firstMonth?.status === 200) {
      insightsArray.push({month:'current' , data:firstMonth.data})
    }
    if (secondMonth?.status === 200) {
      insightsArray.push({month:'currentMinusOne' , data:secondMonth.data})
    }
    if (thirdMonth?.status === 200) {
      insightsArray.push({month:'currentMinusTwo' , data:thirdMonth.data})
    }
    if (fourthMonth?.status === 200) {
      insightsArray.push({month:'currentMinusThree' , data:fourthMonth.data})
    }
    if (fifthMonth?.status === 200) {
      insightsArray.push({month:'currentMinusFour' , data:fifthMonth.data})
    }
    if (sixthMonth?.status === 200) {
      insightsArray.push({month:'currentMinusFive' , data:sixthMonth.data})
    }
    
    return insightsArray;
    


  }

  /*const getInsightsCurrentMonth = () =>{
    return instance.get(consts.requests.getInsights, {
      headers: {
        Accept: "application/json"
      },
    });
  }*/

  const getInsightsWithRange = async (startDate, endDate) => {


    const url = consts.requests.getInsightsWithRange.replace('{startDate}', startDate).replace('{endDate}', endDate);

    return instance.get(url, {
      headers: {
        Accept: "application/json"
      },
    });

  }

  const [listOfTeams, setListOfTeams] = useState([])

  const getTeams = async () => {
    return instance.get(consts.requests.getTeam, {
      headers: {
        Accept: "application/json"
      },
    })
  }

  const [listOfDepartments, setListOfDepartments] = useState([])
  const getDepartment = async () => {

    return instance.get(consts.requests.getDepartment, {
      headers: {
        Accept: "application/json"
      },
    });
  }

  const [listOfUsers, setListOfUsers] = useState([])

  const getUsers = async () => {
    return instance.get(consts.requests.getAdminUsers, {
      headers: {
        Accept: "application/json"
      },
    });
  }



  const getTags = async () => {
    return instance.get(consts.requests.getTags, {
      headers: {
        Accept: "application/json"
      },
    });

  }

  const getTelAvivUsers = async (query) => {
    const url = consts.requests.getTelAvivUsers.toString().replace('{query}', query);
    return instance.get(url);

  }

  const freeTextSearch = async (query, toggleExcludeValue) => {
    const url = consts.requests.freeTextSearch.toString().replace('{query}', query).replace('{isExcluded}', toggleExcludeValue);
    return instance.get(url);

  }

  const getRules = async () => {

    return instance.get(process.env.REACT_APP_RULE_BASE_URL + consts.requests.getRules.toString());
  }

  const getApmData = async (id) => {

    return instance.get(consts.requests.getApmData.toString() + id);
  }

  const getGroups = async () => {

    return instance.get(consts.requests.getGroups.toString(), {
      headers: {
        Accept: "application/json"
      },
    });
  }


  const getJsonFile = async (url) => {

    const data = url;
    /*return axios.post(consts.requests.proxy +'/' +data, `"${data}"`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })*/

    return await instance.post(consts.requests.proxy, `"${data}"`);
  }


  const updateIncludeInBackstage = async (serviceId) =>{
    let url = consts.requests.updateIncludeInBackstage.toString().replace('{serviceId}', serviceId)
    return await instance.post(url, {});
  }
  const updateExcludeInBackstage = async (serviceId) =>{
    let url = consts.requests.updateExcludeInBackstage.toString().replace('{serviceId}', serviceId)
    return await instance.post(url, {});
  }

  const createGroupName = (name, description) => {


    return instance.post(consts.requests.createGroupNameAndDesc.toString(), {
      groupName: name,
      description: description
    })



  }


  const deleteGroup = async (groupId) => {

    const url = consts.requests.deleteGroup.toString().replace('{groupId}', groupId);
    return instance.delete(url)

  }


  const updateServiceToGroup = async (groupId, serviceIds) => {

    const url = consts.requests.updateServiceToGroup.toString().replace('{groupId}', groupId);
    return instance.post(url, {
      serivceIds: serviceIds,
      overrideExistingList: true
    })

  }

  const getServiceBrachesInfo = async (id, branch) => {

    if (services) {

      var infraService = services.filter(s => s.id === id);
      if (infraService && infraService.length > 0) {
        const url = consts.requests.getProjectByServiceAndBranch.toString().replace('{serviceId}', infraService[0].id).replace('{branchName}', branch);


        instance.get(url).then((res) => {
          if (res?.status === 200) {

            setBranchDetails({ serviceId: infraService[0].id, branch: branch, data: res?.data })
          }
        }).catch(err => {
          // Handle error
          console.log(err);
        });

      }
    }

  }


  const getServicesByPackageAndVersion = async (packageName, packageVersion) => {

    var url = '';
    if (packageVersion) {
      url = consts.requests.getServicesByPackageAndVersion.toString().replace('{packageNameValue}', packageName).replace('{packageVersionValue}', packageVersion);
    }
    else {
      url = consts.requests.getServicesByPackageWithoutVersion.toString().replace('{packageNameValue}', packageName);
    }

    instance.get(url).then((res) => {
      if (res?.status === 200) {

        setServicesByPackageAndVersion(res?.data)

      }
    }).catch(err => {
      // Handle error
      console.log(err);
    });;

  }

  const getPackagesWithVersions = async () => {

    if (userToken) {
      const url = consts.requests.getPackagesWithVersions;

      instance.get(url).then((res) => {
        if (res?.status === 200) {

          if (res?.data.length > 0)
            setPackagesWithVersions(res?.data);

        }
      }).catch(err => {
        // Handle error
        console.log(err);
      });
    }
  }

  const getBuildDefinitions = async (id, branch) => {

    setBuildDefinitions([])
    const url = consts.requests.getBuildDefinitions.toString().replace('{serviceId}', id).replace('{branchName}', branch);

    instance.get(url).then((res) => {
      if (res?.status === 200) {

        if (res?.data.length > 0) {
          setBuildDefinitions(res?.data);
        }
        else {

          const ErrorMsg = () => (
            <div>
              <div>There are no builds for this service to display.</div>
            </div>
          )
          toast.warn(<ErrorMsg />)

        }
      }
    }).catch(err => {
      // Handle error
      console.log(err);
    });

  }


  const saveServiceDetails = async (serviceMetaData, id, service) => {

    const url = consts.requests.saveMetaData.toString().replace('{serviceId}', id);

    instance.put(url, serviceMetaData).then((res) => {
      if (res?.status === 200) {
        //update service meta data object - remove and push the new object
        _.remove(servicesMetaData, {
          serviceName: serviceMetaData.serviceName,
          repositoryName: serviceMetaData.repositoryName
        });

        servicesMetaData.push(serviceMetaData)
        setServicesMetaData([...servicesMetaData])


        service.serviceMetadata.links = serviceMetaData.links;
        service.serviceMetadata.serviceName = serviceMetaData.serviceName;
        service.serviceMetadata.manager = serviceMetaData.manager;
        service.serviceMetadata.userManagerId = serviceMetaData.userManagerId;
        service.serviceMetadata.teamId = serviceMetaData.teamId
        service.serviceMetadata.repositoryName = serviceMetaData.repositoryName;
        service.serviceMetadata.description = serviceMetaData.description;
        service.serviceMetadata.newRelicAppName = serviceMetaData.newRelicAppName;
        service.serviceMetadata.team = serviceMetaData.team;
        service.serviceMetadata.environments = serviceMetaData.environments;
        service.serviceMetadata.tags = serviceMetaData.tags;
        service.serviceMetadata.tier = serviceMetaData.tier;


        setModalEditServiceDetailsShow(false)


        const SuccessMsg = () => (
          <div>
            <div>Service information was saved successfully.</div>
          </div>
        )
        toast.success(<SuccessMsg />)
      }
    }).catch(err => {

      console.log(err);
    });


    setUpdateDetailsSuccess(true)

  }
  const getServicesWithInfraNugets = async (packageName) => {
    const url = consts.requests.getServicesWithInfraNugets.toString().replace('{packageName}', packageName);


    var response = await instance.get(url);

    setInfraNugetsServices(response)
  }
  const getAllServices = async () => {

    return instance.get(consts.requests.fetchServices, {
      headers: {
        Accept: "application/json"
      },
    });


  }
  const getMasterAndMainBranches = (services) => {



    var branches = [];
    services.forEach(service => {
      var tempBranches = [];
      var master = service.branches.filter(b => b.toLowerCase() === 'master');
      var main = service.branches.filter(b => b.toLowerCase() === 'main');

      if (master && master.length > 0) {
        tempBranches.push(master[0])
      }

      if (main && main.length > 0) {
        tempBranches.push(main[0])
      }

      var restBranches = service.branches.filter(b => b.toLowerCase() != 'master' && b.toLowerCase() != 'main');

      if (restBranches && restBranches.length > 0) {
        restBranches.forEach(b => {
          tempBranches.push(b);
        });
      }

      branches.push({ serviceId: service.id, branches: tempBranches })
    });



    setBranches(branches)

  }
  const getLogDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    var hour = today.getHours() + ":"
    var min = today.getMinutes() + ":"
    var sec = today.getSeconds();

    today = dd + '/' + mm + '/' + yyyy + ' ' + hour + min + sec;
    return today;
  }

  const groupPackagesInstance = () => {
    var flatPackagesRelationArr = [];
    _.forEach(services, (service) => {
      var serviceName = service.name

      _.forEach(service.projects, (project) => {
        var projectName = project.name

        _.forEach(project.packageStatuses, (packageStatus) => {
          var newItem = { serviceName: serviceName, projectName: projectName, packageStatusId: packageStatus.id }
          const regexp = new RegExp('^Takeaway.', 'i')

          if (regexp.test(packageStatus.id)) {
            flatPackagesRelationArr.push(newItem);
          }
        })
      })

    });
    setFlatPackagesRelation(flatPackagesRelationArr);
    return flatPackagesRelationArr;
  }




  const handleGroupByChange = (value) => {

    setGroupBy(value);
    if (value.toLowerCase() != "default" && value.toLowerCase() != "branch") {
      var grouped = _.groupBy(services, value);
      setGroupedServices(grouped);
    }
    if (value.toLowerCase() === "default") {
      setGroupBy(value);

    }

    if (value.toLowerCase() === "branch") {
      var grouped = _.groupBy(services, "branchName");
      setGroupedServices(grouped);
    }
  };

  const convertIsoDateToDate = (isoDate) => {
    var date = new Date(isoDate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var hour = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds()

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (min < 10) {
      min = '0' + min;
    }
    if (sec < 10) {
      sec = '0' + sec;
    }
    return year + '-' + month + '-' + dt + ' ' + hour + ':' + min + ':' + sec;
  }
  const sortingAtoZ = (arrayToSort) => {

    return _.orderBy(arrayToSort, [(item) => item.name.toLowerCase()], ['asc']);

  }

  const showLoader = () => {
    document.getElementById("loader").style.display = "block";
  }

  const hideLoader = () => {
    document.getElementById("loader").style.display = "none";
  }

  const writeConsole = (text) => {
    console.log(text)
  }


  return (
    <DataContext.Provider value={{
      writeConsole, title, setTitle, isLoading, setIsLoading, services, setServices, groupBy, setGroupBy, getTeams,
      groupedServices, setGroupedServices, setSearchText, searchText, frameworksFilterObject, setFrameworksFilterObject,
      modalDetailsShow, setModalDetailsShow, modalPermissionShow, setModalPermissionShow, isEditorUser, isAdminUser,
      selectedService, setSelectedService, convertIsoDateToDate, listOfTeams, showLoader, hideLoader, isNullOrEmpty,
      setProjectItem, projectItem, getAllServices, flatPackagesRelation, groupPackagesInstance, servicesTags, setServicesTags,
      setServicesTagsCache, servicesTagsCache, getSecrets,
      groupedPackages, setGroupedPackages, sideBarCollapsed, setSideBarCollapsed, computeServicesAndOpenModal, handleTeamNameById,
      modalShow, setModalShow, handleGroupByChange, modalServiceDetailsShow, setModalServiceDetailsShow, manageFilter, setManageFilter,
      branchWithoutMaster, setBranchWithoutMaster, modalEditServiceDetailsShow, setModalEditServiceDetailsShow, getTelAvivUsers,
      modalEditServiceMetaDataShow, setModalEditServiceMetaDataShow, infraNugetsServices, setInfraNugetsServices, getServicesWithInfraNugets,
      servicesMetaData, setServicesMetaData, saveServiceDetails, updateDetailsSuccess, servicesCached, setServicesCached, getSelfServiceSubscriptions, getKVNames,
      refreshService, modalBuildShow, setModalBuildShow, getServiceBrachesInfo, branches, branchDetails, getBuildDefinitions, listOfFrameworks,
      buildDefinitions, setBuildDefinitions, breadcrumbs, setBreadcrumbs, menuSelectedItem, setMenuSelectedItem, insights, setBranchDetails,
      mdEditRowTable, setMdEditRowTable, mdEditEnvTable, setMdEditEnvTable, mdEditTagsTable, setMdEditTagsTable, handleBreadCrumbs, viewMode, setViewMode, getPackagesWithVersions, packagesWithVersions,
      getServicesByPackageAndVersion, servicesByPackageAndVersion, currentPage, setCurrentPage, isUserAuth, setIsUserAuth, getApmData, freeTextSearch,
      login, setLogin, userAccount, setUserAccount, token, setToken, handleLogout, handleNavigation, page, setPage, resultsFound, setResultsFound,
      modalErrorShow, setModalErrorShow, isEditorUser, isAdminUser, createGroupName, groupServices, setGroupServices, getGroups, updateServiceToGroup, deleteGroup,
      groupServicesCached, setGroupServicesCached, scoreRules, setScoreRules, getRules, topScore, setTopScore, mdEditReleaseTable, setMdEditReleaseTable,
      listOfUsers, setListOfUsers, listOfDepartments, setListOfDepartments, listOfTeams, setListOfTeams, notification, setNotification, getSelfServiceBranchesForExsitingRepo,
      getDepartment, getUsers, getMyRequests, userPermissionRequests, setUserPermissionRequests, progressBarValue, setProgressBarValue, getSelfServiceRepositories,
      urlWorkflowType, setUrlWorkflowType, urlRequestId, setUrlRequestId, tlvUsers, setTLVUsers, getJsonFile, progressBarText, setProgressBarText, getSelfServiceEnvironments,
      generatePermissionReqObject, formatDateTime, convertFromTick, formatDateTimeFromTick, aiMessages, setAIMessages, htmlContent, setHtmlContent, progressBarShow, setProgressBarShow,
      modalNotificationShow, setModalNotificationShow, modalAlertShow, setModalAlertShow, connection, setConnection, requestQueue, handleTeamLeaderNameById,
      tabsAutoCICDValue, setTabsAutoCICDValue, requestCICDItems, setRequestCICDItems, getUserCICDRequests, requestCICDUnreadItems, setRequestCICDUnreadItems,
      pipelineState, setPipelineState, getApprovalManagerDropDown, sortingAtoZ, initDeperatmentManager, initSubDeperatmentManager, initTeams, initTiers,
      forceInit, setForceInit, doForceInit, getSolutions, getCSProjFiles, selfServiceCopilotJson,githubBranches,
       githubRepository, githubSolutionFile, githubProjectSettingsFile,updateIncludeInBackstage , updateExcludeInBackstage
    }}>



      {children}
    </DataContext.Provider>
  )

}

export default DataContext;
