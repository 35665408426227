import "./App.css";
import Home from "./Home/Home.js";
import { DataProvider } from "./Context/DataContext";
import { DataProviderHome } from "./Context/DataContextHome";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SideMenuBar from './Home/SideMenuBar';
import Logs from "./logs";
import Loader from "./Loader";
import ErrorModal from './Modals/ErrorModal'
import ServicePage from "./Pages/ServicePage";
import HomePage from "./Pages/HomePage";
import NotFound from "./Pages/NotFound";
import AboutUs from "./Pages/AboutUs";
import TreeNodes from "./Pages/TreeNodes";
import GroupServices from "./Pages/GroupServices";
import AdvancedSearch from "./Pages/AdvancedSearch";
import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useContext, useState, lazy, Suspense } from 'react';
import DataContext from './Context/DataContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from "react";
import { FaAngleUp } from 'react-icons/fa';
import Insights from "./Pages/Insights";
import InfraPackagesRelations from "./Pages/InfraPackagesRelations";
import NotAuthorized from "./Pages/NotAuthorized";
import ManageUsers from "./Pages/ManageUsers";
import DynamicPermission from "./Pages/DynamicPermission";
import Workflow from "./Pages/Workflow";
//import AutoCICD from "./Pages/AutoCICD";
import SCProgressBar from "./SCProgressBar";
import AlertModal from "./Modals/AlertModal";
import AutoCICD from "./Pages/AutoCICD";
import NotificationModal from "./Modals/NotificationModal";
import CronJobsMonitor from "./Pages/CronJobsMonitor";
import Analyzer from "./Pages/Analyzer";
import { DataProviderAnalyzer } from "./Context/DataContextAnalyzer";
import ServiceAnalyzer from "./Pages/ServiceAnalyzerPageOld.js";
import ServiceAnalyzerPage from "./Pages/ServiceAnalyzerPageOld.js";
import { DataProviderBackground } from "./Context/BackgroundDataContext";
import useLogin from "./Hooks/useLogin";
import Guard from "./Components/Guard";
import SelfServicePage from "./Pages/SelfService/SelfServicePage.js";
import { DataProviderConsoleAI } from "./Context/DataContextConsoleAI";
import ChatBotButton from "./Components/Copilot/ChatBotButton.js";
import { CSSTransition } from "react-transition-group";
import ChatComponent from "./Components/Copilot/ChatComponent.js";
import { DataContextChat } from "./Context/DataContextChat.js";




function App() {



  const bottomRef = useRef(null)


  // When the user scrolls down 20px from the top of the document, show the button
  // window.onscroll = () => { scrollFunction() };

  const scrollFunction = () => {

    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      bottomRef.current.style.display = "block";

    } else {
      bottomRef.current.style.display = "none";
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }



  const LazyAutoCICDComponent = lazy(() => import('./Pages/AutoCICD'));
  const LazyAnalyzerComponentOld = lazy(() => import('./Pages/ServiceAnalyzerPageOld.js'));
  const LazyAnalyzerComponent = lazy(() => import('./Pages/ServiceAnalyzerPage.js'));
  const LazyAnalyzerGroupComponent = lazy(() => import('./Pages/ServiceAnalyzerGroupPage'));

  const LazyConsoleAI = lazy(() => import('./Pages/ConsoleAI.js'));

  return (

    <React.Fragment>





      <Router>
        <Suspense fallback={<Loader />}>
          <div className="App">
            <div onClick={topFunction} id="goToTopBtn" ref={bottomRef} title="Go to top">
              <FaAngleUp />
            </div>

            <ToastContainer autoClose={2500} limit={1} />
            <ErrorModal />

            <Loader />


            <DataProvider>
              <DataProviderBackground>
                <DataProviderHome>
                  <DataProviderAnalyzer>

                    <NotificationModal />
                    <SCProgressBar />

                    {
                      localStorage.getItem("InfraConsoleToken") !== ""
                        && localStorage.getItem("InfraConsoleToken") !== null
                        && localStorage.getItem("InfraConsoleToken") !== undefined ?
                        (
                          <React.Fragment>
                            <ChatBotButton />
                            <div>
                              <ChatComponent />
                            </div>
                          </React.Fragment>

                        ) :
                        (
                          <span></span>
                        )
                    }

                    <Switch>

                      <Route exact path="/nodes">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">

                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <TreeNodes />
                          </div>
                        </div>

                      </Route>


                      <Route exact path="/consoleAi">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <DataProviderConsoleAI>
                              <LazyConsoleAI />
                            </DataProviderConsoleAI>

                          </div>
                        </div>

                      </Route>


                      <Route exact path="/service">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <ServicePage />
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/insights">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Insights />
                          </div>
                        </div>

                      </Route>


                      <Route exact path="/group-services">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Guard><GroupServices /></Guard>
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/manage-users">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Guard admin={true}><ManageUsers /></Guard>
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/dynamic-permission">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <DynamicPermission />
                          </div>
                        </div>

                      </Route>


                      <Route exact path="/auto-cicd" >

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <LazyAutoCICDComponent />
                          </div>
                        </div>

                      </Route>




                      <Route exact path="/cronJobs-monitor" >

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <CronJobsMonitor />
                          </div>
                        </div>

                      </Route>


                      <Route exact path="/analyzer-old" >

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <LazyAnalyzerComponentOld />
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/analyzer" >

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <LazyAnalyzerComponent />
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/analyzerGroup" >

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Guard admin={true}> <LazyAnalyzerGroupComponent /></Guard>
                          </div>
                        </div>

                      </Route>







                      <Route exact path="/home">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Home />
                          </div>
                        </div>
                      </Route>

                      <Route exact path="/workflow">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Workflow />
                          </div>
                        </div>
                      </Route>

                      <Route exact path="/infraRelations">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <InfraPackagesRelations />
                          </div>
                        </div>
                      </Route>


                      <Route exact path="/advanced">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <AdvancedSearch />
                          </div>
                        </div>
                      </Route>

                      <Route exact path="/error">

                        <div>
                          <ErrorModal />
                        </div>
                      </Route>

                      <Route exact path="/NotAuthorized">

                        <div>
                          <NotAuthorized />
                        </div>
                      </Route>

                      
                      <Route exact path="/">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <Insights />
                          </div>
                        </div>

                      </Route>

                      <Route exact path="/catalog">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <HomePage />
                          </div>
                        </div>
                      </Route>

                      <Route exact path="/selfservice">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <SelfServicePage />
                          </div>
                        </div>
                      </Route>

                      <Route exact path="/catalog:name">

                        <div className="flex-container">
                          <div name="flexLeftId" className="flex-left">
                            <SideMenuBar />
                          </div>
                          <div className="flex-right">
                            <HomePage />
                          </div>
                        </div>
                      </Route>



                      <Route path="*">
                        <NotFound />
                      </Route>


                    </Switch>

                  </DataProviderAnalyzer>
                </DataProviderHome>
              </DataProviderBackground>
            </DataProvider>
          </div>
        </Suspense>
      </Router>
    </React.Fragment>

  );
}

export default App;
