import SCBreadCrumbs from "../Components/SCBreadCrumbs";
import SCLine from "../Components/SCLine";
import SCHeader from "../Components/SCHeader";
import grid from '../Images/grid.svg';
import list from '../Images/list.svg';
import './SCTop.css';
import DataContext from '../Context/DataContext';
import { useContext , useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { FaLayerGroup } from 'react-icons/fa';




const SCTop = ({displayFirstLine = true, displaySecondLine = true, displayBreadcrumbs=true, displayFilter=true,totalAfterFilter}) => {
    const { setViewMode, viewMode, currentPage } = useContext(DataContext);
  
    const changeView = (view) => {
        setViewMode(view);
      }

   
    
   
    return (
        <>
             <div className="header-row" >
                <div>
                    <SCHeader />
                </div>
            </div>
       
        </>
    )
}

export default SCTop
