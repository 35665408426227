// new file called DogPicture.jsx
import React, { useEffect, useState } from 'react';
import './IncidentReportButtonWidget.css';
import HtmlContentWidget from './HtmlContentWidget';
import HTMLModal from '../../Modals/HTMLModal';

const IncidentReportButtonWidget = (props) => {
  const [imageUrl, setImageUrl] = useState('');
  
  const { message } = props;
  const [modalShow, setModalShow] = useState(false)

  const handleOpenModal = () =>{
    setModalShow(true)
  }

  const RenderWidget = () => {
    if(message?.dataForWidget && message.dataForWidget !== '')
    {
        
        return(
            <HtmlContentWidget customClass='incident-report-widget' click={handleOpenModal}
             html={'<span>Click here for the full report</span>   <span class=\'dp-hyperLink\'>Report</span>'} />
        )

    }

  }
 
  return (
    <div>
     <RenderWidget />
     <HTMLModal data={message.dataForWidget} modalShow={modalShow} setModalShow={setModalShow} />
    </div>
  );
};

export default IncidentReportButtonWidget;