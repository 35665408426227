import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './SCDatePicker.css';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { DateTime, DateTime as luxon } from "luxon";
import 'dayjs/locale/en-gb';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useState } from 'react';
import CalendarIcon from "@mui/icons-material/CalendarToday"; // Import the calendar icon from Material-UI




const SCDatePicker = ({ id = null, handleChange, defaultValue, setValue, value, title, disabled = false, disablePast = false,
    autoOk = false, minDate = {}, maxDate = {}, testid = null }) => {


    const [keyDown, setKeyDown] = useState(false)



    const handleFromChange = (newValue) => {

        if (!newValue) {
            return;
        }
        if (newValue.c && !keyDown) {
            let inputDate = new Date(newValue);
            setValue(inputDate.toISOString());

        }
        if (handleChange) {
            handleChange(newValue);
        }


    };

    useEffect(() => {
        setKeyDown(false)
    }, [value])

    useEffect(() => {

        if (value && value?.ts) {

            const dateTime = DateTime.fromMillis(value.ts);
            const year = dateTime.year;
            const month = dateTime.month;
            const day = dateTime.day;
            const hour = dateTime.hour;
            const minute = dateTime.minute;
            const luxonDateTime = DateTime.local(year, month, day, hour, minute);

            let inputDate = new Date(luxonDateTime);


            setValue(inputDate.toISOString())


        }


    }, [])



    const handleBlur = (e) => {
        const inputDateString = e.target.value;

        // Split the input string and rearrange it into "MM/DD/YYYY HH:mm" format
        const parts = inputDateString.split(" ");
        const dateParts = parts[0].split("/");
        const rearrangedDateString = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]} ${parts[1]}`;

        // Create a Date object from the rearranged date string
        const dateObject = new Date(rearrangedDateString);
        if (isNaN(dateObject.getTime())) {
            return;
        }
        setValue(dateObject.toISOString())
    }

    return (

        <div>
            <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en-gb">

                <DateTimePicker
                    inputProps={{ id: id, testid: testid }}                    
                    disabled={disabled}
                    disablePast={disablePast}
                    autoOk={autoOk}
                    label={title}
                    views={['year', 'day', 'hours', 'minutes', 'seconds']}
                    value={DateTime.fromISO(value)}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(newValue) => {

                        handleFromChange(newValue);
                    }}

                    renderInput={(props) => <TextField

                        {...props} onBlur={(e) => handleBlur(e)} onKeyDown={(e) => setKeyDown(true)} />}

                  
                />


            </LocalizationProvider>

        </div>



    )
}

export default SCDatePicker
