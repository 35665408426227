import DataContext from "../Context/DataContext";
import React, { useEffect, useState, useContext } from "react";
import './SCHeader.css';
import SearchServices from "./SearchServices";
import takeaway from '../Images/takeaway.png';
import tele from '../Images/chart-relation.svg';
import ic from '../Images/icnewlogo2.png';




const SCHeader = () => {
    const { } = useContext(DataContext);
    const buildVersion = process.env.REACT_APP_BUILD_VERSION;

    return (
        <>
            <div className="header-container">

                <div class="header-cell header-left">
                    <div className="ic-header-inner-img">
                         <img src={ic} alt="icon" className="sc-icon" />                       
                    </div>
                    <div className="ic-header-inner-text">Developer Portal</div>
                </div>
                <div class="header-cell header-center">

                    <SearchServices />

                </div>
                <div class="header-cell header-right"></div>
                {/* <div class="header-cell header-right">   <img className="takeaway" src={takeaway} alt="icon" title={buildVersion} /></div>
*/}



            </div>
        </>
    )
}

export default SCHeader
