import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzer.css';
import _ from "lodash";
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { DateTime, DateTime as luxon } from "luxon";
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import SCDatePicker from '../SCDatePicker';
import SCButton from '../SCButton';
import SCTable from '../SCTable';
import ServiceAnalyzerTable from './ServiceAnalyzerTable';
import { toast } from 'react-toastify';
import SCContainer from '../SCContainer';
import { useHistory } from 'react-router-dom';
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';









const ServiceAnalyzerSearch = ({options, handleAutoCompleteOnChangeClick, jobWorking, getOptionLabel, inputValue, label, 
    handleAutoCompleteAutoChange,optionsCached , autoCompleteId = null}) => {




    

   
    return (
        <React.Fragment>
     
        <SCContainer children={

            <Autocomplete
                freeSolo
                id={autoCompleteId === null ? "search-services" : autoCompleteId}
                disableClearable
                options={options}
                onChange={(e, value) => handleAutoCompleteOnChangeClick(e, value)}
                disabled={jobWorking ? true : false}
                getOptionLabel={getOptionLabel}                                        
                filterOptions={(options) => options}
                inputValue={inputValue}
                renderInput={(params) => (
                    <TextField
                        className='service-analyzer-auto-services'
                        {...params}
                        label={label}
                        InputProps={{
                            ...params.InputProps,
                            type: "search"
                        }}
                        onChange={(event) =>
                            handleAutoCompleteAutoChange(event.target.value)
                        }
                      
                    />
                )}


            />
        } loading={optionsCached && optionsCached?.length === 0} />

        </React.Fragment>
    )
}

export default ServiceAnalyzerSearch