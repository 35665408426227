
import React, { useEffect, useState, useContext } from "react";
import './DPGrayButton.css';


const DPGrayButton = ({ text, handleButtonClick, customClass = "", id = "", disable = false }) => {


    return (
        <React.Fragment>
            {
                !disable ?
                    (
                        <div id={id} className={customClass + " dp-gray-button"} onClick={handleButtonClick}>
                            <span className='dp-gray-button-text' >{text}</span>
                        </div>
                    ) :
                    (
                        <div id={id} className={customClass + " dp-gray-diable-button"} onClick={handleButtonClick}>
                            <span className='dp-gray-disable-button-text' >{text}</span>
                        </div>
                    )
            }
        </React.Fragment>


    )
}

export default DPGrayButton
