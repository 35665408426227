import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './SelfServiceModalStepper.css';
import DataContext from '../../Context/DataContext';
import DPCheckbox from '../../Controllers/DPCheckbox';

const steps = ['Select self service flow', 'Select platform', 'Summary'];

const SelfServiceModalStepper = ({ setRepoDecision, repoDecision, setDecision, decision, setCloudDecision, cloudDecision,
    setModalShow, typeDecision, setTypeDecision, setMigrationType, migrationType }) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const { isAdminUser } = React.useContext(DataContext)

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const handleTypeDecision = (type) => {
        setTypeDecision(type)

    }
    const handleRepoDecision = (type) => {
        setRepoDecision(type)
    }
    const handleDecision = (type) => {
        setDecision(type)

    }
    const handleCloudDecision = (type) => {

        setCloudDecision(type)
    }

    const handleMigrationDecision = (type) => {

        setMigrationType(type)
        setDecision(type)
    }

    const handleSubmit = () => {
        setModalShow(false)

    }

    return (
        <Box sx={{
            width: '100%',
            '& .MuiBox-root': {
                float: 'right !important'
            }

        }}>
            <Stepper activeStep={activeStep} alternativeLabel
                sx={{
                    '& .MuiSwitch-thumb': {
                        color: '#8C999B !important'

                    },

                    '& .Mui-active': {
                        color: 'black !important'
                    },
                    '& .Mui-completed': {
                        color: 'black !important'
                    },

                }}
            >
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                handleSubmit()
            ) : (
                <div className='ss-stepper-outer-container'>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                        {
                            activeStep === 0 ?
                                (
                                    <div >
                                        <div className="self-service-stapper-instruction">What would you like to do?</div>

                                        {
                                            typeDecision === 'new' ?
                                                (
                                                    <div className='ss-modal-stepper-first-step-container'>
                                                        <span className='ss-modal-stepper-first-step-span'><DPCheckbox onClick={() => handleTypeDecision('new')} text="Create New Project" id="self-service-btn-new-project" checked={true} /></span>
                                                        <span><DPCheckbox onClick={() => handleTypeDecision('migrate')} text="Service Migration" id="self-service-btn-service-migration" checked={false} /></span>
                                                    </div>
                                                ) :
                                                (
                                                    <div className='ss-modal-stepper-first-step-container'>
                                                        <span className='ss-modal-stepper-first-step-span'><DPCheckbox onClick={() => handleTypeDecision('new')} text="Create New Project" id="self-service-btn-new-project" checked={false} /></span>
                                                        <span><DPCheckbox onClick={() => handleTypeDecision('migrate')} text="Service Migration" id="self-service-btn-service-migration" checked={true} /></span>
                                                    </div>
                                                )
                                        }

                                    </div>
                                ) : activeStep === 1 ?
                                    (
                                        typeDecision === 'new' ?
                                            (
                                                <div>
                                                    {
                                                        isAdminUser &&
                                                        (
                                                            <React.Fragment>
                                                                <div className="self-service-stapper-instruction">Source code repository platform</div>
                                                                {
                                                                    cloudDecision === 'azure' ?
                                                                        (
                                                                            <div className='ss-modal-stepper-first-step-container'>
                                                                                <span className='ss-modal-stepper-first-step-span'>
                                                                                    <DPCheckbox onClick={() => handleCloudDecision('azure')} text="Azure DevOps" id="self-service-btn-new-project" checked={true} />
                                                                                </span>
                                                                                <span><DPCheckbox onClick={() => handleCloudDecision('github')} text="GitHub" id="self-service-btn-service-migration" checked={false} /></span>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div className='ss-modal-stepper-first-step-container'>
                                                                                <span className='ss-modal-stepper-first-step-span'><DPCheckbox onClick={() => handleCloudDecision('azure')} text="Azure DevOps" id="self-service-btn-new-project" checked={false} /></span>
                                                                                <span><DPCheckbox onClick={() => handleCloudDecision('github')} text="GitHub" id="self-service-btn-service-migration" checked={true} /></span>
                                                                            </div>
                                                                        )
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    }

                                                    <div className="self-service-stapper-instruction self-service-stapper-instruction-margin-top">On which platform would you like the service to be created</div>
                                                    {
                                                        decision === 'aks' ?
                                                            (
                                                                <div className='ss-modal-stepper-first-step-container'>
                                                                    <span className='ss-modal-stepper-first-step-span'>
                                                                        <DPCheckbox onClick={() => handleDecision('aks')} id='self-service-btn-aks' text="AKS" checked={true} />
                                                                    </span>
                                                                    <span className='ss-modal-stepper-first-step-span'>
                                                                        <DPCheckbox onClick={() => handleDecision('eks')} id='self-service-btn-eks' text="EKS" checked={false} />
                                                                    </span>
                                                                    <span>
                                                                        <DPCheckbox onClick={() => handleDecision('both')} id='self-service-btn-both' text="Both" checked={false} />
                                                                    </span>
                                                                </div>
                                                            ) : decision === 'eks' ?
                                                                (
                                                                    <div className='ss-modal-stepper-first-step-container '>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleDecision('aks')} id='self-service-btn-aks' text="AKS" checked={false} />
                                                                        </span>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleDecision('eks')} id='self-service-btn-eks' text="EKS" checked={true} />
                                                                        </span>
                                                                        <span>
                                                                            <DPCheckbox onClick={() => handleDecision('both')} id='self-service-btn-both' text="Both" checked={false} />
                                                                        </span>
                                                                    </div>
                                                                ) :
                                                                (
                                                                    <div className='ss-modal-stepper-first-step-container'>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleDecision('aks')} id='self-service-btn-aks' text="AKS" checked={false} />
                                                                        </span>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleDecision('eks')} id='self-service-btn-eks' text="EKS" checked={false} />
                                                                        </span>
                                                                        <span>
                                                                            <DPCheckbox onClick={() => handleDecision('both')} id='self-service-btn-both' text="Both" checked={true} />
                                                                        </span>
                                                                    </div>
                                                                )

                                                    }


                                                    <div className="self-service-stapper-instruction self-service-stapper-instruction-margin-top">Do you want to create a new repository or using existing repository?</div>
                                                    {
                                                        repoDecision === 'new' ?
                                                            (
                                                                <div className='ss-modal-stepper-first-step-container'>
                                                                    <span className='ss-modal-stepper-first-step-span'>
                                                                        <DPCheckbox onClick={() => handleRepoDecision('new')} id='self-service-btn-nr' text="New Repository" checked={true} />
                                                                    </span>
                                                                    <span>
                                                                        <DPCheckbox onClick={() => handleRepoDecision('existing')} id='self-service-btn-er' text="Existing Repository" checked={false} />
                                                                    </span>
                                                                </div>
                                                            ) :
                                                            (
                                                                <div className='ss-modal-stepper-first-step-container'>
                                                                    <span className='ss-modal-stepper-first-step-span'>
                                                                        <DPCheckbox onClick={() => handleRepoDecision('new')} id='self-service-btn-nr' text="New Repository" checked={false} />
                                                                    </span>
                                                                    <span>
                                                                        <DPCheckbox onClick={() => handleRepoDecision('existing')} id='self-service-btn-er' text="Existing Repository" checked={true} />
                                                                    </span>
                                                                </div>
                                                            )
                                                    }


                                                </div>
                                            ) :
                                            (
                                                <div>
                                                    {
                                                        isAdminUser &&
                                                        (
                                                            <React.Fragment>
                                                                <div className="self-service-stapper-instruction self-service-stapper-instruction-margin-top">Source code repository platform</div>
                                                                {
                                                                    cloudDecision === 'azure' ?
                                                                        (
                                                                            <div className='ss-modal-stepper-first-step-container'>
                                                                                <span className='ss-modal-stepper-first-step-span'>
                                                                                    <DPCheckbox onClick={() => handleCloudDecision('azure')} id='self-service-btn-azure' text="Azure Devops" checked={true} />
                                                                                </span>
                                                                                <span>
                                                                                    <DPCheckbox onClick={() => handleCloudDecision('github')} id='self-service-btn-github' text="GitHub" checked={false} />
                                                                                </span>
                                                                            </div>
                                                                        ) :
                                                                        (
                                                                            <div className='ss-modal-stepper-first-step-container'>
                                                                                <span className='ss-modal-stepper-first-step-span'>
                                                                                    <DPCheckbox onClick={() => handleCloudDecision('azure')} id='self-service-btn-azure' text="Azure Devops" checked={false} />
                                                                                </span>
                                                                                <span>
                                                                                    <DPCheckbox onClick={() => handleCloudDecision('github')} id='self-service-btn-github' text="GitHub" checked={true} />
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                }

                                                            </React.Fragment>
                                                        )
                                                    }
                                                    <div>
                                                        <div className="self-service-stapper-instruction self-service-stapper-instruction-margin-top">Into which platform would you like to migrate the service</div>
                                                        {
                                                            migrationType === 'aks' ?
                                                                (
                                                                    <div className='ss-modal-stepper-first-step-container'>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleMigrationDecision('aks')} id='self-service-btn-migrationAks' text="AKS" checked={true} />
                                                                        </span>
                                                                        <span>
                                                                            <DPCheckbox onClick={() => handleMigrationDecision('eks')} id='self-service-btn-migrationEks' text="EKS" checked={false} />
                                                                        </span>
                                                                    </div>
                                                                ) :
                                                                (
                                                                    <div className='ss-modal-stepper-first-step-container'>
                                                                        <span className='ss-modal-stepper-first-step-span'>
                                                                            <DPCheckbox onClick={() => handleMigrationDecision('aks')} id='self-service-btn-migrationAks' text="AKS" checked={false} />
                                                                        </span>
                                                                        <span>
                                                                            <DPCheckbox onClick={() => handleMigrationDecision('eks')} id='self-service-btn-migrationEks' text="EKS" checked={true} />
                                                                        </span>
                                                                    </div>
                                                                )
                                                        }

                                                    </div>
                                                </div>

                                            )

                                    ) :
                                    (
                                        <ul className='self-service-modal-stepper-ul'>
                                            {
                                                isAdminUser && cloudDecision === 'azure' &&
                                                (
                                                    <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Repository platform - Azure </li>
                                                )
                                            }
                                            {
                                                isAdminUser && cloudDecision !== 'azure' &&
                                                (
                                                    <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Repository platform - GitHub </li>
                                                )
                                            }
                                            {
                                                typeDecision === 'new' ?
                                                    (
                                                        <li className='self-service-modal-stepper-li self-service-stapper-instruction' >Create new project </li>
                                                    ) :
                                                    (
                                                        <React.Fragment>
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Service migration</li>
                                                            {
                                                                migrationType === 'aks' ?
                                                                    (
                                                                        <li className='self-service-modal-stepper-li self-service-stapper-instruction'>AKS</li>
                                                                    ) :
                                                                    (
                                                                        <li className='self-service-modal-stepper-li self-service-stapper-instruction'>EKS</li>
                                                                    )
                                                            }
                                                        </React.Fragment>
                                                    )
                                            }
                                            {
                                                typeDecision === 'new' &&
                                                (
                                                    decision === 'aks' ?
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform AKS</li>
                                                        ) : decision === 'eks' ?
                                                            (
                                                                <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform EKS</li>
                                                            ) :
                                                            (
                                                                <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Platform AKS & EKS</li>
                                                            )
                                                )

                                            }
                                            {
                                                typeDecision === 'new' &&
                                                (
                                                    repoDecision === 'new' ?
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Create new repository</li>
                                                        ) :
                                                        (
                                                            <li className='self-service-modal-stepper-li self-service-stapper-instruction'>Edit existing repository</li>
                                                        )
                                                )

                                            }
                                        </ul>

                                    )

                        }

                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            sx={{
                                mr: 1 ,
                                '&:hover': {
                                    borderRadius: '20px', // Change border radius  
                                },
                                
                            }}
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        
                            id='self-service-btn-back'
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        <Button
                            sx={{
                                backgroundColor: '#F36805', // Change background color
                                color: 'white', // Change text color
                                '&:hover': {
                                    backgroundColor: '#F36805', // Change background on hover
                                },
                                borderRadius: '20px', // Change border radius
                            }}
                            id={activeStep === steps.length - 1 ? 'self-service-btn-finish' : 'self-service-btn-next'} onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
                </div>
            )}
        </Box>
    );
}
export default SelfServiceModalStepper