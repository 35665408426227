
import DataContext from '../Context/DataContext';
import React from 'react';
import { useContext, useEffect, useState, useMemo } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarHeader } from 'react-pro-sidebar';

import { FaCodeBranch, FaLayerGroup, FaStickyNote, FaListOl, FaHome, FaSignInAlt, FaSignOutAlt, FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import _ from "lodash";
import './SideMenuBar.css';
import { useHistory } from 'react-router-dom';
import home from '../Images/newNavIcons/home.svg';
import nodes from '../Images/nodes.png';
import vector from '../Images/newNavIcons/sort.svg';
import group from '../Images/group.png';
import logs from '../Images/logs.png';
import login from '../Images/login.png';
import logout from '../Images/newNavIcons/log-out.svg';
import grid from '../Images/grid.png';
import list from '../Images/list.png';
import user from '../Images/newNavIcons/user-circle.svg';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import dynamic from '../Images/newNavIcons/key.svg';
import autocicd from '../Images/newNavIcons/settings.svg';
import cronjob from '../Images/newNavIcons/dashboard.svg';
import analyzer from '../Images/newNavIcons/chart-increase.svg';
import search from '../Images/newNavIcons/search.svg';
import { FaGlobeAmericas } from 'react-icons/fa';
import PublicIcon from '@mui/icons-material/Public';
import groups from '../Images/groups.png';
import SCCheckBox from '../Components/SCCheckBox';
import { Checkbox, Tooltip } from '@mui/material';
import SCNotification from '../Components/SCNotification';
import bell from '../Images/newNavIcons/notification.svg';
import { FaBars, FaUser } from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';




const SideMenuBar = () => {
  const { sideBarCollapsed, setSideBarCollapsed, isEditorUser, isAdminUser, menuSelectedItem, manageFilter, setManageFilter, services, setServices, servicesCached,
    setBreadcrumbs, breadcrumbs, token, handleBreadCrumbs, setModalNotificationShow, setViewMode, userAccount, handleLogout, handleNavigation, page, setPage } = useContext(DataContext);
  const history = useHistory();


  const handleClick = () => {

    //  refreshService();
  }


  useEffect(() => {

    setPage({ name: 'home', route: '' })

  }, [userAccount])


  const openWebSite = (site) => {
    switch (site) {


      case 'WIKI':
        {
          window.open(process.env.REACT_APP_WIKI, '_blank')
          break;
        }
      case 'PACK':
        {
          window.open(process.env.REACT_APP_PACK, '_blank')
          break;
        }
      case 'SQ':
        {
          window.open(process.env.REACT_APP_SQ, '_blank')
          break;
        }
      case 'NR':
        {
          window.open(process.env.REACT_APP_NR, '_blank')
          break;
        }

      default:
        return 'center'
    }
  }

  useEffect(() => {
    if (Object.entries(servicesCached).length > 0) {

      let filterElements = manageFilter.filter(m => m.type === 'checked')

      let filterServices = [].concat(servicesCached).filter((item) => {//serviceType

        for (var key in filterElements) {
          if (item.serviceType === filterElements[key].name)
            return true;
        }
        return false;
      });

      if (filterElements.length === 0) {
        filterServices = [].concat(servicesCached);
      }

      setServices([...filterServices])
    }
  }, [manageFilter])

  const handleCheckBoxClick = (type) => {
    let item = manageFilter.filter(x => x.name === type)[0];
    if (item.type === 'checked') {
      item.element = <FaRegSquare />
      item.type = 'unchecked'
    }
    else {
      item.element = <FaRegCheckSquare />
      item.type = 'checked'
    }

    setManageFilter([...manageFilter]);
  }

  const openNotification = () => {
    setModalNotificationShow(true)

  }
  const [collapsed, setCollapsed] = useState(false);

  const handleToggleSidebar = () => {


    setCollapsed(!collapsed);


    const sidebars = document.getElementsByName('flexLeftId');
    if (sidebars.length > 0) {
      const sidebar = sidebars[0]; // Access the first (and only) element
      sidebar.classList.toggle('collapsed');
    }
  };


  return (

    <div className="side-container" >

      <Sidebar collapsed={collapsed}>

        {
          !collapsed ?
            (
              <div className='sidenav-exp-col-btn' onClick={handleToggleSidebar} style={{ cursor: 'pointer' }}>
                <FaBars />
              </div>
            ) :
            (
              <div className='sidenav-exp-col-btn-close' onClick={handleToggleSidebar} style={{ cursor: 'pointer' }}>
                <CloseIcon />
              </div>
            )

        }


        <Menu iconShape="square" >

          <MenuItem className={page?.name?.toLowerCase() === 'home' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} 
          onClick={() => handleNavigation('home', '')} 
          icon={
            <Tooltip  title={collapsed && "Home"}>
          <img className='menu-icon' src={home} alt="home" />
          </Tooltip>
          } suffix={!collapsed && ""}>Home</MenuItem>

          <MenuItem className='menu-title' disabled>{collapsed ? "Apps" : "Applications"}</MenuItem>

          <MenuItem className={page?.name?.toLowerCase() === 'catalog' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => handleNavigation('catalog', 'Catalog')}
            icon={
              <Tooltip title={collapsed && "Service Catalog"}>
                <img className='menu-icon' src={vector} alt="vector" />
              </Tooltip>
            } suffix={!collapsed && ""}>Service Catalog</MenuItem>

          <MenuItem className={page?.name?.toLowerCase() === 'advanced' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => handleNavigation('advanced', 'Advanced')}
            icon={
              <Tooltip title={collapsed && "Search View"}>
                <img className='menu-icon' src={search} alt="search" />
              </Tooltip>
            } suffix={!collapsed && ""}>Search View</MenuItem>

          <MenuItem className={page?.name?.toLowerCase() === 'manage-users' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
            onClick={() => handleNavigation('manageUsers', 'manage-users')} style={{ display: isAdminUser ? 'flex' : 'none' }}
            icon={
              <Tooltip title={collapsed && "Admin"}>
                <SupervisorAccountIcon />
              </Tooltip>

            } suffix={!collapsed && ""}>Admin</MenuItem>


          <MenuItem className={page?.name?.toLowerCase() === 'dynamic-permission' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
            onClick={() => handleNavigation('dynamicPermission', 'dynamic-permission')}
            icon={
              <Tooltip title={collapsed && "Dynamic Permission"}>
                <img className='menu-icon dynamic-icon' src={dynamic} alt="users" />
              </Tooltip>

            } suffix={!collapsed && ""}>Permission</MenuItem>

          <MenuItem className={page?.name?.toLowerCase() === 'auto-cicd' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
            onClick={() => handleNavigation('autoCicd', 'auto-cicd')}
            icon={
              <Tooltip title={collapsed && "Self Service"}>
                <img className='menu-icon autocicd-icon' src={autocicd} alt="users" />
              </Tooltip>

            } suffix={!collapsed && ""}>DevOps Self Service</MenuItem>


          <MenuItem className={page?.name?.toLowerCase() === 'cronjobs-monitor' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
            onClick={() => handleNavigation('CronJobsMonitor', 'cronJobs-monitor')}
            icon={
              <Tooltip title={collapsed && "CronJobs Monitor"}>
                <img className='menu-icon cronJobs-monitor-icon' src={cronjob} alt="CronJobsMonitor" />
              </Tooltip>

            } suffix={!collapsed && ""}>CronJobs Monitor</MenuItem>

          <MenuItem className={page?.name?.toLowerCase() === 'analyzer' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
            onClick={() => handleNavigation('Analyzer', 'analyzer')}
            icon={
              <Tooltip title={collapsed && "Analyzer"}>
                <img className='menu-icon analyzer-icon' src={analyzer} alt="Analyzer" />
              </Tooltip>

            } suffix={!collapsed && ""}>Analyzer</MenuItem>


          <MenuItem className='menu-title' disabled>{collapsed ? "Tools" : "Tools"}</MenuItem>


          <MenuItem className={`unselected-menu-item  ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => openWebSite('NR')}
            icon={
              <Tooltip title={collapsed && "New Relic"}>
                <PublicIcon />
              </Tooltip>
            } suffix={!collapsed && ""}>NewRelic</MenuItem>

          <MenuItem className={`unselected-menu-item  ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => openWebSite('SQ')}
            icon={
              <Tooltip title={collapsed && "SonarQube"}>
                <PublicIcon />
              </Tooltip>} suffix={!collapsed && ""}>SonarQube</MenuItem>

          <MenuItem className='menu-title' disabled>{collapsed ? "Links" : "Links"}</MenuItem>


          <MenuItem className={`unselected-menu-item  ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => openWebSite('WIKI')}
            icon={<Tooltip title={collapsed && "Infra Wiki"}>
              <PublicIcon />
            </Tooltip>} suffix={!collapsed && ""}>Infra Wiki</MenuItem>


          <MenuItem className='menu-title' disabled>{collapsed ? "General" : "General"}</MenuItem>

          <MenuItem className={`unselected-menu-item  ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} onClick={() => openNotification()}
            icon={
              <Tooltip title={collapsed && "Notification"}>
                <img className='menu-icon notification-icon' src={bell} alt="notification" />
              </Tooltip>
            } suffix={!collapsed && ""}> <SCNotification /></MenuItem>

          {
            !userAccount ?
              (
                <MenuItem className={page?.name?.toLowerCase() === 'login' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
                  onClick={() => handleNavigation('login', 'login')}
                  icon={
                    <Tooltip title={collapsed && "Login"}>
                      <img className='menu-icon' src={login} alt="login" />
                    </Tooltip>
                  } suffix={!collapsed && ""}>{!collapsed ? "Log in" : ""}</MenuItem>
              ) :
              (
                <MenuItem className={menuSelectedItem?.toLowerCase() === 'logout' ? `selected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}` : `unselected-menu-item ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`}
                  onClick={() => handleLogout()}
                  icon={
                    <Tooltip title={collapsed && "Logout"}>
                      <img className='menu-icon' src={logout} alt="logout" />
                    </Tooltip>
                  } suffix={!collapsed && ""}> {!collapsed ? "Log out" : ""} </MenuItem>
              )
          }





          <MenuItem className={`unselected-menu-item no-cursor  ${collapsed ? 'menu-item-collapsed' : 'menu-item-expand'}`} style={{ display: userAccount ? 'inline-flex' : 'none' }}
            icon={
              <Tooltip title={userAccount && collapsed ? userAccount.user : ''}>
                <img className='menu-icon' src={user} alt="user" />
              </Tooltip>
            } suffix={!collapsed && ""}> {userAccount && !collapsed ? userAccount.user : ''}</MenuItem>


        </Menu>



      </Sidebar>
    </div>
  )
}

export default SideMenuBar
