
import React from "react";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useEffect, useState } from "react";
import './NotificationModal.css';
import { useHistory, useLocation } from 'react-router-dom'
import * as consts from '../consts';
import cicdAxios from '../cicdAxios';
import useAxios from "../Hooks/useAxios";
import DPGrayButton from "../Controllers/DPGrayButton";



const NotificationModal = () => {

    

    const instance  = useAxios(process.env.REACT_APP_CICD_BASE_URL);


    const { setModalNotificationShow, notification, modalNotificationShow, token, requestCICDItems, setRequestCICDItems } =
        useContext(DataContext);

    const [unreadNotifications, setUnreadNotifications] = useState([])

    useEffect(() => {
        if (modalNotificationShow) {
            let unreadArray = requestCICDItems.data.filter(i => i.readByServiceCatalog === 'unread' && (i.approvalStatus?.toLowerCase() === 'approved' || i.approvalStatus?.toLowerCase() === 'declined'));
            setUnreadNotifications(unreadArray)
        }
    }, [requestCICDItems, modalNotificationShow])


    useEffect(() => {


     let notificationIds = []
        unreadNotifications.map((id) => {
            notificationIds.push(id.partitionKey)
        })

        if (notificationIds.length > 0) {
            instance.post(consts.requests.updateUserReadNotifications, {
                 userRequestIds: notificationIds,
                 read: true
             }, {
                 headers: {
                     Authorization: `Bearer ${token}`
                 },
             }).then((response) => {
 
             }).catch((error) => {
 
             }).finally(() => {
 
             })

        }
     

    }, [unreadNotifications])

    const RenderNotificationHtml = () => {
        if (unreadNotifications.length === 0) {
            return (
                <div>Notifications are empty</div>
            )
        }
        return (
            <div>

                {

                    Object.entries(unreadNotifications).map(([key, value], index) => (
                        <div key={key}>
                            {
                                value.processStatus?.toLowerCase() === 'failed' || value.approvalStatus?.toLowerCase() === 'declined' ?
                                    (
                                        <div>
                                            <div><span>Repository Name:</span><span className="notification-modal-title"> {value.repoName}</span></div>
                                            <div><span>Application Name:</span><span className="notification-modal-title"> {value.appName}</span></div>                                      
                                            <div><span>Approval Ticket Number:</span><span className="notification-modal-title"> {value.approvalTicketNumber}</span></div>
                                            <div><span>Process Status</span><span className="notification-modal-title-red"> {value.processStatus}</span></div>
                                            <div><span>Approval Status</span><span className="notification-modal-title-red"> {value.approvalStatus}</span></div>
                                            <hr></hr>

                                        </div>
                                    ) :
                                    (
                                        <div>
                                            <div><span>Repository Name:</span><span className="notification-modal-title"> {value.repoName}</span></div>
                                            <div><span>Application Name:</span><span className="notification-modal-title"> {value.appName}</span></div>
                                            <div><span>Repository Url:</span><span className="notification-modal-title-no-bold">{value.repoUrl}</span></div>                                            
                                            <div><span className="notification-modal-title-underline">Pipeline Url:</span><span className="notification-modal-title-html"> {value.buildPipelineUrl}</span></div>
                                            <div><span className="notification-modal-title-underline">Release Pipeline Url:</span><span className="notification-modal-title-html"> {value.releasePipelineUrl}</span></div>                                           
                                            <div><span>Approval Ticket Number:</span><span className="notification-modal-title"> {value.approvalTicketNumber}</span></div>
                                            <div><span>Process Status:</span><span className="notification-modal-title-green"> {value.processStatus}</span></div>
                                            <div><span>Approval Status</span><span className="notification-modal-title-green"> {value.approvalStatus}</span></div>
                                            <div><span>DevOps Project</span><span className="notification-modal-title-green"> {value.azureDevOpsProject}</span></div>
                                            <div><span>DB Creation Status</span><span className="notification-modal-title-green"> {value.dataBaseCreationStatus}</span></div>
                                            <div><span>DB KeyVault Key</span><span className="notification-modal-title-green"> {value.dataBaseKeyVaultKey}</span></div>
                                            <div><span>DB KeyVault Name</span><span className="notification-modal-title-green"> {value.dataBaseKeyVaultName}</span></div>
                                            <hr></hr>
                                        </div>
                                    )
                            }
                        </div>
                    ))


                }
            </div>
        )
    }

    const handleNotificationModealClose = () => {

        setModalNotificationShow(false)

        const newRequestCICDItems = requestCICDItems.data.map(item => {
            if (item.readByServiceCatalog === "unread") {
                return { ...item, readByServiceCatalog: "read" };
            }
            return { ...item };
        });

        setRequestCICDItems({status:-1,data:newRequestCICDItems})

    }

    return (


        <Modal
            fullscreen={false}
            show={modalNotificationShow}
            onHide={() => handleNotificationModealClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ maxHeight: window.innerHeight * 0.9 }}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    NOTIFICATION
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="notification-modal-container">
                <div>
                    <RenderNotificationHtml />
                </div>
            </Modal.Body>
            <Modal.Footer>                
                <DPGrayButton  text="Close" handleButtonClick={() => handleNotificationModealClose()} />
            </Modal.Footer>
        </Modal>

    );
};

export default NotificationModal;
