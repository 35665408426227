import React, { useEffect, useState, useContext } from 'react'
import './ServiceAnalyzerGroup.css';
import _ from "lodash";
import DataContext from '../../Context/DataContext';
import DataContextAnalyzer from '../../Context/DataContextAnalyzer';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ServiceAnalyzerCreateApplicationForm from './ServiceAnalyzerCreateApplicationForm';
import YesNoModal from '../../Modals/YesNoModal';



const ServiceAnalyzerGroup = () => {

    const history = useHistory();
    const { servicesCached } = useContext(DataContext);
    const { getApplicationGroups, deleteApplicationGroups, applications, setApplications, createAnalyzerGroup, updateAnalyzerGroup, buildServices } = useContext(DataContextAnalyzer);
    const [options, setOptions] = useState([])
    const [optionsCached, setOptionsCached] = useState([])
    const [selectedServices, setSelectedServices] = useState([])
    const [applicationName, setApplicationName] = useState('')
    const [applicationDescription, setApplicationDescription] = useState('')
    const [slackNotification, setSlackNotification] = useState(true)

    const [ddlCron, setDdlCron] = useState([])
    const [selectedCronPattern, setSelectedCronPattern] = useState('')

    const [ddlIncident, setDdlIncident] = useState([])
    const [incidentProbability, setIncidentProbability] = useState('')

    const [newRelicEventTitle, setNewRelicEventTitle] = useState('')
    const [value, setValue] = useState("0");
    const [selectedGroup, setSelectedGroup] = useState({})

    const [yesNoResult, setYesNoResult] = useState(false)
    const [modalAlertShow, setModalAlertShow] = useState(false)


    useEffect(() => {
        buildFilteredServices();
        initDdls();
    }, [])


    const initDdls = () => {
        /*var cron = [];
        for (let index = 1; index <= 10; index++) {
            cron.push({ name: index, value: index })
        }
        setDdlCron(cron)*/
        var schedulerCronPattern = [];
        schedulerCronPattern.push({ name: '15 Mins', value: '15' }, { name: '30 Min', value: '30' }, { name: '1 Hour', value: '60' })
        setDdlCron(schedulerCronPattern);

        var incident = [];
        incident.push({ name: 'High', value: 'High' }, { name: 'Medium', value: 'Medium' }, { name: 'Low', value: 'Low' })
        setDdlIncident(incident)
    }



    const buildFilteredServices = () => {

        if (servicesCached && servicesCached.length > 0) {
            var services = []

            let filterServicesCached = servicesCached?.filter(s =>
                (!s.serviceType.toLowerCase().startsWith('aks') &&
                    !s.serviceType.toLowerCase().startsWith('eks') &&
                    isNotNullUndefinedOrEmpty(s.serviceMetadata?.newRelicAppName) &&
                    isNotNullUndefinedOrEmpty(s.portalUrl)
                ) || (s.serviceType.toLowerCase().startsWith('aks') || s.serviceType.toLowerCase().startsWith('eks')))//portal url is for the AppServiceResourceId

            filterServicesCached.map((p) => {

                if (p.serviceType.toLowerCase().startsWith('aks') || p.serviceType.toLowerCase().startsWith('eks')) {
                    if (p.serviceType.toLowerCase().startsWith('eks')) {
                        services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: "", clusterName: p?.eksInfo?.clusterName })
                    }
                    else {
                        services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: "", clusterName: p?.aksInfo?.clusterName })
                    }

                }
                else {
                    services.push({ serviceId: p.id, project: p.azureDevopsProjectName, subscriptionId: p.subscriptionId, resourceGroup: p.resourceGroup, id: p.azureDevopsReleaseDefinitionId, type: p.serviceType, name: p.name, newRelicName: p.serviceMetadata.newRelicAppName, resourceId: p.portalUrl, team: p?.serviceMetadata?.team })
                }

            })
            setOptions(services)
            setOptionsCached(services)
        }


    }
    const isNotNullUndefinedOrEmpty = (str) => {
        return str !== null && str !== undefined && str.trim() !== '';
    }




    const handleRequestDelete = async (e) => {

        try {
            let response = await deleteApplicationGroups(applications[e].id)

            if (response.status === 200) {
                let removeApplication = [...applications];
                _.remove(removeApplication, {
                    id: applications[e].id
                });
                setApplications([...removeApplication])
                showToast("The application was deleted successfully.");
            }


        } catch (error) {
            console.log(error)
            showErrorToast("Something went wrong.");
        }
    }

    useEffect(() => {

        if (Object.entries(selectedGroup).length === 0 || modalAlertShow) {
            return;
        }

        if (!yesNoResult) {

            //services:
            let servicesArr = [];
            selectedGroup.serviceIds.map((service) => {

                let current = servicesCached.filter(x => x.id === service);

                if (current && current.length > 0) {
                    let selectedUserService = {
                        serviceId: current[0].id,
                        name: current[0].name,
                        project: current[0].azureDevopsProjectName,
                        type: current[0].serviceType,
                        team: current[0]?.serviceMetadata?.team

                    }
                    servicesArr.push(selectedUserService);
                }

            })
            //end services

            setSelectedServices([...servicesArr])

            setApplicationName(selectedGroup.name)

            setApplicationDescription(selectedGroup.description)

            setSelectedCronPattern(selectedGroup.schedulerCronPattern)

            setIncidentProbability(selectedGroup.incidentProbability)

            setNewRelicEventTitle(selectedGroup.incidentNewRelicEventTitle)

            setSlackNotification(selectedGroup.isSlackChannelActive)

        }
        else {
            handleSaveChanges();
        }

    }, [yesNoResult, selectedGroup, modalAlertShow])


    const handleApplicationSelect = (e) => {


        if (Object.entries(selectedGroup).length > 0) {
            getAppDiff(applications[e])
        }
        else {
            setSelectedGroup(applications[e])
        }
    }



    const redirectToAnalyzer = () => {
        history.push('/analyzer');
    }




    const handleChange = async (event, newValue) => {
        resetState();
        if (newValue === "1") {

            try {
                let apps = await getApplicationGroups(servicesCached)
            } catch (error) {
                console.log(error)
                showErrorToast("Something went wrong.");
            }

        }
        setValue(newValue);
    };


    const handleCreateApplication = async () => {
        try {
            let servicesIDs = selectedServices.map(obj => obj.serviceId);


            let result = await createAnalyzerGroup(
                applicationName, applicationDescription, servicesIDs,
                '15', incidentProbability, newRelicEventTitle, slackNotification
            )
            if (result.status === 200) {
                showToast("The application was created successfully.");
            }


        } catch (error) {

            console.log(error)
        }
    }

    const showToast = (text) => {
        const SuccessMsg = () => (
            <div>
                <div>{text}</div>
            </div>
        )
        toast.success(<SuccessMsg />)
    }

    const showErrorToast = (text) => {
        const SuccessMsg = () => (
            <div>
                <div>{text}</div>
            </div>
        )
        toast.error(<SuccessMsg />)
    }

    const handleSaveChanges = async () => {
        try {
            let servicesIDs = selectedServices.map(obj => obj.serviceId);

            let result = await updateAnalyzerGroup(selectedGroup.id,
                applicationName, applicationDescription, servicesIDs,
                '15', incidentProbability, newRelicEventTitle, slackNotification
            )

            if (result.status === 200) {
                let updatedItem = applications.filter(x => x.id === selectedGroup.id)[0];

                updatedItem.id = selectedGroup.id;
                updatedItem.name = applicationName;
                updatedItem.description = applicationDescription;
                updatedItem.servicesName = buildServices(servicesCached, servicesIDs);
                updatedItem.serviceIds = servicesIDs;
                updatedItem.schedulerCronPattern = selectedCronPattern;
                updatedItem.incidentProbability = incidentProbability;
                updatedItem.incidentNewRelicEventTitle = newRelicEventTitle;
                updatedItem.isSlackChannelActive = slackNotification

                setApplications([...applications])
                resetState();

                showToast("The application was updated successfully.");

            }


        } catch (error) {
            console.log(error)
            showErrorToast("Something went wrong.");
        }
    }

    const resetState = () => {
        setSelectedGroup({})
        setApplicationName('')
        setApplicationDescription('')
        setSelectedCronPattern('')
        setIncidentProbability('')
        setNewRelicEventTitle('')
        setSelectedServices([])
        setYesNoResult(false)
    }

    const getAppDiff = (currentGroup) => {
        let updatedItem = applications.filter(x => x.id === selectedGroup.id)[0];
        console.log(updatedItem)
        if (updatedItem.name !== applicationName || updatedItem.description !== applicationDescription
            || updatedItem.incidentNewRelicEventTitle !== newRelicEventTitle ||
            updatedItem.incidentProbability !== incidentProbability || updatedItem.schedulerCronPattern !== selectedCronPattern) {
            setModalAlertShow(true);
        }
        else {
            console.log(currentGroup)
            setSelectedGroup(currentGroup)
        }

    }

    return (
        <React.Fragment>
            {/*<div className='analyzer-group-div custom-link-button ' onClick={() => redirectToAnalyzer()}>Back to analyzer page</div>*/}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example"

                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#F36805 !important', // Change this to your desired color
                                },
                                '& .MuiTab-root': {
                                    color: 'black !important', // Color for unselected tabs
                                },
                                '& .Mui-disabled': {
                                    color: 'lightgray !important'
                                }
                            }}
                        >
                            <Tab label="Create Application" value="0" />
                            <Tab label="View All Applications" value="1" />
                        </TabList>
                    </Box>

                    <TabPanel value="0" >

                        <ServiceAnalyzerCreateApplicationForm buttonText="Create" handleSubmit={() => handleCreateApplication()}
                            ddlCron={ddlCron} setDdlCron={setDdlCron} selectedCronPattern={selectedCronPattern} setSelectedCronPattern={setSelectedCronPattern}
                            ddlIncident={ddlIncident} setDdlIncident={setDdlIncident} incidentProbability={incidentProbability} setIncidentProbability={setIncidentProbability}
                            newRelicEventTitle={newRelicEventTitle} setNewRelicEventTitle={setNewRelicEventTitle} optionsCached={optionsCached} setOptionsCached={setOptionsCached}
                            selectedServices={selectedServices} setSelectedServices={setSelectedServices} options={options} setOptions={setOptions}
                            applicationDescription={applicationDescription} setApplicationDescription={setApplicationDescription} applicationName={applicationName}
                            setApplicationName={setApplicationName} slackNotification={slackNotification} setSlackNotification={setSlackNotification}
                        />

                    </TabPanel>
                    <TabPanel value="1" >
                        <div className='analyzer-group-div'>

                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='analyzer-group-cell-hr'>ACTIONS</TableCell>
                                            <TableCell className='analyzer-group-cell-hr'>NAME</TableCell>
                                            <TableCell className='analyzer-group-cell-hr'>DESCRIPTION</TableCell>
                                            <TableCell className='analyzer-group-cell-hr'>SERVICES</TableCell>
                                            {/*<TableCell className='analyzer-group-cell-hr'>SCHEDULER CRON PATTERN</TableCell>*/}
                                            <TableCell className='analyzer-group-cell-hr'>INCIDENT PROBABILITY</TableCell>
                                            <TableCell className='analyzer-group-cell-hr'>INCIDENT NEWRELIC EVENT TITLE</TableCell>
                                            <TableCell className='analyzer-group-cell-hr'>SLACK NOTIFICATION</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {

                                            applications.map((value, innerIndex) => (
                                                <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                                    <TableCell ><span onClick={() => handleApplicationSelect(innerIndex)} className='analyzer-group-link-button'>Select</span>
                                                        <span className='analyzer-group-saperator'>|</span><span onClick={() => handleRequestDelete(innerIndex)} className='analyzer-group-link-button'>Delete</span></TableCell>
                                                    <TableCell>{value.name}</TableCell>
                                                    <TableCell>{value.description}</TableCell>
                                                    <TableCell>{value.servicesName}</TableCell>
                                                    {/*<TableCell>{value.schedulerCronPattern}</TableCell>*/}
                                                    <TableCell>{value.incidentProbability}</TableCell>
                                                    <TableCell>{value.incidentNewRelicEventTitle}</TableCell>
                                                    <TableCell>{
                                                        value.isSlackChannelActive === true ? 'ON' : 'OFF'
                                                    }</TableCell>
                                                </TableRow>
                                            ))

                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                        <div className='analyzer-group-edit-title'>Edit group</div>
                        <div>
                            <ServiceAnalyzerCreateApplicationForm buttonText="Save Changes" handleSubmit={() => handleSaveChanges()}
                                ddlCron={ddlCron} setDdlCron={setDdlCron} selectedCronPattern={selectedCronPattern} setSelectedCronPattern={setSelectedCronPattern}
                                ddlIncident={ddlIncident} setDdlIncident={setDdlIncident} incidentProbability={incidentProbability} setIncidentProbability={setIncidentProbability}
                                newRelicEventTitle={newRelicEventTitle} setNewRelicEventTitle={setNewRelicEventTitle} optionsCached={optionsCached} setOptionsCached={setOptionsCached}
                                selectedServices={selectedServices} setSelectedServices={setSelectedServices} options={options} setOptions={setOptions}
                                applicationDescription={applicationDescription} setApplicationDescription={setApplicationDescription} applicationName={applicationName}
                                setApplicationName={setApplicationName} setSlackNotification={setSlackNotification} slackNotification={slackNotification}
                            />
                        </div>

                    </TabPanel>


                </TabContext>
            </Box>

            <YesNoModal setYesNoResult={setYesNoResult} modalAlertShow={modalAlertShow} setModalAlertShow={setModalAlertShow} />


        </React.Fragment>
    )
}

export default ServiceAnalyzerGroup