import React, { useEffect, useRef, useState, useContext, useCallback, lazy, Suspense, useLayoutEffect } from 'react'
import SCTop from "../Components/SCTop";
import './CronJobsMonitor.css';
import SCDropDownList from '../Components/SCDropDownList';
import * as consts from '../consts';
import DataContextHome from "../Context/DataContextHome";
import DataContext from "../Context/DataContext";
import _ from "lodash";
import SCButton from "../Components/SCButton";
import { toast } from 'react-toastify';
import SCTable from '../Components/SCTable';
import { DateTime, DateTime as luxon } from "luxon";
import play from '../Images/play.svg';
import pause from '../Images/pause.svg';
import refresh from '../Images/refresh.svg';
import Tooltip from '@mui/material/Tooltip';
import cronstrue from 'cronstrue';
import useAxios from '../Hooks/useAxios';
import DPOrangeButton from '../Controllers/DPOrangeButton';




const CronJobsMonitor = () => {


    var parser = require('cron-parser');

    const { generateUserText } = useContext(DataContextHome);
    const { token,setCurrentPage } = useContext(DataContext);

    const [ddlCluster, setDdlCluster] = useState([])
    const [ddlJob, setDdlJob] = useState([])

    const [selectedCluster, setSelectedCluster] = useState('')
    const [jobs, setJobs] = useState([])
    const [selectedJob, setSelectedJob] = useState([])
    const [cronJobsItems, setCronJobsItems] = useState([])


    const [cronJobsFailedItems, setCronJobsFailedItems] = useState([])
    const [cronJobsSucceededItems, setCronJobsSucceededItems] = useState([])

    const [totalFailed, setTotalFailed] = useState('')
    const [totalSucceeded, setTotalSucceeded] = useState('')


    const columnsItems = [

        { display: true, name: "name", key: "name", width: "10%" },
        { display: true, name: "namespace", key: "namespace", width: "10%" },
        { display: true, name: "cron expression", key: "cronExpression", width: "10%" },
        { display: true, name: "cron description", key: "cronDescription", width: "15%" },
        { display: true, name: "suspend", key: "suspend", width: "5%" },
        { display: true, name: "created on", key: "createdOn", width: "10%" },
        { display: true, name: "concurrency policy", key: "concurrencyPolicy", width: "10%" },
        { display: true, name: "next run", key: "nextRun", width: "10%" },
        { display: true, name: "last schedule time", key: "lastScheduleTime", width: "10%" },
        { display: true, name: "last successful time", key: "lastSuccessfulTime", width: "10%" }

    ]


    const columnsFailedItems = [

        { display: true, name: "name", key: "name", width: "10%" },
        { display: true, name: "namespace", key: "namespace", width: "10%" },
        { display: true, name: "creation Timestamp", key: "creationTimestamp", width: "10%" },
        { display: true, name: "start Time", key: "startTime", width: "10%" }


    ]

    const columnsSucceededItems = [

        { display: true, name: "name", key: "name", width: "10%" },
        { display: true, name: "namespace", key: "namespace", width: "10%" },
        { display: true, name: "creation Timestamp", key: "creationTimestamp", width: "10%" },
        { display: true, name: "start Time", key: "startTime", width: "10%" },
        { display: true, name: "completion Time", key: "completionTime", width: "10%" }


    ]

    const instanceInfraDev = useAxios('https://infrastructure-k8s-dev-infra.internal.tenbis.cloud')
    const instanceInfraProd = useAxios('https://infrastructure-k8s-infra.internal.tenbis.cloud')
    const takeawayDev = useAxios('https://infrastructure-k8s-dev-pay.internal.tenbis.cloud')
    const takeawayProd = useAxios('https://infrastructure-k8s-pay.internal.tenbis.cloud')



    useEffect(() => {

             
         let clusters = [{ name: 'Infrastructure Dev', value: 'https://infrastructure-k8s-dev-infra.internal.tenbis.cloud' },
        { name: 'Infrastructure Prod', value: 'https://infrastructure-k8s-infra.internal.tenbis.cloud' },
        { name: 'Takeaway Dev', value: 'https://infrastructure-k8s-dev-pay.internal.tenbis.cloud' },
        { name: 'Takeaway Prod', value: 'https://infrastructure-k8s-pay.internal.tenbis.cloud' }]

        var resource = [];
        clusters.map((c) => {
            resource.push({
                name: c.name,
                value: c.value
            })
        })

        setDdlCluster(resource)
        setCurrentPage('cronjobs-monitor')
       

    }, [])



    useEffect(() => {

        let jobsInCluster = [];
        if (jobs && jobs.length > 0) {
            jobs.map((c) => {
                jobsInCluster.push({
                    name: c.name,
                    value: c.namespace
                })
            })

            setDdlJob(jobsInCluster)
        }

    }, [jobs])

    useEffect(async () => {
        await executeHistoryAndOverviewLogic();


    }, [selectedJob])

    const executeHistoryAndOverviewLogic = async () => {
        if (selectedJob && selectedJob.length > 0) {
            buildCronJobOverview()
            let response = await getJobHistory(selectedJob);

            if (response) {
                buildCronJobHistory(response.data.data);
            }
            else {
                setCronJobsFailedItems([]);
                setCronJobsSucceededItems([]);
                setTotalFailed('')
                setTotalSucceeded('')

            }



        }
    }

    const buildCronJobHistory = (history) => {

        Object.entries(history).forEach(([key, value]) => {
            if (key === 'failed') {
                setTotalFailed(value.length)

                let items = []
                value.map((v) => {
                    items.push({
                        name: v.name,
                        namespace: v.namespace,
                        creationTimestamp: getDateTimeFromISO(v.creationTimestamp),
                        startTime: getDateTimeFromISO(v.startTime)
                    })
                })
                setCronJobsFailedItems(items);
            }
            else {
                setTotalSucceeded(value.length)

                let items = []
                value.map((v) => {
                    items.push({
                        name: v.name,
                        namespace: v.namespace,
                        creationTimestamp: getDateTimeFromISO(v.creationTimestamp),
                        startTime: getDateTimeFromISO(v.startTime),
                        completionTime: getDateTimeFromISO(v.completionTime)
                    })
                })
                setCronJobsSucceededItems(items);

            }

        });

    }

    const getDateTimeFromISO = (isoString) => {
        try {
            const dateTime = DateTime.fromISO(isoString);
            return dateTime.toFormat('yyyy-MM-dd HH:mm:ss');
        } catch (error) {
            console.log('Error from getDateTimeFromISO', error)
            return ""
        }
    }


    const buildCronJobOverview = () => {


        let current = jobs.filter(j => j.namespace === selectedJob);

        if (current && current.length > 0) {


            setCronJobsItems([{
                name: current[0].name,
                namespace: current[0].namespace,
                cronExpression: current[0].cronExpression,
                cronDescription: cronstrue.toString(current[0].cronExpression),
                suspend: current[0].suspend.toString(),
                createdOn: getDateTimeFromISO(current[0].createdAt),
                concurrencyPolicy: current[0].concurrencyPolicy,
                nextRun: getDateTimeFromISO(current[0].nextRun),
                lastScheduleTime: getDateTimeFromISO(current[0].lastScheduleTime),
                lastSuccessfulTime: getDateTimeFromISO(current[0].lastSuccessfulTime),
            }])

        }

    }

    const getJobHistory = async (namespace) => {

        try {

            return getAxios().get('/api/v1/Jobs?Namespace=' + namespace)
        } catch (error) {

        }
    }



    
    const getAxios = () => {
      
        switch (selectedCluster) {
            case 'https://infrastructure-k8s-dev-infra.internal.tenbis.cloud':
              return instanceInfraDev;
              case 'https://infrastructure-k8s-infra.internal.tenbis.cloud':
              return instanceInfraProd;
              case 'https://infrastructure-k8s-dev-pay.internal.tenbis.cloud':
              return takeawayDev;
              case 'https://infrastructure-k8s-pay.internal.tenbis.cloud':
              return takeawayProd;
        
            default:
                break;
        }
        

    }
//taltal
    useEffect(async () => {

        if (token && selectedCluster !== '') {
          try {
            let response = await getCronJobs()            
            if(response)
            {
                buildCronJobObject(response?.data?.data?.cronJobs)
            }
            else{
                const ErrorMsg = () => (
                    <div>We didn't get any data from the cronjob API</div>
                )
                toast.error(<ErrorMsg />)
            }
          } catch (error) {
            const ErrorMsg = () => (
                <div>We didn't get any data from the cronjob API</div>
            )
            toast.error(<ErrorMsg />)
          }
            
        }

    }, [selectedCluster])

    const getCronJobs = async () => {

      

        try {
            return getAxios().get('/api/v1/CronJobs')
        } catch (error) {
            //setError(error);
        }


    }


    const buildCronJobObject = (jobs) => {
        let cronJobs = [];

        jobs.map((job) => {
            cronJobs.push({

                name: job.name,
                nextRun: job.nextRun,
                lastScheduleTime: job.lastScheduleTime,
                lastSuccessfulTime: job.lastSuccessfulTime,
                createdAt: job.createdAt,
                namespace: job.namespace,
                cronExpression: job.cronExpression, // "*/1 * * * *"
                concurrencyPolicy: job.concurrencyPolicy,
                timeZone: job.timeZone,
                failedJobsHistoryLimit: job.failedJobsHistoryLimit,
                successfulJobsHistoryLimit: job.successfulJobsHistoryLimit,
                suspend: job.suspend,
                backoffLimit: job.backoffLimit,
                completions: job.completions,
                parallelism: job.parallelism

            })

        })
        setJobs(cronJobs);

    }


    const handleClusterChanged = (e) => {
        
        setSelectedCluster(e.target.value)
    }

    const handleJobChanged = (e) => {
        setSelectedJob(e.target.value)
    }

    const runTrigger = async () => {
        let current = jobs.filter(j => j.namespace === selectedJob);

        try {
            return getAxios().post('/api/v1/CronJobs/Trigger', {
                name: current[0].name,
                namespace: current[0].namespace
            })
        } catch (error) {
            //setError(error);
        }
    }

    const handleTriggerJob = async () => {

        let result = await runTrigger();
        if (result.status === 200) {
            const SuccessMsg = () => (
                <div>The job has been started ({result.data.data.name})</div>
            )
            toast.success(<SuccessMsg />)
        }
       

    }

    const handleRefresh = async () => {
        await executeHistoryAndOverviewLogic()
    }


    const handleSuspendJob = async () => {

        try {
            let response = await getAxios().post('/api/v1/CronJobs/Toggle', {
                name: cronJobsItems[0].name,
                suspend: true,
                namespace: cronJobsItems[0].namespace,
            })


            if (response.data.responseType === "OK") {
                let tempArray = [...cronJobsItems];
                tempArray[0].suspend = "true";
                setCronJobsItems(tempArray);
            }
        }
        catch (error) {

        }

    }

    const handleStartJobAgain = async () => {

        try {
            let response = await getAxios().post('/api/v1/CronJobs/Toggle', {
                name: cronJobsItems[0].name,
                suspend: false,
                namespace: cronJobsItems[0].namespace,
            })

            if (response.data.responseType === "OK") {
                let tempArray = [...cronJobsItems];
                tempArray[0].suspend = "false";
                setCronJobsItems(tempArray);
            }
        }
        catch (error) {

        }


    }

    return (
        <div>
            <div className="sc-header">
                <SCTop displaySecondLine={false} displayFilter={false} />
            </div>
            <div className="sc-main cron-job-monitor-container">
                <SCDropDownList className="md-ddl-edit" label="Select Cluster" componentState={selectedCluster} setComponentState={setSelectedCluster}
                    size="1000" items={ddlCluster} handleChange={(e) => handleClusterChanged(e)} value={selectedCluster} />
                <div className='cronjob-flex-container'>
                    <SCDropDownList className="md-ddl-edit" label="Select Job" componentState={selectedJob} setComponentState={setSelectedJob}
                        size="1000" items={ddlJob} handleChange={(e) => handleJobChanged(e)} value={selectedJob} />
                </div>

                <div className='cronjob-monitor-btn'>
                    <DPOrangeButton  text="Run Job Manually" handleButtonClick={handleTriggerJob} />
                    <span className='button-cron-job-container'>
                      
                        <Tooltip title="Refresh CronJob Failed and Succeeded list" placement="top">
                            <div onClick={handleRefresh}><img src={refresh} alt='icon' className='type-refresh' /></div>
                        </Tooltip>
                    </span>
                    {
                        cronJobsItems && cronJobsItems.length > 0 ?
                            (
                                cronJobsItems[0].suspend === "true" ?
                                    (
                                        <Tooltip title="This cronjob is suspend. click here to start the job again." placement="top">
                                            <div onClick={handleStartJobAgain}><img src={play} alt='icon' className='type-play-pause' /></div>
                                        </Tooltip>
                                    ) :
                                    (
                                        <Tooltip title="This cronjob is not suspend. click here to suspend the job." placement="top">
                                            <div onClick={handleSuspendJob}><img src={pause} alt='icon' className='type-play-pause' /></div>
                                        </Tooltip>
                                    )

                            ) :
                            (
                                <div></div>
                            )
                    }



                </div>

                <div className='cronjob-monitor-table'>
                    <SCTable customColumnWidth="true" title="CronJob Overview" columnsItems={columnsItems}
                        rowItems={cronJobsItems} />
                </div>

                <div className='cronjob-monitor-table'>
                    <SCTable customColumnWidth="true" title={`CronJob - Failed (${totalFailed})`} columnsItems={columnsFailedItems}
                        rowItems={cronJobsFailedItems} />
                </div>

                <div className='cronjob-monitor-table'>
                    <SCTable customColumnWidth="true" title={`CronJob - Succeeded (${totalSucceeded})`} columnsItems={columnsSucceededItems}
                        rowItems={cronJobsSucceededItems} />
                </div>

            </div>
        </div>
    )
}

export default CronJobsMonitor