import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import DataContextAnalyzer from '../../../Context/DataContextAnalyzer';

const SARenderLogs = ({ elements }) => {

    const { SubstringStringForToolTip, handleCopyToClipboard } = useContext(DataContextAnalyzer);

    const RenderLogs = () => {

        if (!elements || !elements.length === 0) {
            return (
                <React.Fragment>There is no error logs</React.Fragment>
            )
        }

      
        let logsCountElements = elements.filter(e => e.code === 'error_count');
        console.log("logsCountElements", logsCountElements)
      

        if ((!logsCountElements || logsCountElements.length === 0)) {
            return (
                <span></span>
            )
        }

        return (
            <React.Fragment>


                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '14%' }}>Error Class</TableCell>
                                <TableCell sx={{ width: '14%' }}>Message</TableCell>
                                <TableCell sx={{ width: '14%' }}>Error Stack</TableCell>
                                <TableCell sx={{ width: '14%' }}>Exception</TableCell>
                                <TableCell sx={{ width: '14%' }}>Logger</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                logsCountElements[0]?.data?.errorLogs.map((log, index) => (
                                    <TableRow className='anomaly-alert-table-row' key={`tr-element` + index}>
                                        {
                                            log["error.class"] ?
                                                (
                                                    <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>{log["error.class"]}</TableCell>
                                                ) :
                                                (
                                                    <TableCell sx={{ width: '14%' }}></TableCell>
                                                )
                                        }
                                        {
                                            log.message ?
                                                (
                                                    <Tooltip title={log.message}>
                                                        <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                            <div>{SubstringStringForToolTip(log.message)}</div>
                                                            <div className="report-infra-modal-copy" onClick={() => handleCopyToClipboard(log.message)}>Copy Text</div>
                                                        </TableCell>
                                                    </Tooltip>
                                                ) :
                                                (
                                                    <TableCell sx={{ width: '14%' }}></TableCell>
                                                )
                                        }
                                        {
                                            log["error.stack"] ?
                                                (
                                                    <Tooltip title={log["error.stack"]}>
                                                        <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                            <div>{SubstringStringForToolTip(log["error.stack"])}</div>
                                                            <div className="report-infra-modal-copy" onClick={() => handleCopyToClipboard(log["error.stack"])}>Copy Text</div>
                                                        </TableCell>
                                                    </Tooltip>
                                                ) :
                                                (
                                                    <TableCell sx={{ width: '14%' }}></TableCell>
                                                )
                                        }
                                        {
                                            log.exception ?
                                                (
                                                    <Tooltip title={log.exception}>
                                                        <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>
                                                            <div>{SubstringStringForToolTip(log.exception)}</div>
                                                            <div className="report-infra-modal-copy" onClick={() => handleCopyToClipboard(log.exception)}>Copy Text</div>
                                                        </TableCell>
                                                    </Tooltip>
                                                ) :
                                                (
                                                    <TableCell sx={{ width: '14%' }}></TableCell>
                                                )
                                        }
                                        {
                                            log.logger ?
                                                (
                                                    <TableCell sx={{ width: '14%', lineBreak: 'anywhere' }}>{log.logger}</TableCell>
                                                ) : log.source ?
                                                    (
                                                        <TableCell sx={{ width: '14%' }}>{log?.source}</TableCell>
                                                    ) :
                                                    (
                                                        <TableCell sx={{ width: '14%' }}></TableCell>
                                                    )
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment >
        )



    }

    return (
        <RenderLogs />
    )


}
export default SARenderLogs