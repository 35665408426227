
import { useEffect, useState, useContext } from "react";
import './DPOrangeButton.css';


const DPOrangeButton = ({text, handleButtonClick,  customClass = "", id=""}) => {


    return (
        <div id={id} className={customClass + " dp-button"} onClick={handleButtonClick}>
            <span className='dp-button-text' >{text}</span>
        </div>
    )
}

export default DPOrangeButton
