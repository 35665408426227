import React, { createContext, useState, useContext, useRef } from 'react';
import DataContext from "./DataContext";
import _ from "lodash";
import analyzerServiceHealthAxios from '../analyzerServiceHealthAxios';
import analyzerDeploymentChangesAxios from '../analyzerDeploymentChangesAxios';
import analyzerLogsAxios from '../analyzerLogsAxios';
import analyzerDBChangesAxios from '../analyzerDBChangesAxios';
import analyzerConfigurationChangesAxios from '../analyzerConfigurationChangesAxios';
import analyzerMicrosoftHealthAxios from '../analyzerMicrosoftHealthAxios';
import { DateTime, DateTime as luxon } from "luxon";
import * as consts from '../consts';
import useAxios from '../Hooks/useAxios';
import { toast } from 'react-toastify';



const DataContextAnalyzer = createContext({});

export const DataProviderAnalyzer = ({ children }) => {

    const instance = useAxios(process.env.REACT_APP_BASE_URL);
    const instanceAnalyzer = useAxios(process.env.REACT_APP_ANALYZER_BASE_URL);
    const instanceAnalyzerDump = useAxios(process.env.REACT_APP_ANALYZER_DUMP_BASE_URL)
    const instanceAnalyzerMetrics = useAxios(process.env.REACT_APP_ANALYZER_METRICS_BASE_URL)

    const [comments, setComments] = useState([])
    const { services, token, formatDateTimeFromTick } = useContext(DataContext);
    const [applications, setApplications] = useState([])
    const [counters, setCounters] = useState([])
    const [loadingStatus, setLoadingStatus] = useState("")
    const [dump, setDump] = useState([])
    const [loadingDumpStatus, setLoadingDumpStatus] = useState("")
    const [anomalyPredict, setAnomalyPredict] = useState({
        cpu: [],
        memory: [],
        logsCount: [],
        responseTime: []
    })

    const [modules, setModules] = useState({});
    const ddlValueRef = useRef('')


    const [issues, setIssues] = useState([])


    const translateTrigger = (trigger) => {
        switch (trigger) {
            case "Manual":
                return "Manual"
            case "ApplicationsJob":
                return "Applications Job"
            case "IncidentJob":
                return "Incident Job"


            default:
                break;
        }
    }

    const LuxonDateFormatString = (dateString, format = 'yyyy-MM-dd hh:mm:ss a') => {
        try {
            const serverDate = DateTime.fromISO(dateString);
            return serverDate.toFormat(format);
        } catch (error) {
            console.log(error)
            return dateString;
        }
    }

    const getMetrics = async (serviceId, fromDate, toDate, intervalInMin, metricType) => {
        if (!token) {
            return;
        }

        let url = consts.requests.getMetrics.replace('{serviceId}', serviceId).replace('{fromDate}', fromDate).replace('{toDate}', toDate)
            .replace('{intervalInMin}', intervalInMin).replace('{metricType}', metricType)

        return await instanceAnalyzerMetrics.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: true
        })
    }


    const predict = async (serviceId, from, to, intervalInMin, metricType, srCnnEntireThreshold, srCnnEntireBatchSize, srCnnEntireSensitivity) => {

        if (token) {

            let payload = {
                serviceId: serviceId,
                from: from,
                to: to,
                intervalInMin: intervalInMin,
                metricType: metricType,
                srCnnEntireThreshold: srCnnEntireThreshold,
                srCnnEntireBatchSize: srCnnEntireBatchSize,
                srCnnEntireSensitivity: srCnnEntireSensitivity

            };

            return await instanceAnalyzer.post(consts.requests.predict, payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: true
                })
        }

    }

    const getFeedback = async (services) => {
        if (token) {



            return await instanceAnalyzer.post(consts.requests.getFeedback, services,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: false
                })
        }
    }

    const updateFeedback = async (data) => {


        if (token) {


            let args = {
                "incidentId": data.incidentId,
                "userName": data.userName,
                "overall": { "score": data.feedback.overall.score },
                "metrics": { "cpuScore": data.feedback.metrics.cpuScore, "memoryScore": data.feedback.metrics.memoryScore, "responseTimeScore": data.feedback.metrics.responseTimeScore, "logCountScore": data.feedback.metrics.logCountScore },
                "anomalyDetection": { "score": data.feedback.anomalyDetection.score },
                "riskLevel": { "score": data.feedback.riskLevel.score },
                "rootCause": { "score": data.feedback.rootCause.score },
                "recommendation": { "score": data.feedback.recommendation.score },
                "comments": data.comments
            }

            return await instanceAnalyzer.post(consts.requests.updateFeedback, args,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: false
                })
        }
    }

    const reportCommentPost = async (comments) => {

        if (token) {

            /* let payload = {
                 user: user,
                 content:content,
                 reportId:reportId
 
             };*/

            return await instanceAnalyzer.post(consts.requests.reportCommentPost, comments,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: true
                })
        }

    }

    const reportCommentGet = async (requestId, serviceId) => {
        if (!token) {
            return;
        }

        let url = consts.requests.reportCommentGet.replace('{requestId}', requestId).replace('{serviceId}', serviceId)

        return await instanceAnalyzer.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: true
        })
    }

    const getPODS = async (namespace) => {
        if (!token) {
            return;
        }

        let url = consts.requests.getPODS.replace('{namespace}', namespace)

        return await instanceAnalyzerDump.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: true
        })
    }

    const getCounters = async (requestId) => {

        if (!token) {
            return;
        }

        let url = consts.requests.getCounters.replace('{requestId}', requestId)

        return await instanceAnalyzerDump.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: true
        })
    }

    const getDump = async (requestId) => {
        if (!token) {
            return;
        }

        let url = consts.requests.getDump.replace('{requestId}', requestId)

        return await instanceAnalyzerDump.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: true
        })
    }

    const postDump = async (namespace, podName, maxObjectItemAnalysis) => {
        if (token) {

            let payload = {
                shouldAnalyze: true,
                podName: podName,
                maxObjectItemAnalysis: maxObjectItemAnalysis

            };

            return await instanceAnalyzerDump.post(consts.requests.postDump.replace('{namespace}', namespace), payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: true
                })
        }
    }

    const requestCounters = async (namespace, podName, interval, durationInSeconds) => {
        if (token) {

            let payload = {
                namespaceName: namespace,
                podName: podName,
                interval: interval,
                durationInSeconds: durationInSeconds

            };

            return await instanceAnalyzerDump.post(consts.requests.postCounters, payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: true
                })
        }
    }


    const getAnalyzerServiceHealth = async (subscriptionId, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }

        let url = consts.requests.getMicrosoftHealth.replace('{subscriptionId}', subscriptionId)

        return analyzerMicrosoftHealthAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }

    const collectedData = async (requestId, serviceId) => {
        if (token) {
            let url = consts.requests.getCollectedData.replace('{requestId}', requestId).replace('{serviceId}', serviceId);
            return await instanceAnalyzer.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: false
            })
        }
    }

    const getIncident = async (servicesIds, risk, limit, from, to) => {

        if (token) {

            let payload = {
                servicesIds: servicesIds,
                risk: risk,
                limit: parseInt(limit),
                from: from,
                to: to

            };



            return await instanceAnalyzer.post(consts.requests.incident, payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: false
                })
        }

    }

    const scan = async (services, fromDate, toDate, applicationId = null, ignoreSpinner = true) => {
        if (token) {

            let payload = {
                services: services,
                from: fromDate,
                to: toDate

            };
            if (applicationId !== null) {
                payload.applicationId = applicationId
            }



            return await instanceAnalyzer.post(consts.requests.scan, payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    ignoreSpinner: ignoreSpinner
                })
        }
    }
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        const CopyMsg = () => (
            <div>Text copied to clipboard!</div>
        )
        toast.success(<CopyMsg />)
    }

    const rootCauseAnalysis = async (reportId, ignoreSpinner = true) => {
        if (token) {
            let url = consts.requests.getRootCauseAnalysis.replace('{reportId}', reportId);
            return await instanceAnalyzer.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: ignoreSpinner
            })
        }
    }

    const report = async (requestId, serviceId, ignoreSpinner = true) => {
        if (token) {
            let url = consts.requests.report.replace('{requestId}', requestId).replace('{serviceId}', serviceId);
            return await instanceAnalyzer.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: ignoreSpinner
            })
        }
    }

    const status = async (requestId) => {
        if (token) {
            let url = consts.requests.status.replace('{requestId}', requestId);
            return await instanceAnalyzer.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: true
            })
        }
    }

    const getHistory = async (params) => {
        if (token) {
            let url = consts.requests.getScanHistory.replace('{params}', params);
            return await instanceAnalyzer.get(url, {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                }
            })
        }
    }

    const buildServices = (servicesCached, serviceIds) => {

        let services = serviceIds.map((s) => {
            {
                return <div>{GetServiceNameById(servicesCached, s)}</div>
            }
        })

        return <div><div>{services}</div></div>


    }

    const GetServiceNameById = (servicesCached, id) => {
        if (!servicesCached || Object.entries(servicesCached).length === 0) {
            return;
        }
        let name = servicesCached.filter(ser => ser.id === id);
        if (name && name.length > 0) {
            return name[0].name
        }
        return "Service not found";
    }

    const getApplicationGroups = async (servicesCached) => {
        try {
            let apps = await getApplications()

            if (apps.status === 200) {
                let items = [];

                apps.data.map((app) => {
                    let item = {
                        id: app.id,
                        name: app.name,
                        description: app.description,
                        servicesName: buildServices(servicesCached, app.serviceIds),
                        serviceIds: app.serviceIds,
                        schedulerCronPattern: app.schedulerCronPattern,
                        incidentProbability: app.incidentProbability,
                        incidentNewRelicEventTitle: app.incidentNewRelicEventTitle,
                        isSlackChannelActive: app.isSlackChannelActive
                    };
                    items.push(item);
                })
                setApplications([...items])

            }


        } catch (error) {
            console.log(error)

        }
    }

    const getApplications = async () => {
        if (token) {
            return await instance.get(consts.requests.getApplicationGroups, {
                headers: {
                    Accept: "application/json",
                    //Authorization: `Bearer ${token}`
                },

            })
        }
    }

    const deleteApplicationGroups = async (id) => {
        if (token) {
            return await instance.delete(consts.requests.deleteApplicationGroups.replace('{groupId}', id), {
                headers: {
                    Accept: "application/json"
                    //Authorization: `Bearer ${token}`,
                },

            })
        }
    }

    const updateAnalyzerGroup = async (groupId, name, description, serviceIds, schedulerCronPattern, incidentProbability, incidentNewRelicEventTitle, slackNotification) => {

        if (token) {
            return await instance.put(consts.requests.updateGroup.replace('{groupId}', groupId), {
                name: name,
                description: description,
                serviceIds: serviceIds,
                schedulerCronPattern: schedulerCronPattern,
                incidentProbability: incidentProbability,
                incidentNewRelicEventTitle: incidentNewRelicEventTitle,
                isSlackChannelActive: slackNotification
            },
                {
                    headers: {
                        Accept: "application/json"
                        //Authorization: `Bearer ${token}`,
                    },
                })
        }

    }


    const createAnalyzerGroup = async (name, description, serviceIds, schedulerCronPattern, incidentProbability, incidentNewRelicEventTitle, slackNotification) => {

        if (token) {
            return await instance.post(consts.requests.createGroup, {
                name: name,
                description: description,
                serviceIds: serviceIds,
                schedulerCronPattern: schedulerCronPattern,
                incidentProbability: incidentProbability,
                incidentNewRelicEventTitle: incidentNewRelicEventTitle,
                isSlackChannelActive: slackNotification
            },
                {
                    headers: {
                        Accept: "application/json"
                        // Authorization: `Bearer ${token}`,
                    },
                })
        }

    }




    const getDBAlerts = async (appName, fromDate, toDate, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getDBAlerts.replace('{appName}', appName).replace('{fromDate}', fromDate)
            .replace('{toDate}', toDate)

        return analyzerDBChangesAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }

    const getAnalyzerDBChanges = async (valueFrom, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }

        let url = consts.requests.getDBChanges.replace('{fromDate}', valueFrom)
        return analyzerDBChangesAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }

    const getAnalyzerConfigurationChanges = async (selectedAppName, selectedAppServiceResourceId, valueFrom, valueTo, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }

        let url = consts.requests.getConfigurationChanges.replace('{appName}', selectedAppName)
            .replace('{appServiceResourceId}', selectedAppServiceResourceId)
            .replace('{fromDate}', valueFrom).replace('{toDate}', valueTo)

        return analyzerConfigurationChangesAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })

    }


    const getAnalyzerDeploymentsChanges = async (selectedProject, releaseDefId, valueFrom, valueTo, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getDeploymentChanges.replace('{project}', selectedProject).replace('{releaseDefId}', releaseDefId)
            .replace('{limit}', 100).replace('{fromDate}', valueFrom).replace('{toDate}', valueTo)

        return analyzerDeploymentChangesAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }

    const serviceAvailability = (serviceName) => {

    }

    const getLogsCount = async (appName, fromDate, toDate, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getLogsCount.replace('{appName}', appName).replace('{fromDate}', fromDate)
            .replace('{toDate}', toDate)

        return analyzerLogsAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }



    const getLogsDetails = async (appName, fromDate, toDate, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getLogsDetails.replace('{appName}', appName).replace('{fromDate}', fromDate)
            .replace('{toDate}', toDate)

        return analyzerLogsAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }

    const buildMicrosoftHealthObject = (response) => {
        let issuesItems = [];
        if (response) {
            const sortedByDateDesc = _.orderBy(response, ["properties.impactStartTime"], ["desc"]);
            sortedByDateDesc.map((item) => {
                issuesItems.push({
                    open: false,
                    name: item.name,
                    type: item.type,
                    tenantId: item.tenantId,
                    kind: item.kind,
                    location: item.location,
                    resourceGroup: item.resourceGroup,
                    subscriptionId: item.subscriptionId,
                    managedBy: item.managedBy,
                    sku: item.sku,
                    plan: item.plan,
                    tags: item.tags,
                    identity: item.identity,
                    zones: item.zones,
                    extendedLocation: item.extendedLocation,
                    impactMitigationTime: item.properties?.impactMitigationTime,
                    externalIncidentId: item.properties?.externalIncidentId,
                    recommendedActions: item.properties?.recommendedActions,
                    status: item.properties?.status,
                    platformInitiated: item.properties?.platformInitiated,
                    impact: item.properties?.impact,
                    impactStartTime: formatDateTimeFromTick(item.properties?.impactStartTime),
                    lastUpdateTime: formatDateTimeFromTick(item.properties?.lastUpdateTime),
                    eventSource: item.properties?.eventSource,
                    trackingId: item.properties?.trackingId,
                    eventLevel: item.properties?.eventLevel,
                    impactType: item.properties?.impactType,
                    eventType: item.properties?.eventType,
                    priority: item.properties?.priority,
                    duration: item.properties?.duration,
                    summary: item.properties?.summary,
                    header: item.properties?.header,
                    title: item.properties?.title,
                    level: item.properties?.level,
                    isHIR: item.properties?.isHIR,

                })
            })
        }
        return issuesItems;
    }

    const buildDeploymentChangesObject = (response) => {

        let dicCICD = [];

        if (response.deployments.length > 0) {

            response.deployments.map((item) => {

                let ci = {}
                let cd = {}

                Object.entries(item).forEach(([key, value]) => {
                    if (key === 'ci') {
                        ci = value;
                    }
                    if (key === 'release') {
                        cd = value;
                    }
                    if (Object.keys(ci).length > 0 && Object.keys(cd).length > 0) {
                        dicCICD.push({
                            ci: ci, cd: cd
                        })
                        ci = {}
                        cd = {}
                    }


                })

            })

        }

        let arrDefHistory = [];
        if (response.releaseDefinitionHistory.length > 0) {

            response.releaseDefinitionHistory.map((item) => {

                arrDefHistory.push({
                    changeType: item.changeType,
                    changedBy: item.changedBy,
                    changedDate: item.changedDate,
                    comment: item.comment,
                    definitionId: item.definitionId,
                    definitionUrl: item.definitionUrl,
                    revision: item.revision
                })

            })

        }


        let deploymentDetails = {
            ci: dicCICD,
            defHistory: arrDefHistory
        }


        return deploymentDetails;
    }



    const getAppDependencies = async (appName, ignoreSpinner = false) => {

        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getAppDependencies.replace('{appName}', appName);
        try {
            return instance.get(url, {
                headers: {
                    Accept: "application/json"
                    // Authorization: `Bearer ${token}`,
                },
                ignoreSpinner: ignoreSpinner
            })
        }
        catch (error) {
            throw new Error(error)
        }

    }
    const getDiagnosticDataForAKS = (subscriptionId, resourceGroup, clusterName, fromDate, toDate, ignoreSpinner = false) => {
        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getDiagnosticDataForAKS.replace('{subscriptionId}', subscriptionId).replace('{resourceGroup}', resourceGroup)
            .replace('{clusterName}', clusterName)
            .replace('{fromDate}', fromDate)
            .replace('{toDate}', toDate)

        return analyzerServiceHealthAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })
    }
    const getDiagnosticDataForAppService = (subscriptionId, resourceGroup, appName, fromDate, toDate, ignoreSpinner = false) => {

        if (!token) {
            return; //error message later
        }
        let url = consts.requests.getDiagnosticDataForAppService.replace('{subscriptionId}', subscriptionId).replace('{resourceGroup}', resourceGroup)
            .replace('{serviceName}', appName).replace('{fromDate}', fromDate)
            .replace('{toDate}', toDate)

        return analyzerServiceHealthAxios.get(url, {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            ignoreSpinner: ignoreSpinner
        })

    }

    const SubstringStringForToolTip = (inputString, maxLength = 100) => {


        if (inputString.length > maxLength) {
            return inputString.substring(0, maxLength) + '...';
        }
        return inputString;
    }


    const columnsDataItems = [



        { display: true, name: "database", key: "database", width: "25%" },
        { display: true, name: "server", key: "server", width: "25%" },
        { display: true, name: "table", key: "table", width: "25%" },
        { display: true, name: "count", key: "count", width: "25%" },

    ]


    const columnsDataItemsForLogs = [

        { display: true, name: "messageId", key: "messageId", width: "7%" },
        { display: true, name: "type", key: "type", width: "5%" },
        { display: true, name: "environment", key: "environment", width: "7%" },
        { display: true, name: "class", key: "class", width: "7%" },
        { display: true, name: "message", key: "message", width: "20%" },
        { display: true, name: "level", key: "level", width: "5%" },
        { display: true, name: "logger", key: "logger", width: "20%" },
        { display: true, name: "time", key: "time", width: "10%" },

    ]

    const translateAnalyzerCodes = (obj) => { //for the timeline metadata

        try {
            switch (obj?.code.toLowerCase()) {

                case "memory":
                case "cpu":
                case "requests":
                case "response_time": {

                    return <div>
                            <div><b>{ obj.code }:</b></div>
                            <div>{obj.data.value}
                                {
                                    obj?.data?.unitOfMeasure === "Percentage" ?
                                        (
                                            <span>%</span>
                                        ) :
                                        (
                                            <span>{obj?.data?.unitOfMeasure}</span>
                                        )
                                }
                            </div>
                        </div>
                    

                }

                case "alert": {
                    return <div> <div>database: {obj.data.database} </div> <div>table: {obj.data.table} </div> ruleName: {obj.data.ruleName}</div>
                }
                case "diagnostics_cpu_drill_down": {
                    console.log('diagnostics_cpu_drill_down - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_memory_drill_down": {
                    console.log('diagnostics_memory_drill_down - translateAnalyzerCodes', obj)
                    return <div>Message: {obj.data.message} </div>

                    return obj.module + "\n\r";
                }
                case "diagnostics_key_vault_app_settings": {
                    console.log('diagnostics_key_vault_app_settings - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_snat_port_exhaustion": {
                    console.log('diagnostics_snat_port_exhaustion - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_swap_availability_and_performance": {
                    console.log('diagnostics_swap_availability_and_performance - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_tcp_connections_diagnostic": {
                    console.log('diagnostics_tcp_connections_diagnostic - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_ssl_and_domains": {
                    console.log('diagnostics_ssl_and_domains - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_restart": {
                    console.log('diagnostics_restart - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_scaling_operations": {
                    console.log('cpdiagnostics_scaling_operationsu - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r";
                }
                case "diagnostics_alert": {
                    console.log('diagnostics_alert - translateAnalyzerCodes', obj)

                    if (obj?.data?.items && obj?.data?.items.length > 0) {
                        return (
                            <React.Fragment>
                                {obj?.data?.items.map((item) => (
                                    <div>

                                        <div>Name: {item.name}</div>
                                        <div>Description: {item.description}</div>
                                        <div>Status: {item.status}</div>

                                    </div>
                                ))}
                            </React.Fragment>
                        )
                    }

                    return obj.module + "\n\r"
                }
                case "diagnostics_event_logs": {
                    console.log('diagnostics_event_logs - translateAnalyzerCodes', obj)
                    return obj.module + "\n\r"
                }
                case "release_definition_history": {
                    return <div> <div>Change Type: {obj.data.changeType}  Change By: {obj.data.changedBy}</div></div>
                }
                case "release_history": {

                    return <div>
                        <div>Change Type: {obj.data.changeType}  Change By: {obj.data.changedBy}</div>
                        <div>Comment: {obj.data.comment ? obj.data.comment : obj.data.changeDetails}</div>
                    </div>

                }
                case "stage_last_modified_on": {
                    return <div> <div>Created By: {obj.data.createdBy}  Status: {obj.data.status}</div></div>

                }
                case "resource_changes": {
                    return <div> <div>Type: {obj.data.changeType} </div><div> Count: {obj.data.changesCount}</div><div> Resource Group: {obj.data.resourceGroup}</div></div>
                }
                case "error_count": {

                    return <div>
                        {
                            obj?.data.errorLogs && obj?.data.errorLogs.length > 0 && obj?.data.errorLogs.map((e) => (
                                <div>
                                    <div><span>logger</span>{e.logger}</div>
                                    <div><span>message</span>{e.message}</div>
                                </div>
                            ))

                        }
                    </div>
                }
                case "error_new_ratio": {

                    return <div> <div>We found {obj?.data.errorLogs.length} unique error logs, which represent {obj?.data.extraData.CurrentOriginalCount} total logs. </div></div>
                }
                case "app_service": {

                    return <div><div> <div>Category: {obj.data.changeCategory}  Description: {obj.data.description}</div></div><div><div>New Value {obj.data.newValue}</div> <div>Old Value: {obj.data.oldValue}  Property: {obj.data.property}</div></div></div>
                }
                case "app_configuration_or_key_vault": {
                    return <div> <div>key: {obj.data.key}  Provider: {obj.data.provider}</div></div>

                }
                default:
                    break;
            }
        } catch (error) {
            console.log("error", error)
        }
    }
    const createAKSNamespace = (clusterName, serviceName) => {
        switch (clusterName) {
            case "infra-dev-aks-cluster":
                return "infra-" + serviceName + "-dev"
            case "pay-dev-aks-cluster":
                return "pay-" + serviceName + "-dev"
            default:
                return serviceName
        }
    }


    const BulletColorByModule = (key) => {
        switch (key.toLowerCase()) {



            case "configuration":
                {
                    return 'red'
                }
            case "database":
                {
                    return 'green'
                }
            case "deployments":
                {
                    return 'lightskyblue'
                }
            case "metrics":
                {
                    return 'palevioletred'
                }
            case "resourcechanges":
                {
                    return 'yellow'
                }


            default:
                break;
        }
    }


    return (
        <DataContextAnalyzer.Provider value={{
            getAnalyzerConfigurationChanges, getAnalyzerDBChanges, getAnalyzerServiceHealth, getAnalyzerDeploymentsChanges, columnsDataItems,
            buildDeploymentChangesObject, buildMicrosoftHealthObject, issues, setIssues, getLogsCount, getLogsDetails, columnsDataItemsForLogs,
            getDiagnosticDataForAppService, getAppDependencies, getDBAlerts, getDiagnosticDataForAKS, scan, report, status, createAnalyzerGroup,
            getApplicationGroups, deleteApplicationGroups, updateAnalyzerGroup, applications, setApplications, buildServices, LuxonDateFormatString,
            GetServiceNameById, translateAnalyzerCodes, BulletColorByModule, SubstringStringForToolTip, getHistory, rootCauseAnalysis, collectedData,
            requestCounters, getPODS, getCounters, createAKSNamespace, postDump, getDump, counters, setCounters, loadingStatus, setLoadingStatus,
            dump, setDump, loadingDumpStatus, setLoadingDumpStatus, ddlValueRef, getIncident, predict, handleCopyToClipboard, translateTrigger,
            reportCommentPost, reportCommentGet, anomalyPredict, setAnomalyPredict, comments, setComments, modules, setModules, getMetrics, updateFeedback,
            getFeedback
        }}>



            {children}
        </DataContextAnalyzer.Provider>
    )

}

export default DataContextAnalyzer;
