import { Modal, Button, Container, Col, Row } from "react-bootstrap";
import DataContext from "../Context/DataContext";
import { useContext, useState, useEffect } from "react"
import './AksOrEksModal.css';
import SelfServiceModalStepper from "../Components/SelfService/SelfServiceModalStepper";


const AksOrEksModal = ({setCloudDecision, cloudDecision, setDecision, decision, setRepoDecision,
     repoDecision, setModalShow, modalShow ,typeDecision, setTypeDecision,setMigrationType, migrationType}) => {
    const { } =
        useContext(DataContext);


    const handleCloseModal = () => {
        setModalShow(false)

    }

    const handleModalShow = () => {

        setModalShow(true)
        setDecision('aks')
        setRepoDecision('new')
        setCloudDecision('azure')
    }


  


    return (
        <Modal
            show={modalShow}
            onHide={handleCloseModal}
            onShow={handleModalShow}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="alert-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="aks-eks-ss-alert-modal-title">
                    Choose one of the options below
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelfServiceModalStepper setModalShow={setModalShow} setRepoDecision={setRepoDecision}
                typeDecision={typeDecision} setTypeDecision={setTypeDecision}
                setCloudDecision={setCloudDecision} cloudDecision={cloudDecision}
                setMigrationType={setMigrationType} migrationType={migrationType}
                repoDecision={repoDecision} setDecision={setDecision} decision={decision} />
            
            </Modal.Body>
          
        </Modal>

    )
}

export default AksOrEksModal