// ChatContext.js
import React, { createContext, useEffect, useState, useContext, useRef } from 'react';
import * as consts from '../consts';
import useAxios from '../Hooks/useAxios';
import config from '../Components/Copilot/config.js';
import { marked } from 'marked';
import "../App.css";
import Markdown from 'markdown-to-jsx'
import { render } from 'react-dom';
import useLogin from '../Hooks/useLogin.js';
import DataContext from './DataContext.js';
import { DateTime, DateTime as luxon } from "luxon";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import DOMPurify from 'dompurify';
import { v4 as uuid } from 'uuid';


export const DataContextChat = createContext();

export const DataProviderChat = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const { token } = useContext(DataContext);

  const instance = useAxios(process.env.REACT_APP_BASE_URL);
  const instanceAnalyzerCopilot = useAxios(process.env.REACT_APP_ANALYZER_COPILOT_BASE_URL);


  const [assistantId, setAssistantId] = useState('')
  const [htmlContent, setHtmlContent] = useState('')
  const [threadId, setThreadId] = useState('')
  const [chatModuleLocation, setChatModuleLocation] = useState('')
  const [chatInit, setChatInit] = useState(null)
  const [connection, setConnection] = useState(null);
  const [fromIncident, setFromIncident] = useState('')
  const { userLogin } = useLogin()
  const isNewRequest = useRef(false)
  const [signalrMessages, setSignalrMessages] = useState(null)
  const [disableUserChatSendBtn, setDisableUserChatSendBtn] = useState(false)
  const [linkToReportMD, setLinkToReportMD] = useState(null)
  const [signalRStatus, setSignalRStatus] = useState([])
  const messageRef = useRef('');
  const lastSeq = useRef(0)
  const threadMessageId = useRef('')


  const [selfServiceReceiveJsonMessage, setSelfServiceReceiveJsonMessage] = useState(null)

  useEffect(() => {

    if (connection && chatInit !== null) {

      if (connection?._connectionState === "Disconnected") {
        connection.start()
          .then(result => {
            connectToSignalRSocketBackend(connection)

          })
          .catch(e => console.log('Copilot SignalR Connection failed: ', e));
      }
      else {
        connectToSignalRSocketBackend(connection)
      }



    }


  }, [connection, chatInit]);



  const connectToSignalRSocketBackend = (connection) => {
    connection.invoke("AddClientToGroupAsync", chatInit.chatSession.id)
      .then(() => {
        console.log("Joined group");
      }).catch(err => console.error(err.toString()));

    // Subscribe to the "ReceiveBotResponseStatus" method
    connection.on("ReceiveBotResponseStatus", (chatId, status) => {


      if (!status) {

        return;
      }
      console.log("ReceiveBotResponseStatus",status)

      //if(signalRStatus && signalRStatus.length > 0){
      setSignalRStatus(prevStatus => [...prevStatus, status]);
      //}


      if (status === "Saving token usage") {
        setDisableUserChatSendBtn(false)

      }

      if (status === "Generating bot response") {

        isNewRequest.current = true;
        lastSeq.current = 0;
        threadMessageId.current = uuid()
        messageRef.current = ''
        setSignalRStatus([])
      }

    });
    connection.on("ReceiveDocumentMessage", async (message) => {

      if (message) {
        if (message?.citations && message?.citations?.length > 0) {


          try {

            const response = await fetch(message?.citations && message?.citations[0].link);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const markdownText = await response.text();

            let convertedMessage = convertMarkdown(markdownText)
            setLinkToReportMD(convertedMessage)

            //setSignalRStatus(prevStatus => [...prevStatus, "Report from link is ready"]);

          } catch (error) {
            console.error('Error fetching markdown:', error);
          }
        }
      }
    });
//taltal
    connection.on("ReceiveJsonMessage", (message) => {
      try {
        if (message && message?.content) {
          console.log("ReceiveJsonMessage",message)
          let obj = JSON.parse(message.content);
          setSelfServiceReceiveJsonMessage(obj)
        }
        
      } catch (error) {
        setSelfServiceReceiveJsonMessage(null)
      }
    
    });

    // Subscribe to the "ReceiveMessageUpdate" method
    connection.on("ReceiveMessageUpdate", (message, sequenceNumber) => {

      if (sequenceNumber > lastSeq.current) {
        lastSeq.current = sequenceNumber;
        if (messageRef.current !== "") {

          message.content = message.content.replace(messageRef.current.content, '');
          messageRef.current.content += message.content;
        }
        else {

          messageRef.current = message;
        }

        const newMessage = { type: 'message', message: messageRef.current, sequenceNumber: sequenceNumber, chatId: '', timestamp: new Date(), id: 'signalr-message' };

        setSignalrMessages(newMessage);
      }



    });
  }

  const convertMarkdown = (text) => {
    let htmlContent = marked(text);
    const cleanHtmlString = DOMPurify.sanitize(htmlContent);
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, 'text/html');
    return doc.documentElement.outerHTML;
  }

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
    setDisableUserChatSendBtn(false)
  };

  const generateConnection = (token) => {

    const connection = new HubConnectionBuilder()

      .withUrl(process.env.REACT_APP_COPILOT_HUB, {
        accessTokenFactory: async () => {
          return token;
        },
      })
      .configureLogging(LogLevel.Information)
      .build();
    setConnection(connection);


  }

  const startSignalRConnection = async () => {

    if (connection && connection?._connectionState === 'Connected') {
      setConnection(connection)
    }
    else {
      let expiresOnUnixString = localStorage.getItem("InfraConsoleExpired");
      const expiresOnUnix = parseInt(expiresOnUnixString, 10);

      const now = DateTime.now();
      const expiresOn = DateTime.fromSeconds(expiresOnUnix);



      if (now < expiresOn) {
        generateConnection(localStorage.getItem('InfraConsoleToken'))
      } else {
        console.error('Token expired in copilot chat');
        let newAccessToken = await userLogin();
        generateConnection(newAccessToken?.accessToken)


        //save new token + setToken state
        localStorage.setItem('InfraConsoleToken', newAccessToken?.accessToken)
        // Handle token expiration (e.g., redirect to login page)
      }






    }

  }


  const toggleChatWithInitMessage = async (message) => {
    setIsChatOpen(!isChatOpen);
    if (!chatInit) {
      let chatId = await createCopilotChat("from incident table");
      return await sendCopilotMessage(message, chatId);
    }
  }


  const toggleChatOnPageChange = () => {
    setIsChatOpen(false);
  };

  const getModuleNameByPath = () => {
    const pathname = window.location.pathname.toLowerCase();

    if (pathname.includes("auto-cicd")) {
      setChatModuleLocation('self service');
      return "self service";
    } else if (pathname.includes("dynamic-permission")) {
      setChatModuleLocation('dynamic permission');
      return "dynamic permission"
    } else if (pathname.includes("analyzer")) {
      setChatModuleLocation('analyzer');
      return "analyzer"
    } else if (pathname.includes("catalog")) {
      setChatModuleLocation('catalog');
      //config.payload = { token: token, module:'catalog' }
      return "catalog"
    } else {
      setChatModuleLocation('home');
      return "home"
    }
  }

  const getCopilotId = async () => {

    try {


      const url = consts.requests.getAssitant;
      return await instance.get(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("InfraConsoleToken")}`,
        },
        ignoreSpinner: true
      })

    } catch (error) {

    }


  }

  const sendCatalogMessage = async (assistantId, messageInput) => {

    try {
      return await instance.post(consts.requests.sendCatalogMessage, {
        assistantId: assistantId,
        threadId: threadId,
        messageInput: messageInput
      }, { ignoreSpinner: true })
    } catch (error) {

    }


  }



  const sendCopilotMessage = async (message, cID) => {

    try {



      setDisableUserChatSendBtn(true)
      let url = consts.requests.sendCopilotMessage.replace('{ChatId}', cID);
      let response = await instanceAnalyzerCopilot.post(url, {
        input: message
      }, { ignoreSpinner: true })

      return response;

    } catch (error) {
      setDisableUserChatSendBtn(false)
    }


  }

  const createCopilotChat = async (title = 'default', type = 'Regular') => {



    try {
      let results = await instanceAnalyzerCopilot.post(consts.requests.createCopilotChat, {
        title: title,
        chatType: type
      }, { ignoreSpinner: true })

      if (results.status === 200) {
        if (results?.data?.data?.chatSession !== null) {
          setChatInit(results.data.data)
          return results.data.data;
        }
      }
    }
    catch (error) {

    }

  }

  const markdownToHtml = (markdown) => {
    // Headers (h6 to h1)
    markdown = markdown.replace(/######\s(.*)(?:\n|$)/g, '<h6>$1</h6>');
    markdown = markdown.replace(/#####\s(.*)(?:\n|$)/g, '<h5>$1</h5>');
    markdown = markdown.replace(/####\s(.*)(?:\n|$)/g, '<h4>$1</h4>');
    markdown = markdown.replace(/###\s(.*)(?:\n|$)/g, '<h3>$1</h3>');
    markdown = markdown.replace(/##\s(.*)(?:\n|$)/g, '<h2>$1</h2>');
    markdown = markdown.replace(/#\s(.*)(?:\n|$)/g, '<h1>$1</h1>');

    // Bold and italic
    markdown = markdown.replace(/\*\*\*(.*?)\*\*\*/g, '<strong><em>$1</em></strong>');
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Horizontal rule
    markdown = markdown.replace(/^\s*[-\*]{3,}\s*$/gm, '<hr />');

    // Blockquotes
    markdown = markdown.replace(/^\>\s(.*)(?:\n|$)/gm, '<blockquote>$1</blockquote>');

    // Lists (unordered and ordered)
    markdown = markdown.replace(/^\s*\*\s(.*)(?:\n|$)/gm, '<ul>\n<li>$1</li>\n</ul>');
    markdown = markdown.replace(/^\s*-\s(.*)(?:\n|$)/gm, '<ul>\n<li>$1</li>\n</ul>');
    markdown = markdown.replace(/^\s*\d+\.\s(.*)(?:\n|$)/gm, '<ol>\n<li>$1</li>\n</ol>');

    // Inline code
    markdown = markdown.replace(/`([^`]*)`/g, '<code>$1</code>');

    // Code blocks
    markdown = markdown.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');

    // Line breaks
    markdown = markdown.replace(/\n/g, '<br />');

    // Links
    markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // Images
    markdown = markdown.replace(/\!\[([^\]]+)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');

    return markdown.trim();
  }

  const parseHtmlWithCustomClasses = (html) => {



    html = html.replace(/class="container"/g, 'class="container-html-custom-classes"');
    //return html.replace(/>\s+|\s+</g, match => match.trim());



    html = html.replace(/class="insight"/g, 'class="insight-html-custom-classes"');
    html = html.replace(/class="Insight"/g, 'class="insight-html-custom-classes"');
    html = html.replace(/class="section"/g, 'class="section-html-custom-classes"');
    html = html.replace(/class="Section"/g, 'class="section-html-custom-classes"');
    html = html.replace(/class="anomaly"/g, 'class="anomaly-html-custom-classes"');
    html = html.replace(/class="Anomaly"/g, 'class="anomaly-html-custom-classes"');
    html = html.replace(/class="classification"/g, 'class="classification-html-custom-classes"');
    html = html.replace(/class="Classification"/g, 'class="classification-html-custom-classes"');



    // Parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Replace all <strong> elements with <div> elements
    doc.querySelectorAll('strong').forEach(el => {
      const div = document.createElement('div');
      div.innerHTML = el.innerHTML;
      el.replaceWith(div);
    });

    // Convert the modified DOM back to HTML string
    return doc.documentElement.outerHTML;
  }

  const parseMarkdownToHtml = (markdownText) => {


    //let markText = markdownToHtml(markdownText);
    //https://www.npmjs.com/package/markdown-to-jsx
    //render(<Markdown>{markText}</Markdown>, document.body)


    if (!containsSilence(markdownText)) {

      let markText = markdownToHtml(markdownText);
      //   markText = marked(markdownText);
      markText = addWrapper(markText);
      /*markText = handleTableTag(markText);
      markText = handleTDandTH(markText)*/
      //  markText = removePTagsUnderSpecificDiv(markText)

      return handleATag(markText);
    }
    else {
      return "Sorry, my friend, I have no information regarding your question 😔."
    }


  };
  //taltal
  const addWrapper = (markText) => {
    return '<div class=\'copilot-wrapper-custom-class\'>' + markText + '</div>'
  }

  const containsSilence = (htmlString) => {
    // Regular expression to check for [silence] within <p> tags
    const regex = /\[silence\]/;
    return regex.test(htmlString);

  }

  const removePTagsUnderSpecificDiv = (markText) => {
    const pTagRegex = /<p>(.*?)<\/p>/gi;

    // Replace <p> tags with their inner content
    return markText.replace(pTagRegex, (match, p1) => {
      return p1; // Return only the inner content, effectively removing <p> tags
    });
  }

  const handleTDandTH = (markText) => {
    // Add border style to <th> elements
    markText = markText.replace(/<th(.*?)>/g, '<th$1 style="border: 1px solid;">');

    // Add border style to <td> elements
    return markText.replace(/<td(.*?)>/g, '<td$1 style="border: 1px solid;">');
  }


  const handleTableTag = (markText) => {
    // Regular expression to match <table> tags
    const tableTagRegex = /(<table\b[^>]*)(>)/gi;

    // Replace <table> tags with the updated version including style
    return markText.replace(tableTagRegex, (match, p1, p2) => {
      // If style attribute already exists, add the background color to it
      if (/style=/i.test(p1)) {
        return p1.replace(/style=(['"])(.*?)\1/i, (styleMatch, quote, styleContent) => {
          return `style=${quote}color: white; ${styleContent}${quote}`;
        }) + p2;
      } else {
        // Otherwise, add a new style attribute
        return `${p1} style="color: white;"${p2}`;
      }
    });
  }


  const handleATag = (markText) => {
    const anchorTagRegex = /(<a\s+[^>]*href=["'][^"']*["'][^>]*)(>)/gi;
    return markText.replace(anchorTagRegex, '$1 target="_blank"$2');
  }



  const parseRecivedMessage = (message) => {


    const htmlEscapeTable = {
      '&': '&amp;',
      '"': '&quot;',
      "'": '&#39;', // Use &#39; instead of &apos; for better compatibility
      '>': '&gt;',
      '<': '&lt;',
      '\n': '<br>',
    };

    // Escape special characters
    let escapedString = message.replace(/[&"'<> \n]/g, function (char) {
      return htmlEscapeTable[char] || char;
    });

    // Regular expression to find URLs
    const urlPattern = /(\bhttps?:\/\/[^\s<]+)/g;

    // Replace URLs with clickable links
    escapedString = escapedString.replace(urlPattern, function (url) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });

    return escapedString;

  }


  return (
    <DataContextChat.Provider value={{
      isChatOpen, toggleChat, getCopilotId, getModuleNameByPath, toggleChatOnPageChange, createCopilotChat, sendCopilotMessage, markdownToHtml,
      sendCatalogMessage, assistantId, setAssistantId, parseRecivedMessage, htmlContent, setHtmlContent, chatModuleLocation, setChatModuleLocation,
      threadId, setThreadId, chatInit, setChatInit, parseMarkdownToHtml, toggleChatWithInitMessage, fromIncident, setFromIncident, parseHtmlWithCustomClasses,
      startSignalRConnection, setConnection, connection, signalrMessages, setSignalrMessages, lastSeq, isNewRequest, threadMessageId,
      disableUserChatSendBtn, setDisableUserChatSendBtn, convertMarkdown, linkToReportMD, setLinkToReportMD, signalRStatus, setSignalRStatus,
      selfServiceReceiveJsonMessage, setSelfServiceReceiveJsonMessage
    }}>
      {children}
    </DataContextChat.Provider>
  );
};
