
import React, { useContext, useEffect, useState } from 'react'
import './ExportPDFHtml.css';
import SAAnomalyChart from '../anomaly/SAAnomalyChart';
import DataContextAnalyzer from '../../../../Context/DataContextAnalyzer';
import SARenderSummary from '../SARenderSummary';
import SARenderComments from '../SARenderComments';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Typography } from '@mui/material';
import SARenderReportDataByCode from '../SARenderReportDataByCode';
import SARenderLogs from '../SARenderLogs';
import _ from "lodash";
import SAAnalyzerRootCause from '../SAAnalyzerRootCause';
import MarkdownIt from 'markdown-it';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import '../MarkdownReportStyle.css'



const ExportPDFHtml = ({ modules, targetRef, service, valueFrom, valueTo }) => {
    const { anomalyPredict, LuxonDateFormatString } = useContext(DataContextAnalyzer);

    const [services, setServices] = useState([])

    const [summary, setSummary] = useState(null)


    const md = new MarkdownIt({
        highlight: (str, lang) => {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) { }
          }
          return ''; // use external default escaping
        }
      });

    useEffect(()=>{
        let servicesArr = []
        servicesArr.push(service)
        setServices(servicesArr)

        if(service?.summarization){
            let newSummary = service?.summarization.replace(/class="container"/g, 'class="container-html-report-and-pdf"');
            setSummary(newSummary)
        }
    },[service])

    const RenderInsightsTable = ({ name, modules }) => {

        let elements = modules[name];

        if (name.toLowerCase() === "diagnostics" && elements.length > 0) {
            return (
                <div>
                    <div className='pdf-data-incident-secion-title'>{name}</div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Data</TableCell>
                                    <TableCell>OccurredAt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {

                                    elements.map((value, innerIndex) => (
                                        <TableRow className='anomaly-alert-table-row' key={`tr-element` + innerIndex}>
                                            <TableCell >{value.code}</TableCell>
                                            <TableCell>
                                                <SARenderReportDataByCode code={value.code} value={value} />
                                            </TableCell>
                                            <TableCell>{LuxonDateFormatString(value.occurredAt)}</TableCell>
                                        </TableRow>
                                    ))

                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )
        }

        if (name.toLowerCase() === 'metrics' || name.toLowerCase() === 'configuration' || name.toLowerCase() === 'database' || name.toLowerCase() === 'deployments') {

            const groupedData = _.groupBy(elements, 'code');
            return (
                Object.entries(groupedData).map(([key, value]) => (
                    <React.Fragment>
                        <div className='pdf-data-incident-secion-title'>{name}</div>
                        <SARenderReportDataByCode code={key} value={null} elements={value} />
                    </React.Fragment>
                ))

            )

        }


        if (name.toLowerCase() === 'logs' && elements.length > 0) {
            return (

                <React.Fragment>
                    <div className='pdf-data-incident-secion-title'>{name}</div>
                    <SARenderLogs elements={elements} />
                </React.Fragment>

            )

        }

        return (
            elements.map((value, innerIndex) => (
                <React.Fragment>
                    <div className='pdf-data-incident-secion-title'>{name}</div>
                    <TableContainer key={innerIndex} component={Paper}>
                        <Table>
                            <SARenderReportDataByCode code={value.code} value={value} elements={elements} />
                        </Table>
                    </TableContainer>
                </React.Fragment>
            ))

        )
    }

    const RenderSummaryForExportPDF = () =>{
        if(summary === undefined || summary === "" || summary === null){
            return (
                <div>No summary</div>
            )
        }
        const postMortem = "# Post Mortem Report"        
        const htmlContent = md.render(summary.replace(postMortem, ''));
        return(
            <div className='markdown-content pdf-export-md-content' dangerouslySetInnerHTML={{ __html: htmlContent }} />
        )
    }

    return (
        <div>
            <div ref={targetRef} className='pdf-data-incident-target-ref'>
               
                <div className='pdf-data-incident-title'>Incident Report for {service.name} from {LuxonDateFormatString(service.from)} to {LuxonDateFormatString(service.to)}</div>
                <div className='pdf-data-incident-padding'>
                    {
                        service &&  service.summarization !== '' ?
                            (
                                <React.Fragment>
                                    <div className='pdf-data-incident-secion-title'>Incident Summary</div>
                                   {/* <SARenderSummary service={service} />*/}
                                   <RenderSummaryForExportPDF />
                                 
                                </React.Fragment>

                            ) :
                            (
                                <div></div>
                            )
                    }
                </div>
                <div className='pdf-data-incident-secion-title'>Anomaly Charts</div>
                <div className="pdf-data-by-code-incident-container">



                    <div className='data-by-code-incident-row'>

                        <div className="data-by-code-incident-square">
                            <SAAnomalyChart yLinkValue="ms" ready={true} type="CPU" data={anomalyPredict.cpu} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                        </div>
                        <div className="data-by-code-incident-square">
                            <SAAnomalyChart yLinkValue="%" ready={true} type="Memory" data={anomalyPredict.memory} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                        </div>

                    </div>
                    <div className='data-by-code-incident-row'>

                        <div className="data-by-code-incident-square">
                            <SAAnomalyChart yLinkValue="count" ready={true} type="Logs Count" data={anomalyPredict.logsCount} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                        </div>
                        <div className="data-by-code-incident-square">
                            <SAAnomalyChart yLinkValue="ms" ready={true} type="Response Time" data={anomalyPredict.responseTime} from={service.from === undefined ? valueFrom : service.from} to={service.to === undefined ? valueTo : service.to} />
                        </div>

                    </div>
                </div>
                <div className='pdf-data-incident-padding'>
                    {
                        Object.entries(modules).map(([key, values], innerIndex) => (

                            key !== "Summary" && key !== "Measurements" && key !== "Dump" && key !== "RootCause" ?
                                (
                                    <RenderInsightsTable name={key} modules={modules} />
                                ) : key === 'RootCause' ?
                                    (
                                        <React.Fragment>
                                            <div className='pdf-data-incident-secion-title'>{key}</div>
                                            <SAAnalyzerRootCause services={services} values={values} displayAsTabs={false} />
                                        </React.Fragment>

                                    ) :
                                    (
                                        <span></span>
                                    )


                        ))
                    }
                </div>
                <div className='pdf-data-incident-padding'>
                    <div className='pdf-data-incident-secion-title'>Comments</div>
                    <SARenderComments />
                </div>
            </div>
        </div>
    )
}
export default ExportPDFHtml