import config from './config.js';
import MessageParser from './MessageParser.js';
import ActionProvider from './ActionProvider.js';
//import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import './InfraCopilot.css';
import { useContext, useEffect, memo, useState, useRef } from 'react';
import useAxios from '../../Hooks/useAxios.js';
import useLogin from '../../Hooks/useLogin.js';
import * as consts from '../../consts';
import DataContext from '../../Context/DataContext.js';
import _ from "lodash";
import DataContextConsoleAI from '../../Context/DataContextConsoleAI.js';
import { DataContextChat } from '../../Context/DataContextChat.js';
import { v4 as uuid } from 'uuid';
import Chatbot from "react-chatbot-kit";

const InfraCopilot = ({ isChatOpen }) => {


  const { userToken } = useLogin();

  const { token } = useContext(DataContext);
  const { getCopilotId, setAssistantId, getModuleNameByPath, disableUserChatSendBtn } = useContext(DataContextChat);
  const [inputTextIsValid, setInputTextIsValid] = useState(false)
  const setInputRef = useRef(false)



  const disableSendButton = () => {
    let sendButton = getSendButton()
    
    if (sendButton) {
      
      sendButton.classList.add('disabled');
      
      
    }
  }

  const enableSendButton = () => {
    let sendButton = getSendButton()
    
    if (sendButton) {
      
      sendButton.classList.remove('disabled');
      
    }
  }

  const getSendButton = () => {
    return document.querySelector('.react-chatbot-kit-chat-btn-send');
  }

  const getInputChat = () => {
    return document.querySelector('.react-chatbot-kit-chat-input');
  }

  const handleInputChange = (event) => {
    // Get the current value of the input field
    const inputValue = event.target.value;
    
    if (inputValue.length > 0 && !disableUserChatSendBtn) {
      enableSendButton();
    }
    else (
      disableSendButton()
    )
  }

  const handleKeyPress = (event) => {
    let sendButton = getSendButton();
    if (event.key === 'Enter' && sendButton.classList.contains('disabled')) {
      event.preventDefault(); // Optional: Prevents default behavior of Enter key (like form submission)
    }
  }

  useEffect(() => {
    const inputElement = getInputChat();
    inputElement.addEventListener('input', handleInputChange);
    inputElement.addEventListener('keydown', handleKeyPress);
    disableSendButton();



  }, [])

  useEffect(() => {
    const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');
    const inputElement = getInputChat();
    if (sendButton) {
      if(disableUserChatSendBtn){
        disableSendButton()
      }
      else if(inputElement.value === ""){
        disableSendButton()
      }
      else{
        enableSendButton();

      }
    }

    

   
  }, [disableUserChatSendBtn])

  //next steps
  //create data context for copilot
  //invole send recive api


  useEffect(async () => {

    if (token) {



      let response = await getCopilotId();
      let obj = response.data.find(x => x.name === 'NetBis');
      setAssistantId(obj.id)
      // config.payload = { token: token }

    }

  }, [])




  useEffect(async () => {
    if (isChatOpen) {      
    //  getModuleNameByPath();

    }
   
  }, [isChatOpen])

  return (
    <div>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}       
      
      />

    </div>

  );
};

export default InfraCopilot