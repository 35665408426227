
import React, { useEffect, useState, useContext } from 'react'
import './SAAnomalyChart.css';
import _ from "lodash";
import CanvasJSReact from '@canvasjs/react-charts';
import { DateTime, DateTime as luxon } from "luxon";
import DataContextAnalyzer from '../../../../Context/DataContextAnalyzer';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;




const SAAnomalyChart = ({ reportAnomaly, type, data, from, to, ready, yLinkValue="ms" }) => {

    //https://canvasjs.com/docs/charts/chart-options/height/
    //https://canvasjs.com/docs/charts/chart-options/axisy/strip-trend-lines/
    

    const { LuxonDateFormatString } = useContext(DataContextAnalyzer);


  

    const filterChartData = (data) => {

        const fromDate = DateTime.fromISO(from);
        const toDate = DateTime.fromISO(to);

        let filteredData = null;
        if (data !== undefined) {

            filteredData = data.filter(obj => {
                const timestamp = DateTime.fromISO(obj.timestamp);

                return timestamp >= fromDate && timestamp <= toDate;
            });


        }

        return filteredData;

    }

    const buildEmptyOptions = (dataPoints, title) => {
        
        const options = {
            animationEnabled: true,
            title: {
                text: type + " " + title
            },
            axisX: {
                valueFormatString: "DD/MM/YYYY HH:mm",
                labelAngle: 45
            },
            axisY: {
                title: "Value ("+yLinkValue+")",
              
            },
            height: 260,         
            data: [{
                
                type: "spline",
                dataPoints:dataPoints
            }]
        }

        return options
    }


    const buildOptions = (dataPoints, avg) => {
        const options = {
            animationEnabled: true,
            title: {
                text: type
            },
            axisX: {
                valueFormatString: "DD/MM/YYYY HH:mm",
                labelAngle: 45
               
            },
            axisY: {
                title: "Value ("+yLinkValue+")",
              
                stripLines: [
                    {

                        value: avg,
                        color: "orange",
                        label: "Avg: " + (avg.toFixed(2)),
                        labelFontColor: "#a8a8a8",
                    }
                ]
            },
            height: 260,          
            data: [{
             
                type: "spline",
                dataPoints: dataPoints
            }],
            toolTip: {
                content: function(e) {
                   
                    const date = new Date(e.entries[0].dataPoint.x);
                    const formattedDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Format date
                    return `<b>Date:</b> ${formattedDate}<br/><b>Value:</b> ${e.entries[0].dataPoint.y}`;
                },
                shared: true
            }
        }

        return options
       
    }

    const buildDataPoints = (withAnomaly) =>{
        var clone = [...data.metrics.value.data.data]
        if(withAnomaly){
            clone.push({requestId:"",serviceId:"",timestamp:data.anomaly[0].timestamp,type:"",value:data.anomaly[0].value, isAnomaly:true})
        }
        
        const orderedData = _.orderBy(clone, ['timestamp'], ['asc']);
       

        let count = -1;
        let dataPoints = orderedData.map((s) => {
            count++;
            const dateTime = DateTime.fromISO(s.timestamp);
            const year = dateTime.year;
            const month = dateTime.month;
            const day = dateTime.day;
            const hour = dateTime.hour;
            const minute = dateTime.minute;
            const second = dateTime.second;

            
            if(withAnomaly){
                return { x:  new Date(year, month-1, day, hour, minute, second), y: s.value,        
                    markerColor: !s.isAnomaly ? 'blue' : 'red' ,indexLabel:  !s.isAnomaly ? '' : 'Anomaly'  }
            }
            else{
                return { x:  new Date(year, month-1, day, hour, minute, second), y: s.value,                 
                    markerColor:  'blue' }
            }

            
            
        })
        return dataPoints
    }

    const generateChartOptions = (withAnomaly) => {

//taltal
        if(data.metrics?.value?.data?.data?.length >0 ){
        
            let dataPoints = buildDataPoints(withAnomaly)
            let avg = calcAvg(dataPoints);
            let options = withAnomaly ?  buildOptions(dataPoints, avg) : buildEmptyOptions(dataPoints, "(We've found only metrics without anomaly)")
            return options;
          
        }
        else{
            let options = buildEmptyOptions([], "(We didn't find any anomaly for this metric)")
            return options;
        }
        
       
       
    }

    const calcAvg = (dataPoints) => {

        var total = 0;
        for (var i = 0; i < dataPoints.length; i++) {
            total += dataPoints[i].y;
        }
        return total / dataPoints.length;

    }

  

    return (

        <React.Fragment>
            {
                 !ready ?
                 (
                 <div className='sa-anomaly-square'>
                     <div className='sa-anomaly-square-spinner linear-wipe'>Fetching anomaly data for {type}</div>
                 </div>
     
                 ): data.anomaly !== undefined && ready ?
                 (
                    
                 <CanvasJSChart options={generateChartOptions(true)}   />
                 ) :
                 (
                 <CanvasJSChart options={generateChartOptions(false)}  />
                 )
            }
        </React.Fragment>

    )


}



export default SAAnomalyChart







