
import React, { useContext, useEffect, useState } from 'react';
import './SelfServiceFillFormWidget.css';
import HtmlContentWidget from './HtmlContentWidget';
import HTMLModal from '../../Modals/HTMLModal';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { DataContextChat } from '../../Context/DataContextChat';

const SelfServiceFillFormWidget = (props) => {
 
    const { toggleChat} = useContext(DataContextChat);

  const { message } = props;
  const history = useHistory();

  const handleRedirect = () =>{
    toggleChat()
    history.push('/auto-cicd?chat=selfService');

  }
 
  const RenderWidget = () => {
    if(message?.dataForWidget && message.dataForWidget !== '')
    {

        
        return(
            <HtmlContentWidget customClass='self-service-autofill-widget' click={handleRedirect}
             html={'<span class=\'dp-hyperLink\'>Move To Self-Service</span>'} />
        )

    }

  }
 
  return (
    <div>
     <RenderWidget />
    
    </div>
  );
};

export default SelfServiceFillFormWidget;